import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { ReactComponent as BurgerSvg } from 'assets/icons/burger.svg';
import { ReactComponent as CloseSvg } from 'assets/icons/close.svg';
import { ROUTES } from 'constants/routes';
import { FeatureFlags } from 'utils/helpers/detection/types';

import { T9nContext } from 'providers/T9nProvider/t9nContext';
import { HeaderFooterContext } from 'providers/HeaderFooter/HeaderFooterContext';
import {
  IconAlignment,
  NavigationListItemProps,
  SidebarProps,
  SidebarNavItems,
} from './types';

import {
  SidebarWrapper,
  Logo,
  SidebarNav,
  SidebarHeader,
  DocumentIcon,
  UserIcon,
  HouseIcon,
  SidebarCloseButton,
  SidebarControl,
  SidebarMenuButton,
  VisuallyHidden,
  SidebarAccountNav,
} from './Sidebar.styled';
import { NavItem } from './NavItem';

export const Sidebar = ({
  selectedPropertyId,
  propertyList,
  isUserConnected,
  isPreview,
}: SidebarProps) => {
  const urlParams = useLocation();
  const pathName = urlParams.pathname;
  const { t9n } = useContext(T9nContext);
  const { logoUrl } = useContext(HeaderFooterContext);

  const [sidebarOpen, setSidebarIsOpen] = useState(false);

  const handleCloseSidebar = () => {
    setSidebarIsOpen(!sidebarOpen);
  };
  const showApplicant = useFeatureIsOn(FeatureFlags.APPLICANT as string);
  const showLettings = useFeatureIsOn(FeatureFlags.LETTINGS as string);

  const flags: { [key: string]: boolean } = {
    showLettings,
    showApplicant,
  };

  const navItems: NavigationListItemProps[] = [
    {
      key: SidebarNavItems.DOCUMENTS,
      label: t9n.Documents.DocumentsTitle,
      route: ROUTES.DOCUMENTS.ROOT,
      isUppercase: true,
      icon: {
        align: IconAlignment.LEFT,
        icon: DocumentIcon,
      },
      isHidden: !isUserConnected && !isPreview,
    },
    {
      key: SidebarNavItems.APPLICANT,
      label: t9n.MyPropertySearches,
      route: ROUTES.APPLICANT.ROOT,
      isUppercase: true,
      isHidden: !showApplicant,
      icon: {
        align: IconAlignment.LEFT,
        icon: HouseIcon,
      },
    },
    {
      key: SidebarNavItems.PROPERTIES,
      label: t9n.PropertiesTitle,
      isUppercase: true,
      isProperties: true,
    },
  ];
  return (
    <>
      <SidebarControl data-test-id="sidebarControl">
        <SidebarMenuButton onClick={handleCloseSidebar}>
          <BurgerSvg />
          <VisuallyHidden>{t9n.Global.Menu}</VisuallyHidden>
        </SidebarMenuButton>
      </SidebarControl>
      <SidebarWrapper isOpen={sidebarOpen}>
        <SidebarHeader>
          {isPreview ? (
            <Logo title={t9n.LogoTitle} />
          ) : (
            <a href={logoUrl as string} aria-label="Savills home">
              <Logo title={t9n.LogoTitle} />
            </a>
          )}
          <SidebarCloseButton onClick={handleCloseSidebar}>
            <CloseSvg />
            <VisuallyHidden>{t9n.Global.Menu}</VisuallyHidden>
          </SidebarCloseButton>
        </SidebarHeader>
        <SidebarNav role="navigation">
          {navItems.map((item) => (
            <NavItem
              key={item.key}
              flags={flags}
              toggleSidebar={handleCloseSidebar}
              item={item}
              icon={item?.icon?.icon}
              propertyList={propertyList}
              selectedPropertyId={selectedPropertyId}
              isActive={item.route === pathName}
            />
          ))}
        </SidebarNav>
        <SidebarAccountNav>
          <NavItem
            key={SidebarNavItems.ACCOUNT}
            item={{
              key: SidebarNavItems.ACCOUNT,
              label: t9n.AccountTitle,
              route: ROUTES.ACCOUNT,
              isFixedToBottom: true,
              icon: {
                align: IconAlignment.LEFT,
                icon: UserIcon,
              },
              isUppercase: true,
            }}
            flags={flags}
            toggleSidebar={handleCloseSidebar}
            icon={UserIcon}
            propertyList={propertyList}
            selectedPropertyId={selectedPropertyId}
            isActive={ROUTES.ACCOUNT === pathName}
            isDisabled={isPreview}
          />
        </SidebarAccountNav>
      </SidebarWrapper>
    </>
  );
};
