import styled from 'styled-components';

import { lighten } from 'polished';

export const Nav = styled.ul`
  display: flex;
  padding: 0;
  border-bottom: 1px solid
    ${(props) => lighten(0.65, props.theme.colorPalette.branding.ink)};
  position: relative;
  z-index: 0;
`;

export const TabButton = styled.button<{ isActive: boolean }>`
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  border: 0;
  padding: 15px 0;
  margin-right: 30px;
  margin-bottom: -1px;
  background: none;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};
  font-size: ${(props) => props.theme.typography.xsmall};
  border-bottom: 1px solid
    ${(props) =>
      props.isActive
        ? props.theme.colorPalette.secondary.red
        : lighten(0.65, props.theme.colorPalette.branding.ink)};
  color: ${(props) =>
    props.isActive
      ? props.theme.colorPalette.secondary.red
      : lighten(0.2, props.theme.hub.colorPalette.font.darkGrey)};
  border-width: ${(props) => (props.isActive ? '2px' : '1px')};
`;

export const TabGrid = styled.div<{ isActive: boolean }>`
  display: ${(props) => (props.isActive ? 'grid' : 'none')};
  grid-gap: 20px;
  padding: 20px 0;
  grid-template-columns: auto;
  grid-template-rows: auto;

  @media screen and (${(props) => props.theme.media.widthMed}) {
    grid-template-columns: 1fr;
  }
`;
