import { SIDEBAR_WIDTHS } from 'components/Sidebar/Sidebar.styled';
import React from 'react';
import styled from 'styled-components';

export const AppGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 56px 1fr auto; /* Add a new row for the footer */
  grid-template-areas:
    'sidebar'
    'main'
    'footer'; /* Add a new area for the footer */
  grid-gap: 0;
  min-height: 100vh;
  width: 100%;
  position: sticky;
  font-family: ${(props) => props.theme.fonts.secondary.font};

  @media screen and (${(props) => props.theme.media.widthSml}) {
    grid-template-columns: ${SIDEBAR_WIDTHS.sml} minmax(0, 1fr);
    grid-template-rows: 1fr auto; /* Adjust for the footer */
    grid-template-areas:
      'sidebar main'
      'sidebar footer'; /* Footer spans both columns */
    position: relative;
    overflow-x: hidden;
  }
  @media screen and (${(props) => props.theme.media.widthXXlrg}) {
    grid-template-columns: ${SIDEBAR_WIDTHS.lrg} minmax(0, 1fr);
  }
`;

export const Header = styled.header`
  grid-area: sidebar;
  position: fixed;
  z-index: 2;

  @media screen and (${(props) => props.theme.media.widthSml}) {
    z-index: 1;
  }
`;

export const Main = styled.main<{ isBrochurePage?: boolean }>`
  grid-area: main;
  min-height: calc(100vh - 40px);
  padding: 0;
  background-color: ${(props) => props.theme.hub.colorPalette.misc.lightGrey};
  z-index: 1;
  height: fit-content;
  position: relative;
  width: 100vw;

  @media screen and (${(props) => props.theme.media.widthSml}) {
    min-height: 100vh;
    width: auto;
    z-index: 2;
  }
`;

export const PageGrid = styled.div<{
  hasStickyNav?: boolean;
  hideHeader?: boolean;
  isApplicantView?: boolean;
  isFeedbackView?: boolean;
}>`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(56px, auto) 1fr;
  grid-template-areas: 'pageheader' 'pagemain';
  grid-gap: ${(props) =>
    props.isApplicantView || props.isFeedbackView ? '0 46px' : '40px 46px'};
  width: 100%;
  padding: 20px;
  max-width: 1680px;
  margin: 0 auto;

  @media screen and (${(props) => props.theme.media?.widthSml}) {
    grid-gap: 40px 46px;
    padding: 40px 66px;
  }

  @media screen and (${(props) => props.theme.media.widthMed}) {
    padding: 40px 106px;
  }
`;

export const BrochurePageGrid = styled(PageGrid)<{ whiteBG?: boolean }>`
  background: ${(props) =>
    props.whiteBG ? props.theme.colorPalette.branding.white : 'none'};
  grid-gap: 0 46px;

  @media screen and (${(props) => props.theme.media?.widthSml}) {
    grid-gap: 0 46px;
  }

  @media screen and (${(props) => props.theme.media?.widthMed}) {
    padding: 60px 0 20px;
  }
`;

export const PropertyPageGrid = styled(BrochurePageGrid)`
  padding: 0;
  max-width: none;
`;

interface FlexContainerProps {
  direction?: string;
  justifyContent?: string;
  margin?: string;
  padding?: string;
}
export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}
`;

interface HeaderProps {
  className?: string;
  children: React.ReactNode;
}
type WrappedHeaderProps = HeaderProps & {
  direction?: string;
  justifyContent?: string;
  gridArea?: string;
  isBrochurePage?: boolean;
  isApplicantView?: boolean;
};

export type PageColumnConfig = {
  default: GridConfig;
  widthSml?: GridConfig;
  widthMed?: GridConfig;
  widthLrg?: GridConfig;
};

export type GridConfig = {
  columns: string;
  gap: string;
};

function WrappedHeader({ className, children }: HeaderProps) {
  return (
    <FlexContainer as="header" className={className} direction="column">
      {children}
    </FlexContainer>
  );
}

export const PageHeader = styled(WrappedHeader)<WrappedHeaderProps>`
  ${({ gridArea }) => gridArea && `gridarea: ${gridArea};`}
  width: 100%;
  min-height: 50px;
  ${({ direction }) => direction && `flex-direction: ${direction};`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}

  position: ${(props) => (props.isApplicantView ? 'sticky' : 'relative')};
  top: ${(props) => (props.isApplicantView ? '55px' : 'auto')};
  z-index: 2;

  @media screen and (${(props) => props.theme.media.widthSml}) {
    padding: ${(props) => (props.isBrochurePage ? '0 46px' : '0')};
  }
  @media screen and (${(props) => props.theme.media.widthMed}) {
    padding: ${(props) => (props.isBrochurePage ? '0 86px' : '0')};
  }

  @media screen and (${(props) => props.theme.media.widthLtSml}) {
    h1 {
      font-size: ${(props) => props.theme.typography.xlarge};
    }
  }
`;

export const PageBody = styled.div<{ isApplicantView?: boolean }>`
  grid-area: pagemain;
  ${(props) => props.isApplicantView && `margin-bottom: 40px;`}

  @media screen and (${(props) => props.theme.media.widthLtSml}) {
    ${(props) => props.isApplicantView && `padding: 0 25px;`}
  }
`;

export const PropertyPageBody = styled.div`
  grid-area: pagemain;
  padding: 40px 20px 40px;
  margin: 0 auto;
  width: 100%;
  max-width: 1680px;

  @media screen and (${(props) => props.theme.media.widthMed}) {
    padding: 40px 106px 40px;
  }
`;

export const PageColumns = styled.div<PageColumnConfig>`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  margin-bottom: 20px;

  ${(props) =>
    props.widthMed &&
    `
    @media screen and (${props.theme.media.widthMed}) {
      display: grid;
      grid-template-columns: ${props.widthMed.columns};
      grid-gap: ${props.widthMed.gap}};
    }
  `}

  ${(props) =>
    props.widthLrg &&
    `
    @media screen and (${props.theme.media.widthLrg}) {
      display: grid;
      grid-template-columns: ${props.widthLrg.columns};
      grid-gap: ${props.widthLrg.gap}};
    }
  `}
`;

interface SectionProps {
  gridArea?: string;
  maxWidth?: string;
}

export const PageSection = styled.section<SectionProps>`
  grid-area: ${(props) => props.gridArea};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}

  @media screen and (${(props) => props.theme.media.widthLtSml}) {
    h2 {
      font-size: ${(props) => props.theme.typography.large};
    }
  }
`;

export const SidebarHeading = styled.h2`
  margin: 25px 0;
  font-size: ${(props) => props.theme.typography.default};
`;

export const SubSection = styled.section`
  margin-top: 15px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
