import { Keyboard, Mousewheel } from 'swiper';

export enum CarouselDirections {
  PREVIOUS = 'previous',
  NEXT = 'next',
}

export const carouselModules = [Keyboard, Mousewheel];

export const SLIDES_PER_VIEW = 3;
