import styled, { css } from 'styled-components';
import { hideVisually } from 'polished';
import { ReactComponent as ZoomIn } from 'assets/icons/zoomIn.svg';
import { ReactComponent as ZoomOut } from 'assets/icons/zoomOut.svg';
import { ReactComponent as ResetZoom } from 'assets/icons/resetZoom.svg';

export const NavigationContainer = styled.div`
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
`;

export const sharedGalleryNavBtnStyles = css`
  background: ${(props) => props.theme.colorPalette.branding.yellow};
  width: 35px;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  outline: 0;
  border: 0;

  &:focus {
    background: ${(props) => props.theme.colorPalette.branding.yellow};
    outline: solid ${(props) => props.theme.colorPalette.secondary.steel};
  }
`;

export const galleryNavButtonStyles = css`
  fill: ${(props) => props.theme.colorPalette.branding.ink};
`;

export const PaginationWrapper = styled.div`
  position: absolute;
  bottom: 0;
  height: 30px;
  background: ${(props) => props.theme.colorPalette.branding.white};
  width: 100%;
  z-index: 1;
  left: 0;

  @media screen and (${(props) => props.theme.media.widthMed}) {
    height: 80px;
    padding: 0;
  }
`;

export const PaginationInner = styled.div`
  position: absolute;
  padding: 0 60px;
  bottom: 4px;
  width: 100%;
  left: 0;

  @media screen and (${(props) => props.theme.media.widthMed}) {
    bottom: 30px;
  }
`;

export const PaginationBulletsList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
`;

export const PaginationBullet = styled.li<{ isactive: boolean }>`
  margin-left: 5px;

  &:first-child {
    margin-left: 0;
  }

  &::after {
    content: '';
    width: 7px;
    height: 7px;
    background: ${(props) =>
      props.isactive
        ? props.theme.colorPalette.branding.ink
        : props.theme.colorPalette.forms.inputBorder};
    border-radius: 50%;
    display: block;
  }

  span {
    ${hideVisually};
  }

  @media screen and (${(props) => props.theme.media.widthMed}) {
    display: none;
  }
`;

const sharedFontStyles = css`
  font-family: ${(props) => props.theme.fonts.primary.font};
  font-size: 18px;
  font-weight: ${(props) => props.theme.fonts.primary.fontWeight};
  color: ${(props) => props.theme.colorPalette.branding.ink};
`;

export const ImageCaption = styled.p`
  display: none;

  @media screen and (${(props) => props.theme.media.widthMed}) {
    display: block;
    position: absolute;
    bottom: 0;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);

    ${sharedFontStyles};
  }
`;

export const SlidesCounter = styled.p`
  position: absolute;
  right: 15px;
  bottom: 0;
  margin: 0;
  ${sharedFontStyles};

  @media screen and (${(props) => props.theme.media.widthMed}) {
    display: block;
    right: 55px;
  }
`;

export const ZoomControlsWrapper = styled.div`
  position: absolute;
  right: 48px;
  bottom: 100px;
  z-index: 10;
  display: none;
  box-shadow: 0 0 3px ${(props) => props.theme.colorPalette.button.grey};

  @media screen and (${(props) => props.theme.media.widthMed}) {
    display: flex;
    flex-direction: column;
  }
`;

export const ZoomButton = styled.button`
  background: ${(props) => props.theme.colorPalette.branding.white};
  border: 0;
  padding: 6px;
  position: relative;
  width: 48px;
  height: 48px;

  &::after {
    content: '';
    width: 32px;
    position: absolute;
    bottom: 0;
    left: 8px;
    height: 1px;
    background: ${(props) =>
      props.theme.colorPalette.forms.inputBackgroundDisabled};
  }

  span {
    ${hideVisually};
  }
`;

const zoomButtonStyles = css`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ZoomInIcon = styled(ZoomIn)`
  ${zoomButtonStyles};
`;

export const ZoomOutIcon = styled(ZoomOut)`
  ${zoomButtonStyles};
`;

export const ResetZoomIcon = styled(ResetZoom)`
  ${zoomButtonStyles};
`;
