/* eslint-disable no-console */
import { useState } from 'react';

const COOKIE_LINK = 'sv-cookie-management-link';
const ACTIVE_LINK_CLASS = 'sv-expandable-item__title-active';
const ICON_CLASS = 'sv--expanded';
const ACTIVE_MODAL_CLASS = 'show-cookie-modal';
const SAVILLS_ACCEPTANCE_COOKIE = 'SavillsCookieAcceptance';
const SAVE_CLOSE_BTN = 'sv-save-close';
const FUNCTIONAL_INPUT = 'functional-cookies';
const TRACKING_INPUT = 'tracking-cookies';
const ESSENTIAL_INPUT = 'strictly-cookies';
const HEADER_CLASS = 'sv-expandable-item__header';

function toSeconds(value: number) {
  return value * 24 * 60 * 60 * 1000;
}

function writeCookie(name: string, value: string, days: number) {
  let expires = '';

  if (days) {
    const date = new Date();
    const seconds = toSeconds(days);

    date.setTime(date.getTime() + seconds);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value}${expires}; path=/;`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function cookieToString(cookie: any) {
  return JSON.stringify(cookie);
}

function readCookie(name: string) {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(nameEQ) === 0) {
      return decodeURI(cookie.substring(nameEQ.length, cookie.length));
    }
  }
  return null;
}

export function toggleCookieSection(link: HTMLElement) {
  if (!link) {
    return;
  }

  const selectedContainer = link.parentElement;
  const selectedHeader = link;

  if (!selectedContainer || !selectedHeader) {
    return;
  }

  const selectedContainerClasses =
    selectedContainer.getAttribute('class') || '';

  if (selectedContainerClasses.includes(ACTIVE_LINK_CLASS)) {
    selectedContainer.classList.remove(ACTIVE_LINK_CLASS);
    selectedHeader.classList.remove(ICON_CLASS);
  } else {
    selectedContainer.classList.add(ACTIVE_LINK_CLASS);
    selectedHeader.classList.add(ICON_CLASS);
  }
}

function safeJSONParse(str: string) {
  try {
    return JSON.parse(str);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('JSON failed to parse');
    return {};
  }
}
const getCookieValues = () => {
  const cookie = readCookie(SAVILLS_ACCEPTANCE_COOKIE);
  const values = cookie && safeJSONParse(cookie);
  return [cookie, values];
};

export function useCookieModal() {
  const [checkboxesToggled, setCheckboxesToggled] = useState('false');

  const setupForm = () => {
    const sectionHeader = document.querySelectorAll(`header.${HEADER_CLASS}`);

    const [cookie, values] = getCookieValues();

    if (sectionHeader) {
      sectionHeader.forEach((header) => {
        const headerElement = header as HTMLElement;
        headerElement.addEventListener('click', (e: Event) => {
          e.stopImmediatePropagation();
          toggleCookieSection(headerElement);
        });
      });
    }

    const functionalCheckbox = document.getElementById(FUNCTIONAL_INPUT);
    const trackingCheckbox = document.getElementById(TRACKING_INPUT);
    const essentialCheckbox = document.getElementById(ESSENTIAL_INPUT);
    essentialCheckbox?.setAttribute('checked', 'checked');

    if (cookie) {
      const { functional, tracking } = values;
      if (functionalCheckbox && functional === true) {
        functionalCheckbox.setAttribute('checked', 'checked');
      }

      if (trackingCheckbox && tracking === true) {
        trackingCheckbox.setAttribute('checked', 'checked');
      }
    }

    setCheckboxesToggled('true');

    const submitBtn = document.querySelector(
      '.sv-cookie-management__modal-cta button',
    );

    submitBtn?.addEventListener('click', (e: Event) => {
      e.preventDefault();
      functionalCheckbox?.setAttribute('checked', 'checked');
      trackingCheckbox?.setAttribute('checked', 'checked');

      writeCookie(
        SAVILLS_ACCEPTANCE_COOKIE,
        cookieToString({
          functional: true,
          tracking: true,
          forwarded: false,
        }),
        365,
      );
      resetModal();
    });

    const saveBtn = document.querySelector(`.${SAVE_CLOSE_BTN}`);
    saveBtn?.addEventListener('click', (e: Event) => {
      e.preventDefault();
      writeCookie(
        SAVILLS_ACCEPTANCE_COOKIE,
        cookieToString({
          functional: (functionalCheckbox as HTMLInputElement).checked,
          tracking: (trackingCheckbox as HTMLInputElement).checked,
          forwarded: false,
        }),
        365,
      );
      resetModal();
    });
  };

  const resetModal = () => {
    document.body
      .querySelector('.sv-cookie-management__modal')
      ?.classList.remove(ACTIVE_MODAL_CLASS);

    document.body.style.overflow = 'auto';

    document.body.querySelectorAll(`.${ACTIVE_LINK_CLASS}`).forEach((el) => {
      el.classList.remove(ACTIVE_LINK_CLASS);
    });
    document.body.querySelectorAll(`.${ICON_CLASS}`).forEach((el) => {
      el.classList.remove(ICON_CLASS);
    });
  };

  const loadEventHandlers = () => {
    window.addEventListener('click', (e) => {
      const toggleModalBtn = document.querySelector(`.${COOKIE_LINK}`);
      const targettedLink = e.target as HTMLElement;

      if (targettedLink === toggleModalBtn) {
        document.body
          .querySelector('.sv-cookie-management__modal')
          ?.classList?.add(ACTIVE_MODAL_CLASS);
        document.body.style.overflow = 'hidden';
        e.preventDefault();
        if (checkboxesToggled !== 'true') {
          setupForm();
        }
      }
    });
  };

  return {
    loadEventHandlers,
    setupForm,
  };
}
