import React from 'react';
import styled from 'styled-components';
import { AreaType } from 'types/property';

import { ReactComponent as Size } from 'assets/icons/size.svg';
import { ReactComponent as LandSize } from 'assets/icons/landSize.svg';
import { attributeIconStyles } from '../Attributes/Attributes.styled';

export const InternalSizeIcon = styled(Size)`
  ${attributeIconStyles};
`;

export const ExternalSizeIcon = styled(LandSize)`
  ${attributeIconStyles};
  width: 20px;
  height: 20px;
`;

export const getSizeIcon = (type: string) => {
  switch (type) {
    case AreaType.LandSize:
      return <ExternalSizeIcon />;
    default:
      return <InternalSizeIcon />;
  }
};
