import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import Truncate from 'react-truncate';

import { matchMediaUtil } from 'utils/helpers/detection/matchMedia';
import { Line1, Line2 } from './Address.styled';
import { AddressProps } from './types';
import { getAddressLine } from './helpers';

const Address: React.FC<AddressProps> = ({
  address,
  darkMode = false,
  isMiniCard = false,
}) => {
  const theme = useTheme();
  const [isLrgWidth, setIsLrgWidth] = useState<boolean>(
    matchMediaUtil(theme.media.widthSml),
  );

  const onViewportResize = () => {
    const size = matchMediaUtil(theme.media.widthSml);

    if (size !== isLrgWidth) setIsLrgWidth(size);
  };

  useEffect(() => {
    window.addEventListener('resize', onViewportResize);

    return () => {
      window.removeEventListener('resize', onViewportResize);
    };
  });

  if (typeof address === 'string') {
    return (
      <Line1 isMiniCard={isMiniCard} darkMode={darkMode}>
        <Truncate lines={1}>{address}</Truncate>
      </Line1>
    );
  }

  return (
    <div>
      <Line1 isMiniCard={isMiniCard} darkMode={darkMode}>
        <Truncate lines={1}>{getAddressLine(address)}</Truncate>
      </Line1>
      <Line2 isMiniCard={isMiniCard} darkMode={darkMode}>
        <Truncate lines={1}>
          {address.line2}
          {!!address.postcode && `, ${address.postcode}`}
        </Truncate>
      </Line2>
    </div>
  );
};

export default Address;
