import React, { useContext } from 'react';

import { T9nContext } from 'providers/T9nProvider/t9nContext';
import {
  AccessibleBtnTitle,
  NavigationButton,
  NextIcon,
  PreviousIcon,
} from './Carousel.styled';
import { CarouselBtnProps } from './types';
import { CarouselDirections } from './constants';

const CarouselBtn: React.FC<CarouselBtnProps> = ({
  direction,
  isGallery,
  swiper,
  isDisabled,
}) => {
  const { t9n } = useContext(T9nContext);

  return (
    <NavigationButton
      direction={direction}
      type="button"
      disabled={isDisabled}
      onClick={() =>
        direction === CarouselDirections.PREVIOUS
          ? swiper.slidePrev()
          : swiper.slideNext()
      }
      isGallery={isGallery}
    >
      <AccessibleBtnTitle>
        {direction === CarouselDirections.PREVIOUS
          ? t9n.PropertyCarousel.PreviousImage
          : t9n.PropertyCarousel.NextImage}
      </AccessibleBtnTitle>
      {direction === CarouselDirections.PREVIOUS ? (
        <PreviousIcon />
      ) : (
        <NextIcon />
      )}
    </NavigationButton>
  );
};

export default CarouselBtn;
