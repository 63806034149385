export enum NotificationStyleEnum {
  Caution = 0,
  Alert = 1,
  Info = 2,
}

export enum UserTypeEnum {
  EnabledUser = 0,
  AllUsers = 1,
}

export enum MaintenanceBannerEnum {
  ScheduledMaintenance = 0,
  GenericMaintenance = 1,
}

export type NotificationFeature = {
  dismissible: boolean;
  enabledUsers?: {
    type: NotificationStyleEnum;
    value: string;
  };
  allUsers?: {
    type: MaintenanceBannerEnum;
    replacements: string[];
  };
};
