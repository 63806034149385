import React from 'react';
import { PropertyNav } from './PropertyNav';
import {
  SidebarNavContent,
  SidebarNavItem,
  SidebarDisabledLink,
} from './Sidebar.styled';
import { IconAlignment, NavItemProps } from './types';

export const NavItem = ({
  item,
  propertyList,
  toggleSidebar,
  flags,
  icon: Icon,
  isActive,
  isDisabled,
}: NavItemProps) => {
  if (item.isHidden) {
    return null;
  }

  if (isDisabled) {
    return (
      <SidebarNavItem
        key={item.key}
        isFixedToBottom={item.isFixedToBottom}
        isUppercase={item.isUppercase}
        iconRightAligned={item.icon?.align === IconAlignment.RIGHT}
        isPropertyItem={item.isProperties}
        isActive={isActive}
        isDisabled
      >
        <SidebarNavContent to={item.route || ''} isDisabled isActive={isActive}>
          {Icon && <Icon />}
          <SidebarDisabledLink>
            {item.label}
            {item.isProperties && item.label}
          </SidebarDisabledLink>
        </SidebarNavContent>
      </SidebarNavItem>
    );
  }

  return (
    <SidebarNavItem
      key={item.key}
      isFixedToBottom={item.isFixedToBottom}
      isUppercase={item.isUppercase}
      iconRightAligned={item.icon?.align === IconAlignment.RIGHT}
      isPropertyItem={item.isProperties}
      isActive={isActive}
    >
      {!item.isProperties && (
        <SidebarNavContent
          to={item.route || ''}
          onClick={toggleSidebar}
          isActive={isActive}
        >
          {Icon && <Icon />}
          {item.label}{' '}
        </SidebarNavContent>
      )}
      {item.isProperties && (
        <PropertyNav
          propertyList={propertyList}
          item={item}
          flags={flags}
          toggleSidebar={toggleSidebar}
        />
      )}
    </SidebarNavItem>
  );
};
