import { DateTypes, TagProps } from 'components/Card/types';
import { Agent, Negotiator } from 'components/NegotiatorCard/types';
import { PropertyTenures, PropertyVerticals } from 'constants/global';
import {
  Price,
  PropertySize,
  SearchArea,
  Property,
  Appointment,
} from 'types/property';
import { SavillsSearchCriteria } from 'types/savills';
import { OfferStatus } from 'views/Vendor/types';

export type Alert = {
  id: string;
  vertical: PropertyVerticals;
  area: string[];
  tenure: PropertyTenures;
  price: string;
  propertyTypes: string | string[];
  bedrooms: string;
  geoLocation: google.maps.LatLng | google.maps.LatLngLiteral;
  url: string;
};

export type ReapitArea = {
  name?: string;
  type?: string;
  area?: string[];
};

export type Criteria = {
  id: string;
  postcode: string;
  searchShape: SearchArea[];
  priceRange: Price;
  propertyType: PropertyVerticals;
  bedrooms?: number;
  bathrooms?: number;
  receptions?: number;
  features?: string[];
  lifestyle?: string[];
  propertySize?: PropertySize;
  landSize?: PropertySize;
};

export type RecommendPropertiesProps = {
  properties: Property[];
};

export type PropertyRecordsProps = {
  records: Criteria[];
};

export type SavedPropertiesProps = {
  savedProperties: Property[];
};

export type SimpleAlert = {
  createdDate: string;
  searchDescription: string;
  searchContext: SavillsSearchCriteria;
  memberId: string;
  alertId: string;
  ignore: boolean;
};

export type ApplicantResponse = {
  deletingAlertId: string | null;
  togglingAlertId: string | null;
  deletingPropertyId: string | null;
  applicantData: {
    savedProperties: Property[];
    // TODO reinstate when we do the full card; savedAlerts: Alert[];
    savedAlerts: SimpleAlert[];
    savedSearches: Criteria[];
    recommendedProperties: Property[];
  };
  applicantRecord: {
    viewings: Viewing[];
    applicants: ApplicantRecord[];
    offers: ReapitApplicantOffer[];
  };
};

export type ApplicantRecord = {
  id: string;
  name?: string;
  email?: string;
  contactId?: string;
  notes?: string;
  type?: string;
  style?: string[];
  bedroomsMin?: number;
  bedroomsMax?: number;
  receptionsMin?: number;
  receptionsMax?: number;
  bathroomsMin?: number;
  bathroomsMax?: number;
  parkingSpacesMin?: number;
  parkingSpacesMax?: number;
  negotiator?: {
    id?: string;
    name: string;
    officeId?: string;
    workPhone?: string;
    mobilePhone?: string;
    telephone: string;
    email: string;
    image: string;
  };
  searchType?: string;
  area?: {
    internal?: {
      type?: string;
      amount?: number;
      amountFrom?: number;
      amountTo?: number;
    };
    external?: {
      type?: string;
      amount?: number;
      amountFrom?: number;
      amountTo?: number;
    };
  };
  map?: {
    name?: string;
    type?: string;
    area?: string[];
    geocode?: {
      center: google.maps.LatLng;
      bounds: google.maps.LatLngBounds;
    };
  }[];
  locationType?: string;
  locationOptions?: string[];
  price: {
    priceTo?: string;
    priceFrom?: string;
  };
};

export interface Viewing extends Appointment {
  property: Property;
  negotiator?: Agent;
}

export interface ApplicantOffer {
  id: string;
  price: number;
  currency: string;
  date: DateTypes;
  tag: TagProps;
  status: OfferStatus;
  negotiator?: Agent;
}
export interface ApplicantPropertyOffers {
  property: Property;
  offers: ApplicantOffer[];
  isAccepted: boolean;
  type: OfferType;
}

export enum OfferType {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export type ReapitApplicantOffer = {
  id: string;
  created: string;
  currency: string;
  amount: number;
  status: OfferStatus;
  date: string;
  negotiator?: Negotiator;
  property?: Property;
};

export interface NotFoundProps {
  link?: string;
  linkText: string;
  message: string;
  withImg?: boolean;
}
