import { SAVED_PROPERTIES_LIMIT } from 'constants/applicant';
import { ROUTES } from 'constants/routes';
import { Impression } from 'providers/GoogleAnalytics/GoogleAnalyticsContext';
import { Property } from 'types/property';
import { ApplicantResponse } from 'views/Applicant/types';
import { getLocalizationParametersFromCookieOrUrl } from '../localization/localization';

type DownloadFileArgs = {
  fileContents: string;
  fileName: string;
  mimeType: string;
};

export function downloadFile({
  fileContents,
  fileName,
  mimeType,
}: DownloadFileArgs) {
  const blob = new Blob([fileContents], { type: mimeType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/* eslint-disable no-useless-escape */
export function slugify(input: string) {
  const a =
    'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b =
    'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return input
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export const getSearchUrl = () => {
  return window.appConfig.searchUrl;
};

function convertPropertyToImpression(
  property: Property,
  index: number,
): Impression {
  return {
    brand: property.officeName,
    category: property.propertyTypes,
    id: property.externalPropertyId,
    list: 'Applicant Hub',
    name: property.address.line1,
    position: `${index + 1}`,
    price: `${property.guidePrice}`,
    variant: null,
  };
}

export function getImpressions(pathname: string, data?: ApplicantResponse) {
  const isSavedPropertiesPage = pathname.includes(
    ROUTES.APPLICANT.SAVED_PROPERTIES,
  );
  const savedProperties = data?.applicantData?.savedProperties || [];

  const recommendedProperties = isSavedPropertiesPage
    ? []
    : data?.applicantData?.recommendedProperties || [];

  const displayedSavedProperties = isSavedPropertiesPage
    ? savedProperties
    : savedProperties.slice(0, SAVED_PROPERTIES_LIMIT);

  return [...recommendedProperties, ...displayedSavedProperties].map(
    (property, index) => convertPropertyToImpression(property, index),
  );
}

export function getCookieDomain() {
  const { host } = window.location;
  switch (true) {
    case host.includes('localhost'):
      return 'localhost';
    case host.includes('mys-team.com'):
      return '.mys-team.com';
    case host.includes('savills.com'):
      return '.savills.com';
    default:
      return host;
  }
}

export function getBaseUrl() {
  const { localizedRoute } = getLocalizationParametersFromCookieOrUrl();
  const localeSegments = localizedRoute ? `${localizedRoute}/` : '';
  const baseElement = document.querySelector('base');
  const baseHref = baseElement ? baseElement.getAttribute('href') : '/';
  return `${baseHref}${localeSegments}`;
}
