/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExtendedSwiper } from './types';

export type Ref = {
  swiper: ExtendedSwiper;
  extendParams: any;
  on: any;
  emit: any;
};

export type GestureElements = {
  imageEl?: HTMLImageElement;
  imageWrapEl?: HTMLDivElement;
  slideEl?: HTMLDivElement;
};

export const MAX_ZOOM = 4;
