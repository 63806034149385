import React from 'react';

import { CookieModalProps } from './types';
import { CookieModalWrapper } from './CookieModal.styled';

const CookieModal: React.FC<CookieModalProps> = ({ modalHtml }) => {
  return <CookieModalWrapper dangerouslySetInnerHTML={{ __html: modalHtml }} />;
};

export default CookieModal;
