import { DEFAULT_LOCALE_PARAMS } from 'constants/global';
import { LanguageCode } from 'savills-ui-kit';
import { CountryCode } from 'types/countries';
import cookie from 'browser-cookies';
import { GPS_LOCALE } from 'constants/cookies';

const isTestUrl = window.location.pathname.includes('MYS-');

function checkForLanguageMatch(languageCode: LanguageCode) {
  return Object.values(LanguageCode).includes(languageCode);
}

function checkForCountryMatch(countryCode: CountryCode) {
  return Object.values(CountryCode).includes(countryCode);
}

function checkIfLocalizationParametersAreValid(
  countryCode: CountryCode,
  languageCode: LanguageCode,
) {
  return (
    checkForCountryMatch(countryCode) && checkForLanguageMatch(languageCode)
  );
}

function splitLocaleCookie() {
  const localeCookieChunks = cookie.get(GPS_LOCALE)?.split('-') ?? [];
  if (localeCookieChunks.length > 2) {
    return [
      `${localeCookieChunks[0]}-${localeCookieChunks[1]}`,
      localeCookieChunks[2],
    ];
  }
  if (localeCookieChunks.length === 2) {
    return [localeCookieChunks[0], localeCookieChunks[1]];
  }
  return [];
}

function clearCookieIfNotComReferrer() {
  if (document.referrer) {
    const isSavillsRefferal = document.referrer?.includes('savills');
    const isNotComReferrer =
      document.referrer?.includes('.com.') ||
      !document.referrer?.includes('.com');
    if (isSavillsRefferal && isNotComReferrer) {
      const isSavills = window.location.hostname.includes('savills');
      const domain = isSavills ? '.savills.com' : '.mys-team.com';
      document.cookie = `${GPS_LOCALE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
    }
  }
}

export function getLocalizationParametersFromCookieOrUrl() {
  clearCookieIfNotComReferrer();
  let countryCode: CountryCode;
  let languageCode: LanguageCode;
  if (!isTestUrl) {
    const [, cCode, lCode] = window.location.pathname.split('/') as [
      unknown,
      CountryCode,
      LanguageCode,
    ];
    countryCode = cCode;
    languageCode = lCode;
  } else {
    const [, , cCode, lCode] = window.location.pathname.split('/') as [
      unknown,
      unknown,
      CountryCode,
      LanguageCode,
    ];
    countryCode = cCode;
    languageCode = lCode;
  }

  const [cLanguageCode, cCountryCode] = splitLocaleCookie() as [
    LanguageCode,
    CountryCode,
  ];

  const isLocalizedUrl = checkIfLocalizationParametersAreValid(
    countryCode,
    languageCode,
  );

  const cookieCountryCode = cCountryCode?.toLowerCase() as CountryCode;
  const cookieLanguageCode = cLanguageCode?.toLowerCase() as LanguageCode;

  const localizedRoute = isLocalizedUrl ? `${countryCode}/${languageCode}` : '';

  return isLocalizedUrl
    ? {
        countryCode: cookieCountryCode || countryCode,
        languageCode: cookieLanguageCode || languageCode,
        localizedRoute,
      }
    : {
        countryCode:
          cookieCountryCode || (DEFAULT_LOCALE_PARAMS.COUNTRY as CountryCode),
        languageCode:
          cookieLanguageCode ||
          (DEFAULT_LOCALE_PARAMS.LANGUAGE as LanguageCode),
        localizedRoute,
      };
}
