/* eslint-disable import/no-unresolved */
import React, { useState, useMemo } from 'react';
import { Keyboard, Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ZoomOptions, Swiper as SwiperClass } from 'swiper/types';

import 'swiper/css';
import 'swiper/css/zoom';

import SlidesPagination from './SlidesPagination';
import GalleryZoom from './GalleryZoom';
import {
  FloorplanGalleryContainer,
  ZoomContainer,
} from './FloorplanGallery.styled';
import { NavigationContainer } from './Gallery.styled';
import ExtendedZoom from './ExtendedZoom';
import { PropertyCardGalleryProps } from './types';
import CarouselBtn from '../Carousel/CarouselBtn';
import { CarouselDirections } from '../Carousel/constants';
import { MAX_ZOOM } from './constants';

type WithZoomProps = Pick<PropertyCardGalleryProps, 't9n' | 'images'>;

const WithZoomModules = [Keyboard, Zoom, ExtendedZoom];

const WithZoom: React.FC<WithZoomProps> = ({ images, t9n }) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(
    null,
  );
  const [swipeNavEnabled, setSwipeNavEnabled] = useState(true);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeZoomScale, setActiveZoomScale] = useState(1);

  const zoomConfig = useMemo<ZoomOptions>(
    () => ({
      containerClass: 'swiper-zoom-container',
      minRatio: 1,
      maxRatio: activeZoomScale < MAX_ZOOM ? activeZoomScale + 1 : MAX_ZOOM,
    }),
    [activeZoomScale],
  );

  const onSlideChange = (props: SwiperClass) => {
    setActiveSlide(props.realIndex);
  };

  const onZoomChange = (_: SwiperClass, scale: number) => {
    if (scale === 1 && !swipeNavEnabled) setSwipeNavEnabled(true);
    if (scale > 1 && swipeNavEnabled) setSwipeNavEnabled(false);

    setActiveZoomScale(scale);
  };

  return (
    <FloorplanGalleryContainer>
      <Swiper
        slidesPerView={1}
        modules={WithZoomModules}
        loop
        keyboard
        onSlideChange={onSlideChange}
        zoom={zoomConfig}
        allowTouchMove={swipeNavEnabled}
        onZoomChange={onZoomChange}
        autoHeight
        onSwiper={setSwiperInstance}
      >
        {images.map((image) => (
          <SwiperSlide key={image.href}>
            <ZoomContainer className="swiper-zoom-container">
              <img src={image.href} alt={`${image.caption}-gallery`} />
            </ZoomContainer>
          </SwiperSlide>
        ))}
        <GalleryZoom
          maxZoom={MAX_ZOOM}
          activeZoomScale={activeZoomScale}
          t9n={t9n}
        />
      </Swiper>
      {swiperInstance && (
        <NavigationContainer>
          <CarouselBtn
            direction={CarouselDirections.PREVIOUS}
            isGallery
            swiper={swiperInstance}
          />
          <CarouselBtn
            direction={CarouselDirections.NEXT}
            isGallery
            swiper={swiperInstance}
          />
        </NavigationContainer>
      )}
      <SlidesPagination activeSlideIndex={activeSlide} images={images} />
    </FloorplanGalleryContainer>
  );
};

export default WithZoom;
