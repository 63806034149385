import React, { HTMLAttributes, SyntheticEvent } from 'react';

type GenericProps = {
  [index: string]: unknown;
};
function preventFocusOnMouseDown(e: SyntheticEvent) {
  e.preventDefault();
}
export function withNoMouseFocus(
  Component: React.FC<HTMLAttributes<HTMLElement>>,
): React.FC<GenericProps> {
  return function WrappedComponent(props: GenericProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component onMouseDown={preventFocusOnMouseDown} {...props} />;
  };
}
