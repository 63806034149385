import React from 'react';

import PropertyCard from 'components/PropertyCard/PropertyCard';
import { PropertyCardType } from 'components/PropertyCard/constants';
import { ViewingTimePeriodTypes } from './types';
import ViewingDate from './ViewingDate';
import { ViewingCardWrapper } from './Viewings.styled';
import { getViewingStatusFlags } from './helpers';

const ViewingTimePeriod: React.FC<ViewingTimePeriodTypes> = ({
  viewings,
  t9n,
}) => {
  return (
    <>
      {viewings.map((viewing) => (
        <div key={`viewing-${viewing.reapitId}`}>
          <ViewingDate
            viewingFlags={getViewingStatusFlags(viewing, t9n)}
            start={viewing.start}
            end={viewing.end}
          />
          <ViewingCardWrapper>
            <PropertyCard
              property={viewing.property}
              agent={viewing.negotiator}
              t9n={t9n}
              type={PropertyCardType.VIEWING}
            />
          </ViewingCardWrapper>
        </div>
      ))}
    </>
  );
};

export default ViewingTimePeriod;
