import React, { useContext } from 'react';
import { FeatureFlags } from 'utils/helpers/detection/types';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { MaintenanceNotice } from 'components/Maintenance/MaintenanceComponent';
import {
  T9nContext,
  T9nProvider,
  withT9nHook,
} from 'providers/T9nProvider/t9nContext';
import { ApolloContextProvider } from 'providers/Apollo/ApolloContextProvider';
import errorT9nQuery from '../../queries/t9n/errorT9n.gql';

const MaintenancePageWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t9n } = useContext(T9nContext);
  const showMaintenancePage = useFeatureIsOn(
    FeatureFlags.MAINTENANCE_MODE as string,
  );

  return showMaintenancePage ? (
    <MaintenanceNotice
      heading={t9n.ErrorPages.Maintenance.Header}
      message={t9n.ErrorPages.Maintenance.Content}
    />
  ) : (
    (children as React.ReactElement)
  );
};

const postponeRenderT9nKeys = ['ErrorPages'];
const HookedMaintenancePageWrapper = withT9nHook(
  MaintenancePageWrapper,
  errorT9nQuery,
  postponeRenderT9nKeys,
);

const T9nWrapper: React.FC<{
  countryCode: string;
  languageCode: string;
  children: React.ReactNode;
}> = ({ countryCode, languageCode, children }) => (
  <ApolloContextProvider countryCode={countryCode} languageCode={languageCode}>
    <T9nProvider countryCode={countryCode} languageCode={languageCode}>
      <HookedMaintenancePageWrapper>{children}</HookedMaintenancePageWrapper>
    </T9nProvider>
  </ApolloContextProvider>
);

export default T9nWrapper;
