export enum CountryCode {
  AE = 'ae',
  AG = 'ag',
  AU = 'au',
  AUH = 'auh',
  BE = 'be',
  BH = 'bh',
  BHR = 'bhr',
  CA = 'ca',
  CN = 'cn',
  COM = 'com',
  CZ = 'cz',
  DE = 'de',
  DK = 'dk',
  DXB = 'dxb',
  EG = 'eg',
  ES = 'es',
  FR = 'fr',
  GB = 'gb',
  GG = 'gg',
  GI = 'gi',
  GR = 'gr',
  HK = 'hk',
  ID = 'id',
  IE = 'ie',
  IN = 'in',
  IT = 'it',
  JE = 'je',
  JP = 'jp',
  KR = 'kr',
  KY = 'ky',
  LU = 'lu',
  MC = 'mc',
  MX = 'mx',
  MY = 'my',
  NL = 'nl',
  NZ = 'nz',
  OM = 'om',
  OMA = 'oma',
  PL = 'pl',
  PT = 'pt',
  QA = 'qa',
  RU = 'ru',
  SE = 'se',
  SG = 'sg',
  SHJ = 'shj',
  TC = 'tc',
  TH = 'th',
  TW = 'tw',
  US = 'us',
  USA = 'usa',
  VN = 'vn',
}
