import styled, { css } from 'styled-components';
import { transparentize, hideVisually } from 'polished';

import { ReactComponent as Previous } from 'assets/icons/prevCarousel.svg';
import { ReactComponent as Next } from 'assets/icons/nextCarousel.svg';
import {
  sharedGalleryNavBtnStyles,
  galleryNavButtonStyles,
} from '../Gallery/Gallery.styled';

import { PROPERTY_CARD_IMAGE_HEIGHT } from '../constants';
import { CarouselDirections } from './constants';

export const SliderContainerImages = styled.div`
  height: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
`;

export const SliderContainerCards = styled.div`
  max-width: 100%;
  position: relative;
`;

export const ImageContainer = styled.div<{
  isLink?: boolean;
}>`
  height: auto;
  overflow: hidden;
  cursor: ${(props) => (props.isLink ? 'pointer' : 'default')};

  @media screen and (${(props) => props.theme.media.widthMed}) {
    height: ${PROPERTY_CARD_IMAGE_HEIGHT.medViewport};
  }

  @media screen and (${(props) => props.theme.media.widthXlrg}) {
    height: ${PROPERTY_CARD_IMAGE_HEIGHT.largeViewport};
  }
`;

export const sharedNavBtnStyles = css`
  background: ${(props) =>
    transparentize(0.5, props.theme.colorPalette.branding.ink)};
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  outline: 0;
  border: 0;

  &:focus {
    background: ${(props) => props.theme.colorPalette.secondary.steel};
  }
`;

export const NavigationButtonBase = styled.button<{ isGallery?: boolean }>`
  ${sharedNavBtnStyles};

  ${(props) =>
    props.isGallery &&
    css`
      ${sharedGalleryNavBtnStyles};

      svg {
        ${galleryNavButtonStyles};
      }
    `};
`;

export const NavigationButton = styled(NavigationButtonBase)<{
  direction: CarouselDirections;
}>`
  &:disabled {
    opacity: 0.2;
    cursor: default;
  }

  ${(props) =>
    props.direction === CarouselDirections.NEXT
      ? `
          right: 0;
        `
      : `
          left: 0;
        `}
`;

export const PropertyCardCarouselNav = styled.div`
  position: absolute;
  top: 50%;
  width: calc(100% + 40px);
  left: -20px;

  @media screen and (min-width: 960px) {
    width: calc(100% + 70px);
    left: -35px;
  }
`;

const navigationIconStyles = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 13px;
  height: 20px;
  fill: ${(props) => props.theme.colorPalette.branding.white};
`;

export const PreviousIcon = styled(Previous)`
  ${navigationIconStyles}
  left: 8px;
`;

export const NextIcon = styled(Next)`
  ${navigationIconStyles}
  right: 8px;
`;

export const AccessibleBtnTitle = styled.span`
  ${hideVisually};
`;

export const CarouselWrapper = styled.div`
  margin-top: 30px;
`;
