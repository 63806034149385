import { useEffect } from 'react';
import { ActionTypes, createAction, useTracking } from 'hooks/useTracking';
import { useAuth } from 'react-oidc-context';

export const LoginTracker = () => {
  const { isLoading } = useAuth();
  const { setTrackingAction } = useTracking();
  useEffect(() => {
    if (isLoading) return;
    setTrackingAction(createAction(ActionTypes.START_SESSION));
  }, [setTrackingAction, isLoading]);
  return null;
};
