import styled from 'styled-components';

export const NotFoundStyled = styled.div`
  padding: 20px;
  display: flex;
  gap: 24px;
  background: ${(props) => props.theme.colorPalette.branding.white};
  margin: 20px 0 50px;
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) => props.theme.hub.fontSize.propertyCards.large};
  font-family: ${(props) => props.theme.fonts.secondary.font};
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    margin: 0;
    padding: 0;
  }

  img {
    width: 100%;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const SectionHeading = styled.h2`
  font-size: ${(props) => props.theme.typography.xxlarge};
  font-family: ${(props) => props.theme.fonts.primary.font};
  font-weight: ${(props) => props.theme.fonts.primary.fontWeight};
`;

export const Heading = styled.h3`
  font-size: ${(props) => props.theme.typography.large};
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};
`;
