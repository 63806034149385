import { ApolloQueryResult } from '@apollo/client';

import { OfferCardProps } from 'components/Card/OfferCard/types';
import { Appointment, Property, SellingStatus } from 'types/property';
import { ReapitDocument } from 'views/Documents/types';
import { VendorStepCodes } from 'types/vendor';
import { UnavailabilityRule } from '../../components/Calendar/types';
import { VendorStatusCodes } from '../../components/PropertyHeader/types';
import { Task } from '../../components/Tasks/types';

export enum OfferStatus {
  Pending = 'pending',
  Withdrawn = 'withdrawn',
  Rejected = 'rejected',
  Accepted = 'accepted',
  NoteOfInterest = 'noteOfInterest',
  NoteOfInterestWithdrawn = 'noteOfInterestWithdrawn',
}

export type Offer = {
  id: string;
  created: string;
  currency: string;
  date: string;
  amount: number;
  status: OfferStatus;
  negotiator: {
    id: string;
    name: string;
    workPhone: string;
    email: string;
    profileImageUrl: string;
  };
};

export type OfferGroups = {
  pending: OfferCardProps[];
  rejected: OfferCardProps[];
};

export type UniqueMatchRecipientCount = {
  uniqueMatchRecipientCount: number;
  fromUnixTimeMs: number;
  toUnixTimeMs: number;
};

export type VendorResponse = {
  appointmentsByPropertyId: Appointment[];
  unavailability: {
    propertyId: string;
    unavailability: UnavailabilityRule[];
  };
  property: Property;
  offersByPropertyId: Offer[];
  uniqueMatchRecipientCount?: UniqueMatchRecipientCount;
};

export type VendorSellingStatusStepCodeMap = {
  [key in SellingStatus]: VendorStepCodes;
};

export type ActiveStep = {
  number: number;
  name: VendorStepCodes;
  title: string;
  copy: string;
};

export interface SignpostProps {
  backgroundImg: string;
  title: string;
  body: string;
  tag: string;
  handleClick: () => void;
}

export type ApolloRefetchProperty = (variables?: {
  propertyId: string;
}) => Promise<ApolloQueryResult<VendorResponse>>;

export type FeedbackProps = {
  viewingDate: string;
  attendee: string;
  negotiator: string;
  comments: string;
};

export type Profile = {
  userId: string;
  viewingFeedbackVisible: boolean;
  landlordViewingFeedbackVisible: boolean;
};

export interface VendorPageProps {
  [key: string]: unknown;
  documents?: ReapitDocument[];
  documentTasks?: Task[];
  profile?: Profile;
}
export type VendorViewConfigProps = {
  [k in VendorStatusCodes]: string[];
};

export type InstructionResponse = {
  propertyInstruction: {
    success: boolean;
    message: string;
  };
};
export interface WithPropertyIdProps {
  propertyId: string;
  tasks?: Task[];
}
