import styled from 'styled-components';
import { transparentize } from 'polished';

export const PriceContainer = styled.div<{
  isCommercialOrFarm?: boolean;
}>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    !props.isCommercialOrFarm &&
    `
    margin-bottom: 8px;
  `}
`;

export const AreaSize = styled.span<{ isMiniCard?: boolean }>`
  padding: 15px 0 0;
  font-family: ${(props) => props.theme.fonts.primary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};
  font-size: ${(props) =>
    props.isMiniCard
      ? props.theme.typography.default
      : props.theme.typography.large};
  color: ${(props) => props.theme.colorPalette.branding.ink};

  + span {
    font-family: ${(props) => props.theme.fonts.secondary.font};
    font-size: ${(props) =>
      props.isMiniCard
        ? props.theme.typography.xsmall
        : props.theme.typography.small};
    color: ${(props) => props.theme.colorPalette.branding.ink};
    margin-top: 10px;
  }

  + span span,
  .original-price {
    font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
    font-family: ${(props) => props.theme.fonts.secondary.font};
    font-size: ${(props) =>
      props.isMiniCard
        ? props.theme.typography.xsmall
        : props.theme.typography.small};
    color: ${(props) => props.theme.colorPalette.branding.ink};
  }
`;

export const CommercialPrice = styled.span<{
  darkMode: boolean;
  isMiniCard?: boolean;
}>`
  span + span {
    display: block;
    font-size: ${(props) =>
      props.isMiniCard
        ? props.theme.typography.xxsmall
        : props.theme.typography.xsmall};
    color: ${(props) =>
      props.darkMode
        ? transparentize(0.2, props.theme.hub.colorPalette.primary.white)
        : props.theme.hub.colorPalette.primary.grey};
  }
`;
export const PriceHiddenPrefix = styled.h3<{
  darkMode: boolean;
  isMiniCard?: boolean;
}>`
  font-family: ${(props) => props.theme.fonts.primary.font};
  font-weight: ${(props) => props.theme.fonts.primary.fontWeight};
  font-size: ${(props) =>
    props.isMiniCard
      ? props.theme.typography.large
      : props.theme.typography.xlarge};
  color: ${(props) =>
    props.darkMode
      ? props.theme.colorPalette.branding.white
      : props.theme.colorPalette.branding.ink};
  margin: 0 0 10px 0;

  @media screen and (${(props) => props.theme.media.widthMed}) {
    color: ${(props) => props.theme.colorPalette.branding.ink};
  }

  @media screen and (${(props) => props.theme.media.widthXlrg}) {
    font-size: ${(props) =>
      props.isMiniCard
        ? props.theme.typography.xlarge
        : props.theme.typography.xxlarge};
  }
`;

export const Prefix = styled.span<{ darkMode: boolean; isMiniCard?: boolean }>`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) => props.theme.typography.xxsmall};
  color: ${(props) =>
    props.darkMode
      ? transparentize(0.2, props.theme.hub.colorPalette.primary.white)
      : props.theme.hub.colorPalette.primary.grey};

  span {
    font-family: ${(props) => props.theme.fonts.secondary.font};
    font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};

    color: ${(props) => props.theme.hub.colorPalette.primary.grey};
    font-size: ${(props) =>
      props.isMiniCard
        ? props.theme.typography.xxsmall
        : props.theme.typography.xsmall};
  }

  @media screen and (${(props) => props.theme.media.widthMed}) {
    color: ${(props) => props.theme.hub.colorPalette.primary.grey};
  }
  @media screen and (${(props) => props.theme.media.widthLtMed}) {
    margin-bottom: 5px;
  }
`;

export const Suffix = styled.span<{
  darkMode: boolean;
  isMiniCard?: boolean;
  isCommercialOrFarm?: boolean;
}>`
  margin-top: 10px;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) =>
    props.isMiniCard
      ? props.theme.typography.xsmall
      : props.theme.typography.small};
  color: ${(props) => props.theme.colorPalette.branding.ink};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
    font-family: ${(props) => props.theme.fonts.secondary.font};
    color: ${(props) => props.theme.colorPalette.branding.ink};
    font-size: ${(props) =>
      props.isMiniCard
        ? props.theme.typography.xsmall
        : props.theme.typography.small};
  }
`;

export const PriceText = styled.span<{
  darkMode: boolean;
  isMiniCard?: boolean;
}>`
  font-family: ${(props) => props.theme.fonts.primary.font};
  font-weight: ${(props) => props.theme.fonts.primary.fontWeight};
  font-size: ${(props) =>
    props.isMiniCard
      ? props.theme.typography.large
      : props.theme.typography.xlarge};
  color: ${(props) =>
    props.darkMode
      ? props.theme.colorPalette.branding.white
      : props.theme.colorPalette.branding.ink};
  margin: 5px 0 0;

  @media screen and (${(props) => props.theme.media.widthMed}) {
    color: ${(props) => props.theme.colorPalette.branding.ink};
  }

  @media screen and (${(props) => props.theme.media.widthXlrg}) {
    font-size: ${(props) =>
      props.isMiniCard
        ? props.theme.hub.fontSize.propertyCards.xLarge
        : props.theme.hub.fontSize.propertyCards.xxLarge};
  }

  .original-price {
    font-size: ${(props) => props.theme.typography.default};
    color: ${(props) => props.theme.hub.colorPalette.primary.grey};
  }

  .original-price--small {
    color: ${(props) => props.theme.hub.colorPalette.primary.grey};
    font-size: ${(props) =>
      props.isMiniCard
        ? props.theme.typography.xxsmall
        : props.theme.typography.xsmall};
  }
`;

export const AcceptedPriceText = styled(PriceText)`
  color: ${(props) => props.theme.colorPalette.secondary.green};
`;

export const RentalPrice = styled(PriceText)`
  font-size: ${(props) => props.theme.typography.xlarge};
  text-align: left;
  margin-bottom: 10px;
`;

export const RentalPriceFrequency = styled.span<{
  darkMode: boolean;
  isLowercase?: boolean;
  isMiniCard?: boolean;
}>`
  display: block;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) =>
    props.isMiniCard
      ? props.theme.typography.xsmall
      : props.theme.typography.small};
  color: ${(props) =>
    props.darkMode
      ? transparentize(0.2, props.theme.hub.colorPalette.primary.white)
      : props.theme.colorPalette.branding.ink};
  text-transform: ${(props) => (props.isLowercase ? 'none' : 'capitalize')};
  margin: 5px 0;

  a {
    color: ${(props) =>
      props.darkMode
        ? transparentize(0.2, props.theme.hub.colorPalette.primary.white)
        : props.theme.colorPalette.branding.ink};
  }
`;

export const RentBasis = styled.span<{
  darkMode?: boolean;
  isMiniCard?: boolean;
}>`
  display: block;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) =>
    props.isMiniCard
      ? props.theme.typography.xxsmall
      : props.theme.typography.xsmall};
  color: ${(props) =>
    props.darkMode
      ? transparentize(0.2, props.theme.hub.colorPalette.primary.white)
      : props.theme.hub.colorPalette.primary.grey};
  margin: 5px 0 0;

  a {
    color: ${(props) =>
      props.darkMode
        ? transparentize(0.2, props.theme.hub.colorPalette.primary.white)
        : props.theme.colorPalette.branding.ink};
  }

  div > span {
    font-size: ${(props) =>
      props.isMiniCard
        ? props.theme.typography.xxsmall
        : props.theme.typography.xsmall};
    color: ${(props) =>
      props.darkMode
        ? transparentize(0.2, props.theme.hub.colorPalette.primary.white)
        : props.theme.hub.colorPalette.primary.grey};
  }
`;

export const AccompaningLinkWrapper = styled.div`
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.small};
  margin-top: 10px;
  display: inline;
  z-index: 1;
  position: relative;
`;

export const AccompaningLink = styled.a<{ isMiniCard?: boolean }>`
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) =>
    props.isMiniCard
      ? props.theme.typography.xsmall
      : props.theme.typography.small};
  color: ${(props) => props.theme.colorPalette.branding.ink};
  text-decoration: none;

  span {
    border-bottom: 1px solid
      ${(props) => props.theme.colorPalette.secondary.red};
  }
`;
