import { Address as AddressModel } from 'types/property';

export const getAddressLine = (address: AddressModel, isFull?: boolean) => {
  if (!address) {
    return '';
  }
  const additionalAddress = isFull ? `, ${address.postcode}` : '';
  if (address.buildingName && address.buildingNumber) {
    return `${address.buildingName}, ${address.buildingNumber} ${address.line1}${additionalAddress}`;
  }
  if (address.buildingNumber) {
    return `${address.buildingNumber} ${address.line1}${additionalAddress}`;
  }
  if (address.buildingName) {
    return `${address.buildingName} ${address.line1}${additionalAddress}`;
  }
  return `${address.line1}${additionalAddress}`;
};
