import moment from 'moment';

import { Statuses } from 'components/Card/types';
import { OfferCardProps } from 'components/Card/OfferCard/types';
import { T9nHubResource } from 'providers/T9nProvider/types/t9n';
import { Offer, OfferGroups, OfferStatus } from '../../views/Vendor/types';

export const getOfferTag = (
  status: string,
  t9n: T9nHubResource,
  isApplicantCard?: boolean,
) => {
  if (isApplicantCard && status === OfferStatus.Pending) {
    return {
      title: t9n.Offers.NewOffer,
      status: Statuses.WARNING,
    };
  }

  switch (status) {
    case OfferStatus.Rejected:
      return {
        title: t9n.Offers.Rejected,
        status: Statuses.ERROR,
      };
    case OfferStatus.Withdrawn:
      return {
        title: t9n.Offers.Withdrawn,
        status: Statuses.REMOVED,
      };
    case OfferStatus.Accepted:
      return {
        title: t9n.Offers.Accepted,
        status: Statuses.SUCCESS,
      };
    default:
      return null;
  }
};

export const groupOffers = (offers: Offer[], t9n: T9nHubResource) => {
  return offers.reduce(
    (acc, offer) => {
      if (offer.status === OfferStatus.NoteOfInterestWithdrawn) {
        return acc;
      }

      const offerToAdd: OfferCardProps = {
        id: offer.id,
        status: offer.status,
        date: {
          dueDate: new Date(offer.date),
          isExpired: offer.status === OfferStatus.Rejected,
        },
        price: offer.amount,
        negotiator: {
          name: offer.negotiator?.name || '',
          role: '',
          telephone: offer.negotiator?.workPhone || '',
          email: offer.negotiator?.email || '',
          thumbnail: offer.negotiator?.profileImageUrl || '',
        },
      };

      return offer.status === OfferStatus.Pending ||
        offer.status === OfferStatus.NoteOfInterest
        ? {
            ...acc,
            pending: [...acc.pending, offerToAdd],
          }
        : {
            ...acc,
            rejected: [...acc.rejected, offerToAdd],
          };
    },
    { pending: [], rejected: [] } as OfferGroups,
  );
};

export const filterYoungerThan48Hours = (offer: OfferCardProps) => {
  const todayMoment = moment();
  const offerMoment = moment(offer.date.dueDate);
  return moment.duration(todayMoment.diff(offerMoment)).as('hours') > 48;
};

export const filterOffersOnly = (offers: OfferCardProps[]) =>
  offers.filter(
    (offer: OfferCardProps) =>
      offer.status !== OfferStatus.NoteOfInterest &&
      offer.status !== OfferStatus.NoteOfInterestWithdrawn,
  );
