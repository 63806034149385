import { PropertyTenures, PropertyVerticals } from 'constants/global';
import React from 'react';

export enum TaskType {
  UPLOAD_DOC = 'UPLOAD_DOCUMENT',
  SIGN_DOC = 'SIGN_DOCUMENT',
  LICENSE = 'LICENSE',
  ID_VERIFICATION = 'IDVERINPROG',
}

export enum Statuses {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFORMATION = 'info',
  REMOVED = 'removed',
  ERROR = 'error',
  LOCKED = 'locked',
  SAVILLS = 'savills',
  ZOOPLA = 'zoopla',
  RIGHTMOVE = 'rightmove',
  APPRAISAL = 'appraisal',
  LIVE_TENANCY = 'liveTenancy',
  COMPLETE = 'complete',
  MANAGED = 'managed',
  LIGHT_COMPLETE = 'lightComplete',
}

export type CardProps = {
  children: React.ReactNode;
};

export type DateTypes = {
  dueDate: Date;
  isExpired: boolean;
};

export type TaskCardProps = {
  id: string;
  title?: string;
  body?: string;
  tags?: TagProps[];
  items?: CardFooterProps;
  date?: DateTypes;
  icon?: CardIcons;
  theme?: string;
  ctas?: CTAProps[];
  price?: number;
  contact?: ContactProps;
  propertyAddress?: string;
  type?: TaskType;
  selectTask?: (id: string) => void;
};

export type CardTypeProps = {
  tags?: TagProps[];
};

export interface TagProps {
  title: string;
  status: Statuses;
}

export interface CardFooterProps {
  documents?: string[];
}

export type CardSidebarProps = {
  children?: React.ReactNode;
  date?: DateTypes;
  icon?: CardIcons;
  theme?: string;
};

export enum CardIcons {
  DOCUMENT = 'document',
  HOUSE = 'house',
  PERSON = 'person',
}

export enum CtaTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  INLINE = 'inline',
}

export enum CtaLinkTypes {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  MODAL = 'modal',
}

export enum CardTypes {
  DEFAULT = 'default',
  TASK = 'task',
}

export type CTAProps = {
  type: CtaTypes;
  ctaText: string;
  link?: string;
  linkType?: CtaLinkTypes;
};

export type ContactProps = {
  name: string;
  role: string;
  telephone: string;
  email: string;
  thumbnail: string;
};

export type AlertCardProps = {
  vertical: PropertyVerticals;
  area: string[];
  tenure: PropertyTenures;
  price: string;
  propertyTypes: string | string[];
  bedrooms: string;
  geoLocation: google.maps.LatLng | google.maps.LatLngLiteral;
};

export type DefaultCardProps = {
  children: JSX.Element | string;
  cardType?: CardTypes;
  isFullWidth?: boolean;
};

export interface StatCardProps {
  title: string;
  value?: string;
  loading?: boolean;
  error?: boolean;
  tooltip?: string;
  tooltipId?: string;
}
