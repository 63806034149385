/* eslint-disable no-console */
import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import {
  ApolloProvider,
  ApolloContextValue,
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';
import useFatalError from 'providers/FatalError/useFatalError';
import { useAuth } from 'react-oidc-context';

import { GPS_BEARER } from 'constants/cookies';
import { getCookieDomain } from 'utils/helpers/misc/misc';
import {
  getApolloClient,
  getSecureApolloClient,
  LocalizationProps,
} from './apolloClient';

const domain = getCookieDomain();

export function ApolloContextProvider({
  countryCode,
  languageCode,
  children,
}: PropsWithChildren<
  ApolloContextValue & LocalizationProps
>): ReactElement | null {
  const [apolloClient, setClient] =
    React.useState<ApolloClient<NormalizedCacheObject> | null>(null);
  const fatalErrorCallback = useFatalError();

  useEffect(() => {
    const client = getApolloClient(
      { countryCode, languageCode },
      fatalErrorCallback,
    );
    setClient(client);
  }, [fatalErrorCallback, countryCode, languageCode]);

  return apolloClient ? (
    <ApolloProvider client={apolloClient}> {children} </ApolloProvider>
  ) : null;
}

export function SecureApolloContextProvider({
  countryCode,
  languageCode,
  isPreview,
  children,
}: PropsWithChildren<
  ApolloContextValue & LocalizationProps
>): ReactElement | null {
  const auth = useAuth();
  const [apolloClient, setClient] =
    React.useState<ApolloClient<NormalizedCacheObject> | null>(null);
  const fatalErrorCallback = useFatalError();
  const sessionToken = sessionStorage.getItem(
    `oidc.user:${window.appConfig.authServerUri}:savills-web`,
  );

  useEffect(() => {
    const dontRun = auth.isLoading || auth.isAuthenticated || isPreview;
    if (dontRun) return;
    auth.signinRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isLoading, auth.isAuthenticated]);

  useEffect(() => {
    const authProfile = auth?.user?.profile;
    const authToken = auth?.user?.access_token;
    const expiry = auth?.user?.expires_at ?? 0;
    if (authProfile) {
      document.cookie = `${GPS_BEARER}=bearer ${authToken}; expires=${new Date(
        expiry * 1000,
      ).toUTCString()}; path=/; domain=${domain}; sameSite=none; secure`;
    }
  }, [auth.user]);

  useEffect(() => {
    if (!auth.isLoading && (!isPreview || (isPreview && sessionToken))) {
      const client = getSecureApolloClient(
        auth,
        { countryCode, languageCode },
        fatalErrorCallback,
        isPreview,
      );
      setClient(client);

      const url = new URL(window.location.href);
      url.searchParams.delete('code');
      url.searchParams.delete('state');
      url.searchParams.delete('iss');
      window.history.replaceState({}, '', url.toString());
    }
  }, [
    auth,
    fatalErrorCallback,
    countryCode,
    languageCode,
    isPreview,
    sessionToken,
  ]);

  return apolloClient ? (
    <ApolloProvider client={apolloClient}> {children} </ApolloProvider>
  ) : null;
}
