/* eslint-disable react/jsx-props-no-spreading */
import { DocumentNode, useQuery } from '@apollo/client';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { T9nHubResource } from 'providers/T9nProvider/types/t9n';
import { T9N_CLIENT_NAME } from 'providers/Apollo/apolloClient';

type AddT9n = (t9nSet: Partial<T9nHubResource>) => void;

interface T9nContextInterface {
  countryCode: string;
  languageCode: string;
  addT9n?: AddT9n;
  t9n: T9nHubResource;
}

interface T9nProviderProps {
  countryCode: string;
  languageCode: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const T9nContext = createContext<T9nContextInterface>(
  {} as T9nContextInterface,
);

export const T9nProvider: React.FC<T9nProviderProps> = ({
  children,
  countryCode,
  languageCode,
}) => {
  const [t9n, setT9n] = useState<T9nHubResource>({} as T9nHubResource);

  const Tn9ProviderValue = useMemo(() => {
    return {
      countryCode,
      languageCode,
      addT9n: (t9nSet: Partial<T9nHubResource>) =>
        setT9n({ ...t9n, ...t9nSet }),
      t9n,
    };
  }, [t9n, countryCode, languageCode]);

  return (
    <T9nContext.Provider value={Tn9ProviderValue}>
      {children}
    </T9nContext.Provider>
  );
};

function verifyPath(path: string[], obj: { [key: string]: unknown }): boolean {
  if (path.length === 1) {
    return !!obj[path[0]];
  }
  return obj[path[0]]
    ? verifyPath(path.slice(1), obj[path[0]] as { [key: string]: unknown })
    : false;
}

export function withT9nHook<ComponentProps>(
  Component: React.FC<ComponentProps & JSX.IntrinsicAttributes>,
  query: DocumentNode,
  postponeRenderT9nKeys?: string[],
) {
  return (props: ComponentProps & JSX.IntrinsicAttributes) => {
    const { t9n, addT9n, countryCode, languageCode } = useContext(T9nContext);
    const { loading, data } = useQuery<{
      hubResource: Partial<T9nHubResource>;
    }>(query, {
      variables: {
        countryCode,
        languageCode,
      },
      errorPolicy: 'all',
      context: {
        clientName: T9N_CLIENT_NAME,
      },
      fetchPolicy: 'cache-first',
    });
    useEffect(() => {
      if (addT9n && data) {
        addT9n(data.hubResource);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, data]);

    const shouldRender = useMemo(() => {
      if (!t9n) {
        return false;
      }

      return (
        !postponeRenderT9nKeys ||
        postponeRenderT9nKeys.reduce((valid, key) => {
          const hasKey = verifyPath(key.split('.'), t9n);
          return valid && hasKey;
        }, true)
      );
    }, [t9n]);
    return shouldRender ? <Component {...props} /> : null;
  };
}
