import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { ROUTES } from 'constants/routes';

import {
  SharedHeader,
  SharedHeading,
} from 'components/PropertyPageShared/PropertySharedComponents.styled';

import TabPanel from 'components/TabPanel/TabPanel';
import { EmptyOffersMessage } from 'components/Offers/Offers.styled';
import ApplicantOfferCard from 'components/Offers/ApplicantOfferCard';
import { T9nContext } from 'providers/T9nProvider/t9nContext';
import { T9nHubResource } from 'providers/T9nProvider/types/t9n';
import { FeatureFlags } from 'utils/helpers/detection/types';
import withMaintenanceMode from 'components/Maintenance/ReapitMaintenanceMode';
import { useApplicantContext } from '../Applicant';
import { Heading } from '../Applicant.styled';
import { formatOffers } from '../helpers';
import { ApplicantPropertyOffers, OfferType } from '../types';

const getTabContent = (
  t9n: T9nHubResource,
  activeOffers?: ApplicantPropertyOffers[],
  archivedOffers?: ApplicantPropertyOffers[],
  type: OfferType = OfferType.ACTIVE,
) => {
  return (
    <>
      {!!activeOffers?.length && (
        <>
          <Heading>
            {t9n.Applicant.Offers.ActiveTitle} ({activeOffers.length})
          </Heading>
          {activeOffers.map((offer) => (
            <ApplicantOfferCard
              key={`${offer.property.reapitId}-applicantCard`}
              offer={offer}
              t9n={t9n}
            />
          ))}
        </>
      )}
      {type === OfferType.ACTIVE && !activeOffers?.length && (
        <EmptyOffersMessage>{t9n.Offers.NoOffers}</EmptyOffersMessage>
      )}
      {!!archivedOffers?.length && (
        <>
          <Heading>
            {t9n.Applicant.Offers.ArchivedTitle} ({archivedOffers.length})
          </Heading>
          {archivedOffers.map((offer) => (
            <ApplicantOfferCard
              key={`${offer.property.reapitId}-applicantCard`}
              offer={offer}
              t9n={t9n}
            />
          ))}
        </>
      )}

      {type === OfferType.ARCHIVED && !archivedOffers?.length && (
        <EmptyOffersMessage>{t9n.Offers.NoOffers}</EmptyOffersMessage>
      )}
    </>
  );
};

const OffersView = () => {
  const { data } = useApplicantContext();
  const { t9n } = useContext(T9nContext);
  const { offers } = data.applicantRecord;
  const showOffers = useFeatureIsOn(FeatureFlags.APPLICANT_OFFERS as string);
  const navigate = useNavigate();

  if (offers.length === 0 || !showOffers) {
    navigate(ROUTES.APPLICANT.ROOT);
  }

  const activeOffers = formatOffers(offers, t9n, OfferType.ACTIVE);
  const archivedOffers = formatOffers(offers, t9n, OfferType.ARCHIVED);

  const offerTabs = [
    {
      id: t9n.Applicant.Offers.ActiveTitle,
      title: `${t9n.Applicant.Offers.ActiveTitle} (${activeOffers.length})`,
      body: getTabContent(t9n, activeOffers, undefined, OfferType.ACTIVE),
    },
    {
      id: t9n.Applicant.Offers.ArchivedTitle,
      title: `${t9n.Applicant.Offers.ArchivedTitle} (${archivedOffers.length})`,
      body: getTabContent(t9n, undefined, archivedOffers, OfferType.ARCHIVED),
    },
  ];

  return (
    <div>
      <SharedHeader>
        <SharedHeading>{t9n.Applicant.Offers.Title}</SharedHeading>
      </SharedHeader>

      <TabPanel tabs={offerTabs} />
    </div>
  );
};

export default withMaintenanceMode(OffersView);
