import { BroadcastChannel } from 'broadcast-channel';
import { GPS_BEARER } from 'constants/cookies';
import { getCookieDomain } from 'utils/helpers/misc/misc';

export const removeSessionBroadCastChannel = new BroadcastChannel(
  'mysRemoveSession',
);

const REDIRECT_PAUSE = 50;

function killSession() {
  const expiredDate = new Date(0).toUTCString();
  const domain = getCookieDomain();
  document.cookie = `${GPS_BEARER}=; expires=${expiredDate}; path=/; domain=${domain}; sameSite=none; secure`;
  window.sessionStorage.clear();
  window.localStorage.clear();
}

removeSessionBroadCastChannel.onmessage = () => {
  if (window.location.pathname.includes('/booking/')) {
    return;
  }
  killSession();
  setTimeout(() => {
    window.location.href = window.appConfig.authServerUri;
  }, REDIRECT_PAUSE);
};

export async function broadcastRemoveSessionMessage() {
  killSession();
  await new Promise((resolve) => {
    setTimeout(resolve, REDIRECT_PAUSE);
  });
  return removeSessionBroadCastChannel.postMessage('removeSession');
}
