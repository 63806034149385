import { PageColumnConfig } from 'components/Layout/Layout.styled';

export enum TabName {
  ActiveOffers = 'activeOffers',
  RejectedOffers = 'rejectedOffers',
}

export const LAYOUT_CONFIG: PageColumnConfig = {
  default: {
    columns: '1fr',
    gap: '0',
  },
};

export const OFFER_DATE_FORMAT = 'D MMMM';
