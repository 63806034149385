import { ServerError } from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { useContext } from 'react';
import { ClientError, FatalErrorHandlerContext } from './FatalErrorHandler';

const useFatalError = () => {
  const { setErrors } = useContext(FatalErrorHandlerContext);

  const fatalErrorCallback = (error: NetworkError, correlationId?: string) => {
    const clientError: ClientError = {
      message: error?.message ?? 'Fatal Error',
      correlationId,
      statusCode: (error as ServerError).statusCode,
    };

    setErrors(clientError);
  };

  return fatalErrorCallback;
};

export default useFatalError;
