import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as Bedrooms } from 'assets/icons/bedrooms.svg';
import { ReactComponent as Bathrooms } from 'assets/icons/bathrooms.svg';
import { ReactComponent as Receptions } from 'assets/icons/receptions.svg';
import { ReactComponent as OfficeGrade } from 'assets/icons/grade.svg';
import { ReactComponent as OfficePeople } from 'assets/icons/people.svg';
import { PropertyAttribute } from 'types/property';

export const PropertyAttributesContainer = styled.div<{
  overflow?: boolean;
}>`
  display: flex;
  justify-content: center;
  white-space: nowrap;
  overflow: ${(props) => (props.overflow ? 'hidden' : 'visible')};
`;

export const PropertyAttributeContainer = styled.div`
  position: relative;
  padding-left: 24px;
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:last-child {
    margin-right: 0;
  }
`;

export const AttributeCount = styled.span`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) => props.theme.typography.xsmall};
  color: ${(props) => props.theme.hub.colorPalette.primary.grey};
`;

export const attributeIconStyles = css`
  width: 18px;
  height: 18px;
  fill: currentColor;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const BedroomsIcon = styled(Bedrooms)`
  ${attributeIconStyles};
  width: 16px;
  height: 16px;
`;

export const BathroomsIcon = styled(Bathrooms)`
  ${attributeIconStyles};
`;

export const ReceptionsIcon = styled(Receptions)`
  ${attributeIconStyles};
`;

export const OfficeGradeIcon = styled(OfficeGrade)`
  ${attributeIconStyles};
`;

export const OfficePeopleIcon = styled(OfficePeople)`
  ${attributeIconStyles};
`;

export const getAttributeIcon = (type: PropertyAttribute) => {
  switch (type) {
    case PropertyAttribute.Bedrooms:
      return <BedroomsIcon />;
    case PropertyAttribute.Bathrooms:
      return <BathroomsIcon />;
    case PropertyAttribute.Receptions:
      return <ReceptionsIcon />;
    case PropertyAttribute.OfficeGrade:
      return <OfficeGradeIcon />;
    case PropertyAttribute.OfficePeople:
      return <OfficePeopleIcon />;
    default:
      return <BedroomsIcon />;
  }
};
