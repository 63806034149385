export function decodeJwt<T>(jwt?: string): T | null {
  if (!jwt) {
    return null;
  }
  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  try {
    const decodedJwt = JSON.parse(window.atob(base64));
    return decodedJwt;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return null;
}
