import React from 'react';
import { PropertySummaryProps } from '../types';
import {
  PropertySummaryItem,
  PropertySummaryList,
} from './PropertySummary.styled';
import { PropertyCardType } from '../constants';

const MAX_ITEMS = 6;
const SAVED_PROPERTIES_MAX_ITEMS = 4;

export const PropertySummary: React.FC<PropertySummaryProps> = ({
  summary,
  type = PropertyCardType.DEFAULT,
  color,
}) => {
  if (!summary) return null;

  const maxItems =
    type === PropertyCardType.SAVED_PROPERTY
      ? SAVED_PROPERTIES_MAX_ITEMS
      : MAX_ITEMS;

  const truncatedItems =
    summary.length > maxItems ? summary.slice(0, maxItems) : summary;

  return (
    <PropertySummaryList color={color} type={type}>
      {truncatedItems.map((item: string) => (
        <PropertySummaryItem
          dangerouslySetInnerHTML={{ __html: `${item}` }}
          color={color}
          key={JSON.stringify(item)}
        />
      ))}
    </PropertySummaryList>
  );
};
