import React, { useContext, useEffect, useState } from 'react';

import { getLocalizationParametersFromCookieOrUrl } from 'utils/helpers/localization/localization';
import { DEFAULT_LOCALE_PARAMS } from 'constants/global';
import { useLocation } from 'react-router-dom';
import { T9nContext } from 'providers/T9nProvider/t9nContext';
import { useAppData } from 'hooks/useAppData';
import { PropertyTypes } from './constants';
import { PropertyNavList } from './Sidebar.styled';
import { PropertyNavProps } from './types';
import { PropertyNavBlock } from './PropertyNavBlock';
import { LettingNav } from './LettingNav';

export const PropertyNav = ({
  item,
  flags,
  propertyList,
  toggleSidebar,
}: PropertyNavProps) => {
  const { t9n } = useContext(T9nContext);

  const { isLandlordEnabled } = useAppData();
  const urlParams = useLocation();
  const pathName = urlParams.pathname;

  const [currentPropertyId, setCurrentPropertyId] = useState('');
  const { countryCode } = getLocalizationParametersFromCookieOrUrl();

  const isUK = countryCode === DEFAULT_LOCALE_PARAMS.COUNTRY;

  useEffect(() => {
    const allProperties = [
      ...(propertyList?.selling || []),
      ...(propertyList?.letting || []),
    ];
    const selectedProperty = allProperties?.find((property) =>
      pathName.includes(property.id),
    );

    if (selectedProperty) {
      setCurrentPropertyId(selectedProperty.id);
    } else {
      setCurrentPropertyId('');
    }
  }, [propertyList, pathName]);

  if (
    [...(propertyList?.letting || []), ...(propertyList?.selling || [])]
      .length === 0 &&
    !isUK
  ) {
    return null;
  }

  return (
    <PropertyNavList>
      <PropertyNavBlock
        title={t9n.Selling}
        properties={propertyList?.selling}
        route={item.route}
        type={PropertyTypes.SELLING}
        selectedPropertyId={currentPropertyId}
        toggleSidebar={toggleSidebar}
      />

      {flags.showLettings && isLandlordEnabled && (
        <LettingNav
          type={PropertyTypes.LETTING}
          selectedPropertyId={currentPropertyId}
          toggleSidebar={toggleSidebar}
          title={t9n.Letting}
          properties={propertyList?.letting}
        />
      )}
    </PropertyNavList>
  );
};
