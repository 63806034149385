import React from 'react';
import { Tag, TagWrapper } from './Card.styled';
import { TagProps } from './types';

const CardTag = ({ tags }: { tags?: TagProps[] }) => {
  if (!tags || tags.length === 0) {
    return null;
  }

  return (
    <TagWrapper>
      {tags.map(({ status, title }) => (
        <Tag key={title} status={status}>
          {title}
        </Tag>
      ))}
    </TagWrapper>
  );
};

export default CardTag;
