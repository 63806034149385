import React, { useState } from 'react';
import { Nav, TabButton, TabGrid } from './TabPanel.styled';
import {
  TabContentProps,
  TabHeaderProps,
  TabPanelProps,
  TabProps,
} from './types';

const TabPanel: React.FC<TabPanelProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const onTabClick = (tabName: TabProps) => () => {
    setActiveTab(tabName);
  };

  const tabHeaders: TabHeaderProps[] = [];
  const tabContent: TabContentProps[] = [];

  tabs.forEach((tab) => {
    const { id, title, body } = tab;
    tabHeaders.push({ id, title });
    tabContent.push({ id, body });
  });

  return (
    <div>
      <Nav>
        {tabHeaders.map((tabHeader) => (
          <TabButton
            key={`tabBtn_${tabHeader.id}`}
            type="button"
            isActive={activeTab.id === tabHeader.id}
            onClick={onTabClick(
              tabs.find((tab) => tab.id === tabHeader.id) as TabProps,
            )}
          >
            {tabHeader.title}
          </TabButton>
        ))}
      </Nav>
      {tabContent.map((tabItem) => (
        <TabGrid
          key={`tabContent${tabItem.id}`}
          isActive={activeTab.id === tabItem.id}
        >
          {tabItem.body}
        </TabGrid>
      ))}
    </div>
  );
};

export default TabPanel;
