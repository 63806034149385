import React, { createContext, useState, useMemo } from 'react';

export type ClientError = {
  statusCode?: number;
  correlationId?: string;
  message: string;
};

export type FatalErrorContext = {
  fatalError: ClientError | null;
  setErrors: (error: ClientError) => void;
};

const defaultValue = {
  fatalError: null,
  setErrors: {} as React.Dispatch<React.SetStateAction<ClientError | null>>,
};

export const FatalErrorHandlerContext =
  createContext<FatalErrorContext>(defaultValue);

const FatalErrorHandlerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [fatalError, setErrors] = useState<ClientError | null>(null);
  const initialContextValue = useMemo(
    () => ({
      fatalError,
      setErrors,
    }),
    [fatalError],
  );

  return (
    <FatalErrorHandlerContext.Provider value={initialContextValue}>
      {children}
    </FatalErrorHandlerContext.Provider>
  );
};

export default FatalErrorHandlerProvider;
