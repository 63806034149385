import React from 'react';
import {
  PaginationWrapper,
  PaginationInner,
  PaginationBulletsList,
  PaginationBullet,
  ImageCaption,
  SlidesCounter,
} from './Gallery.styled';
import { SlidesPaginationProps } from './types';

const SlidesPagination: React.FC<SlidesPaginationProps> = ({
  images,
  activeSlideIndex,
}) => {
  return (
    <PaginationWrapper>
      <PaginationInner>
        <PaginationBulletsList>
          {images.map((image, index) => (
            <PaginationBullet
              key={image.href}
              isactive={index === activeSlideIndex}
            >
              <span>{image.caption}</span>
            </PaginationBullet>
          ))}
        </PaginationBulletsList>
        <ImageCaption>{images[activeSlideIndex].caption}</ImageCaption>
        <SlidesCounter role="region" aria-live="polite">
          {activeSlideIndex + 1}/{images.length}
        </SlidesCounter>
      </PaginationInner>
    </PaginationWrapper>
  );
};

export default SlidesPagination;
