import { Statuses } from 'components/Card/types';
import { MarketingPortals } from 'types/property';
import { MARKETING_PORTAL_TAGS } from './constants';

export const getMarketingPortalTag = (portal: MarketingPortals) => {
  if (portal === MarketingPortals.RIGHTMOVE) {
    return {
      title: MARKETING_PORTAL_TAGS.rightmove,
      status: Statuses.RIGHTMOVE,
    };
  }

  if (portal === MarketingPortals.ZOOPLA) {
    return {
      title: MARKETING_PORTAL_TAGS.zoopla,
      status: Statuses.ZOOPLA,
    };
  }

  return {
    title: MARKETING_PORTAL_TAGS.savillsWebsite,
    status: Statuses.SAVILLS,
  };
};

export const getMarketingPortalTags = (portalTags: MarketingPortals[]) => {
  return portalTags.map((portal) => {
    return getMarketingPortalTag(portal);
  });
};
