/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { Keyboard } from 'swiper';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';

import { WithFallbackImage } from 'components/ConstrainedImage/ConstrainedImage';
import { getSearchUrl } from 'utils/helpers/misc/misc';
import { SliderContainerImages, ImageContainer } from './Carousel.styled';
import CarouselBtn from './CarouselBtn';
import { PropertyCarouselProps } from './types';
import { CarouselDirections } from './constants';

const carouselModules = [Keyboard];

const PropertyCarousel: React.FC<PropertyCarouselProps> = ({
  images,
  link,
  onClick,
  onIndexChange,
}) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(
    null,
  );

  const onSlideChange = (swiper: SwiperClass) => {
    // fix for swiper activeIndex because its buggy
    const amount = images.length;
    let { activeIndex } = swiper;
    if (activeIndex > amount) activeIndex = 1;
    if (activeIndex < 1) activeIndex = amount;
    if (onIndexChange) onIndexChange(activeIndex);
  };

  return (
    <SliderContainerImages>
      <Swiper
        slidesPerView={1}
        onSwiper={setSwiperInstance}
        onSlideChange={onSlideChange}
        modules={carouselModules}
        loop
        keyboard
        style={{ height: '100%', width: '100%' }} // fix to help image center itself
      >
        {images.map((image) => (
          <SwiperSlide key={image.href}>
            {link ? (
              <ImageContainer
                as="a"
                href={`${getSearchUrl()}${link}`}
                isLink
                onClick={onClick}
              >
                <WithFallbackImage src={image.href} alt={image.caption} />
              </ImageContainer>
            ) : (
              <ImageContainer>
                <WithFallbackImage src={image.href} alt={image.caption} />
              </ImageContainer>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {swiperInstance && (
        <div>
          <CarouselBtn
            direction={CarouselDirections.PREVIOUS}
            swiper={swiperInstance}
          />
          <CarouselBtn
            direction={CarouselDirections.NEXT}
            swiper={swiperInstance}
          />
        </div>
      )}
    </SliderContainerImages>
  );
};

export default PropertyCarousel;
