import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

export const DETAILS_BAR_HEIGHT = 69;

export const StyledPageNav = styled.nav`
  background: ${(props) => props.theme.colorPalette.branding.white};
  color: ${(props) => props.theme.colorPalette.branding.ink};
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.small};

  overflow: scroll;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  position: sticky;
  top: 0;

  > ul {
    background: ${(props) => props.theme.colorPalette.branding.white};
  }
`;

export const StyledNav = styled.ul`
  display: flex;

  @media screen and (${(props) => props.theme.media.widthLtSml}) {
    min-width: 100%;
    width: max-content;
  }
`;

export const StyledNavItem = styled.li<{
  isactive?: boolean;
}>`
  padding: 25px;
  border-bottom: ${(props) =>
    props.isactive ? `2px solid ${props.theme.colorPalette.secondary.red}` : 0};
  align-items: flex-start;

  @media screen and (${(props) => props.theme.media.widthLtSml}) {
    padding: 15px 25px;
  }
`;

export const StyledLink = styled(Link)<{
  isactive?: boolean;
}>`
  color: ${(props) =>
    props.isactive
      ? props.theme.colorPalette.secondary.red
      : props.theme.colorPalette.branding.ink};
  position: relative;
  text-decoration: none;
  font-weight: ${(props) =>
    props.isactive
      ? props.theme.fonts.secondary.fontWeightBold
      : props.theme.fonts.secondary.fontWeightMed};
`;

export const StyledAnchorLink = styled(HashLink)<{
  isactive?: boolean;
}>`
  color: ${(props) => props.theme.colorPalette.branding.ink};
  position: relative;
  text-decoration: none;
  font-weight: ${(props) =>
    props.isactive
      ? props.theme.fonts.secondary.fontWeightBold
      : props.theme.fonts.secondary.fontWeightMed};
`;

export const StyledCount = styled.span`
  position: absolute;
  right: -5px;
  top: -18px;
  background: ${(props) => props.theme.colorPalette.secondary.red};
  color: ${(props) => props.theme.colorPalette.branding.white};
  padding: 5px;
  font-size: ${(props) => props.theme.typography.xxsmall};
  border-radius: 3px;
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightBold};
`;
