import React from 'react';
import {
  StyledNavItem,
  StyledLink,
  StyledCount,
  StyledAnchorLink,
} from './StyledPageNav';
import { NavItemProps } from './types';

function NavItem({ item, isactive, isAnchorNav }: NavItemProps) {
  const { value, count, link, isHidden } = item;
  if (isHidden) {
    return null;
  }

  return (
    <StyledNavItem isactive={isactive}>
      {isAnchorNav ? (
        <StyledAnchorLink smooth isactive={isactive} to={link}>
          {value}
        </StyledAnchorLink>
      ) : (
        <StyledLink isactive={isactive} to={link}>
          {value}
          {count > 0 && <StyledCount>{count}</StyledCount>}
        </StyledLink>
      )}
    </StyledNavItem>
  );
}

export default NavItem;
