/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type RouteChangeEvent = {
  event: string;
  path: string;
  pageTitle: string;
  pageLanguage: string;
  pageCountry: string;
};

export type Impression = {
  brand: string;
  category: string[] | undefined;
  id: string | undefined;
  list: string;
  name: string | undefined;
  position: string;
  price: string | undefined;
  variant: null;
};

interface ClickParams {
  type: GaClickType | string;
  id?: string;
  description?: string;
}

type GoogleAnalyticsContextType = {
  setRouteChangeEvent: (event: RouteChangeEvent) => void;
  setImpressions: (impressions: Impression[]) => void;
  setGaEvent: (params: ClickParams) => void;
};

interface GoogleAnalyticsProviderProps {
  children?: React.ReactNode | React.ReactNode[];
}

export enum GaClickType {
  REMOVE_PROPERTY = 'my_savills_remove_property',
  VIEW_ALERT = 'my_savills_view_alert',
  REMOVE_ALERT = 'my_savills_remove_alert',
  TOGGLE_ALERT = 'my_savills_toggle_alert',
}

interface ClickEvent {
  event: string;
  click: string;
  propertyId?: string;
  alertDescription?: string;
}

const GoogleAnalyticsContext = createContext<GoogleAnalyticsContextType>({
  setRouteChangeEvent: () => {},
  setImpressions: () => {},
  setGaEvent: () => {},
});

export const useGoogleAnalytics = () => useContext(GoogleAnalyticsContext);

window.dataLayer = window.dataLayer || [];

export const GoogleAnalyticsProvider: React.FC<
  GoogleAnalyticsProviderProps
> = ({ children }) => {
  const [routeChangeEvent, setRouteChangeEvent] =
    useState<RouteChangeEvent | null>(null);
  const [impressions, setImpressions] = useState<Impression[] | null>(null);
  const [gaEvent, setGaEvent] = useState<ClickParams | null>(null);

  const contextValue = useMemo(() => {
    return { setRouteChangeEvent, setImpressions, setGaEvent };
  }, [setRouteChangeEvent, setImpressions, setGaEvent]);

  useEffect(() => {
    if (routeChangeEvent === null) {
      return;
    }
    if (routeChangeEvent && impressions) {
      window.dataLayer.push({
        ...routeChangeEvent,
        ecommerce: {
          impressions,
        },
      });
      setRouteChangeEvent(null);
      setImpressions(null);
    } else if (routeChangeEvent && !impressions) {
      window.dataLayer.push(routeChangeEvent);
      setRouteChangeEvent(null);
    }
  }, [routeChangeEvent, impressions]);

  useEffect(() => {
    if (gaEvent === null) {
      return;
    }
    const evt: ClickEvent = {
      event: 'internal_click',
      click: gaEvent.type,
    };
    if (gaEvent.id) {
      evt.propertyId = gaEvent.id;
    }
    if (gaEvent.description) {
      evt.alertDescription = gaEvent.description;
    }
    window.dataLayer.push(evt);
    setGaEvent(null);
  }, [gaEvent]);

  return (
    <GoogleAnalyticsContext.Provider value={contextValue}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
};
