import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface PageTitleContextInterface {
  pageTitle?: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}

interface PageTitleProviderProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const PageTitleContext = createContext<PageTitleContextInterface>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPageTitle: () => {},
});

export const PageTitleProvider: React.FC<PageTitleProviderProps> = ({
  children,
}) => {
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    const fullTitle = pageTitle ? `${pageTitle} | MySavills` : 'MySavills';
    document.title = fullTitle;
  }, [pageTitle]);

  const contextValue = useMemo(
    () => ({ pageTitle, setPageTitle }),
    [pageTitle],
  );

  return (
    <PageTitleContext.Provider value={contextValue}>
      {children}
    </PageTitleContext.Provider>
  );
};

export const usePageTitle = () => useContext(PageTitleContext);
