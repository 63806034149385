/*
This is to prevent warnings when using attributes for styling on the Link component
https://styled-components.com/docs/faqs#why-am-i-getting-html-attribute-warnings
*/

import React from 'react';
import { Link } from 'react-router-dom';
import { withNoMouseFocus } from 'utils/helpers/hoc/preventFocusOnClick';

function StyldLink({
  to,
  children,
  className,
  onMouseDown,
}: {
  to: string;
  className: string;
  children: React.ReactNode;
  onMouseDown: () => void;
}) {
  return (
    <Link className={className} to={to} onMouseDown={onMouseDown}>
      {children}
    </Link>
  );
}

export const StyledLink = withNoMouseFocus(StyldLink as React.FC);
