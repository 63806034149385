import { Statuses } from 'components/Card/types';
import { T9nHubResource } from 'providers/T9nProvider/types/t9n';
import { Viewing } from 'views/Applicant/types';

export const getViewingStatusFlags = (
  viewing: Viewing,
  t9n: T9nHubResource,
) => {
  const viewingTags = [];

  if (viewing.isCancelled) {
    return [
      {
        title: t9n.Applicant.Viewings.Tags.Cancelled,
        status: Statuses.ERROR,
      },
    ];
  }

  if (viewing.isConfirmed) {
    viewingTags.push({
      title: t9n.Applicant.Viewings.Tags.Confirmed,
      status: Statuses.SUCCESS,
    });
  } else {
    viewingTags.push({
      title: t9n.Applicant.Viewings.Tags.Unconfirmed,
      status: Statuses.WARNING,
    });
  }

  if (viewing.isVirtual) {
    viewingTags.push({
      title: t9n.Applicant.Viewings.Tags.Virtual,
      status: Statuses.INFORMATION,
    });
  }

  return viewingTags;
};
