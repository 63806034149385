import { theme } from 'savills-ui-kit';

const hubTheme = {
  ...theme,
  hub: {
    colorPalette: {
      primary: {
        white: '#fff',
        yellow: '#ffdf00',
        red: '#cd171e',
        darkBlue: '#252839',
        greyBlue: '#69769e',
        grey: '#78828b',
        ink: '#25273A',
      },
      font: {
        darkGrey: '#313131',
        grey: '#4a4a4a',
        greyGreen: '#464853',
        ink: '#25273A',
        lightGreyBlue: '#939fc5',
      },
      misc: {
        green: '#1e8c30',
        orange: '#ff7a00',
        lightGrey: '#f2f2f2',
        nearBlack: '#0f111f',
        darkGrey: '#252833',
        lightGreyBlue: '#444966',
        purple: '#6b2e50',
      },
      calendar: {
        confirmed: '#2EB574',
        unconfirmed: '#CD171E',
        available: '#FFFFFF',
        unavailable: '#D7DAE2',
      },
      availabilityCalendar: {
        unavailable: '#DCDCDC',
        available: '#B4D7B4',
      },
      propertyCards: {
        farm: '#6c9173',
        commercial: '#ffdf00',
        newDevelopment: '#7987b5',
        zoopla: '#8046f1',
        rightmove: '#01deb6',
      },
    },
    fontSize: {
      menu: {
        xsmall: '10px',
        small: '12px',
        medium: '14px',
        large: '16px',
      },
      page: {
        default: '14px',
        small: '12px',
        medium: '16px',
        large: '24px',
      },
      propertyCards: {
        default: '16px',
        large: '20px',
        xLarge: '24px',
        xxLarge: '28px',
      },
    },
    spacing: {
      default: '20px',
    },
  },
  media: {
    widthXsml: 'min-width: 320px',
    widthSmlPhone: 'min-width: 380px',
    widthSml: 'min-width: 768px',
    widthMed: 'min-width: 1024px',
    widthLrg: 'min-width: 1136px',
    widthXlrg: 'min-width: 1248px',
    widthXXlrg: 'min-width: 1366px',
    widthLtXsml: 'max-width: 319px',
    widthLtSmlPhone: 'max-width: 379px',
    widthLtSml: 'max-width: 767px',
    widthLtMed: 'max-width: 1023px',
    widthLtLrg: 'max-width: 1135px',
    widthLtXlrg: 'max-width: 1247px',
    widthLtXXlrg: 'max-width: 1365px',
  },
  fonts: {
    primary: {
      font: '"DomineWebRegular", "Georgia", "Palatino Linotype", serif',
      name: 'Domine Regular',
      fontWeight: 400,
      fontWeightMed: 400,
      fontWeightBold: 400,
    },
    primaryAlt: {
      font: '"DomineWebBold", "Georgia", "Palatino Linotype", serif',
      name: 'Domine Bold',
      fontWeight: 700,
      fontWeightMed: 700,
      fontWeightBold: 700,
    },
    secondary: {
      font: '"Gotham", sans-serif',
      name: 'Gotham',
      fontWeight: 300,
      fontWeightMed: 500,
      fontWeightBold: 700,
    },
  },
};

export default hubTheme;
