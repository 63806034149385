import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useGoogleMap } from 'savills-ui-kit';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, Outlet, useOutletContext } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import applicantDataQuery from 'queries/applicant/applicantDataQuery.gql';
import {
  PageBody,
  PageGrid,
  PageHeader,
} from 'components/Layout/Layout.styled';

import { checkIfEnabled } from 'helpers/helpers';
import PageNav from 'components/PageNav/PageNav';
import Loading from 'components/Loading/Loading';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { T9nContext, withT9nHook } from 'providers/T9nProvider/t9nContext';
import { AccountStateContext } from 'providers/Account/AccountState';
import applicantT9nQuery from 'queries/t9n/applicantT9n.gql';
import getAlertSearchUrlQuery from 'queries/applicant/getAlertSearchUrlQuery.gql';
import removeAlertMutation from 'queries/applicant/removeAlertMutation.gql';
import toggleSavedAlertMutation from 'queries/applicant/toggleSavedAlertMutation.gql';
import { Profile } from 'types/app';
import removeSavedPropertyMutation from 'queries/applicant/removeSavedPropertyMutation.gql';
import { FeatureFlags } from 'utils/helpers/detection/types';
import { ActionTypes, createAction, useTracking } from 'hooks/useTracking';
import { Property } from 'types/property';
import { getImpressions, getSearchUrl } from 'utils/helpers/misc/misc';
import { PageTitleContext } from 'providers/PageTitle/PageTitleContext';
import {
  GaClickType,
  useGoogleAnalytics,
} from 'providers/GoogleAnalytics/GoogleAnalyticsContext';
import { SimpleAlert, ApplicantRecord, ApplicantResponse } from './types';
import {
  formatApplicantData,
  getPageTitle,
  parseNavItems,
  recursivelyRemoveTypename,
} from './helpers';

export type ApplicantContext = {
  data: ApplicantResponse;
  handlers: {
    onViewResults(alert: SimpleAlert): void;
    onRemoveAlert(alert: SimpleAlert): void;
    onToggleAlert(alert: SimpleAlert): void;
    onRemoveSavedProperty(property: Property): void;
  };
};

export const useApplicantContext = () => {
  return useOutletContext<ApplicantContext>();
};

function ApplicantPage({ profile }: Readonly<{ profile: Profile }>) {
  const { scriptLoaded } = useGoogleMap({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
  });
  const { setTrackingAction } = useTracking();
  const { t9n } = useContext(T9nContext);
  const auth = useAuth();
  const { state: AccountState } = useContext(AccountStateContext);
  const { data, loading, refetch } = useQuery<ApplicantResponse>(
    applicantDataQuery,
    {
      variables: {
        emailAddresses: AccountState.auth?.user?.profile?.name,
      },
      errorPolicy: 'all',
    },
  );

  const [getAlertUrl] = useLazyQuery(getAlertSearchUrlQuery);
  const [removeAlert] = useMutation(removeAlertMutation);
  const [removeSavedProperty] = useMutation(removeSavedPropertyMutation);
  const [toggleAlert] = useMutation(toggleSavedAlertMutation);
  const [deletingAlertId, setDeletingAlertId] = useState<string | null>(null);
  const [togglingAlertId, setTogglingAlertId] = useState<string | null>(null);
  const [deletingPropertyId, setDeletingPropertyId] = useState<string | null>(
    null,
  );
  const [updatedApplicants, setUpdatedApplicants] = useState<ApplicantRecord[]>(
    [],
  );

  const notify = (message: string) =>
    toast.success(message, {
      toastId: 'success',
    });
  const errorNotify = (message: string) =>
    toast.error(message, {
      toastId: 'error',
    });

  const location = useLocation();
  const { pathname } = location;

  const { setPageTitle } = useContext(PageTitleContext);
  const { setImpressions, setGaEvent } = useGoogleAnalytics();

  const isUserConnected = checkIfEnabled(auth);
  const handlers = useMemo(
    () => ({
      async onViewResults(alert: SimpleAlert) {
        const { alertId, searchContext: criteria } = alert;
        try {
          if (AccountState.isPreview) {
            toast.error(t9n.PreviewMode.Notification);
            return;
          }
          setTrackingAction(
            createAction(ActionTypes.VIEW_SAVED_ALERT, { alertId }),
          );
          setGaEvent({
            type: GaClickType.VIEW_ALERT,
            description: alert.searchDescription?.trim(),
          });
          const { data: alertUrlData } = await getAlertUrl({
            variables: {
              alertId,
              criteria: recursivelyRemoveTypename(criteria),
            },
          });
          const { searchUrl } = alertUrlData;
          const shareUrl = searchUrl.searchUrl;
          if (shareUrl) {
            const link = document.createElement('a');
            link.href = `${getSearchUrl()}${shareUrl}&AlertId=${alertId}`;
            link.click();
          }
        } catch (e: unknown) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
      async onRemoveAlert(alert: SimpleAlert) {
        if (AccountState.isPreview) {
          toast.error(t9n.PreviewMode.Notification);
          return;
        }
        const { alertId } = alert;
        setTrackingAction(
          createAction(ActionTypes.REMOVE_SAVED_ALERT, { alertId }),
        );
        setGaEvent({
          type: GaClickType.REMOVE_ALERT,
          description: alert.searchDescription?.trim(),
        });
        setDeletingAlertId(alertId);
        try {
          const { data: removeAlertData } = await removeAlert({
            variables: { alertId },
          });
          if (removeAlertData.removeAlert.success) {
            await refetch();
          }
        } catch (e: unknown) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
        setDeletingAlertId(null);
      },
      async onToggleAlert(alert: SimpleAlert) {
        if (AccountState.isPreview) {
          toast.error(t9n.PreviewMode.Notification);
          return;
        }
        const { alertId } = alert;
        const toggled = alert.ignore ? 'on' : 'off';
        setTrackingAction(
          createAction(ActionTypes.TOGGLE_SAVED_ALERT, {
            alertId,
            toggled,
          }),
        );
        setGaEvent({
          type: `${GaClickType.TOGGLE_ALERT}-${toggled}`,
          description: alert.searchDescription?.trim(),
        });
        setTogglingAlertId(alertId);
        try {
          const { data: toggleAlertData } = await toggleAlert({
            variables: { alertId },
          });
          if (toggleAlertData.toggleSavedAlert.success) {
            await refetch();
          }
        } catch (e: unknown) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
        setTogglingAlertId(null);
      },
      async onRemoveSavedProperty(property: Property) {
        if (AccountState.isPreview) {
          toast.error(t9n.PreviewMode.Notification);
          return;
        }
        const { reapitId: propertyId, externalPropertyId } = property;
        setTrackingAction(
          createAction(ActionTypes.REMOVE_SAVED_PROPERTY, {
            propertyId: externalPropertyId,
          }),
        );
        setGaEvent({
          type: GaClickType.REMOVE_PROPERTY,
          id: externalPropertyId,
        });
        setDeletingPropertyId(propertyId);
        try {
          const { data: removeSavedPropertyData } = await removeSavedProperty({
            variables: { propertyId },
          });
          if (removeSavedPropertyData.removeSavedProperty.success) {
            await refetch();
            notify(t9n.Applicant.Saved.Message.Success);
          } else {
            errorNotify(t9n.Applicant.Saved.Message.Error);
          }
        } catch (e: unknown) {
          errorNotify(`${e}`);
          // eslint-disable-next-line no-console
          console.error(e);
        }

        setDeletingPropertyId(null);
      },
    }),
    [
      getAlertUrl,
      removeAlert,
      toggleAlert,
      refetch,
      setTrackingAction,
      removeSavedProperty,
      setDeletingPropertyId,
      t9n,
      setGaEvent,
      AccountState.isPreview,
    ],
  );

  const viewingFeatureFlagOn = useFeatureIsOn(FeatureFlags.VIEWINGS as string);
  const showViewings =
    viewingFeatureFlagOn && data?.applicantRecord?.viewings?.length;

  const offersFeatureFlagOn = useFeatureIsOn(
    FeatureFlags.APPLICANT_OFFERS as string,
  );

  useEffect(() => {
    if (loading) return;
    const impressions = getImpressions(location.pathname, data);
    setImpressions(impressions);
    const documentTitle = getPageTitle(location.pathname, t9n);
    setPageTitle(documentTitle);
  }, [setPageTitle, t9n, loading, data, setImpressions, location]);

  const navItems = useMemo(
    () =>
      parseNavItems(
        data?.applicantRecord?.offers || [],
        showViewings ? data?.applicantRecord?.viewings : [],
        pathname,
        t9n,
        !offersFeatureFlagOn,
        !viewingFeatureFlagOn,
        isUserConnected,
      ),
    [
      data?.applicantRecord?.offers,
      data?.applicantRecord?.viewings,
      pathname,
      t9n,
      showViewings,
      offersFeatureFlagOn,
      viewingFeatureFlagOn,
      isUserConnected,
    ],
  );

  useMemo(() => {
    if (data?.applicantRecord?.applicants?.length) {
      Promise.resolve(
        formatApplicantData(data.applicantRecord?.applicants, scriptLoaded),
      ).then((res) => setUpdatedApplicants(res as ApplicantRecord[]));
    }
  }, [data, scriptLoaded]);

  const outletContext = useMemo(() => {
    return {
      data: {
        ...data,
        deletingAlertId,
        togglingAlertId,
        deletingPropertyId,
        applicantRecord: {
          ...data?.applicantRecord,
          applicants: updatedApplicants,
        },
      },
      handlers,
    };
  }, [
    data,
    deletingAlertId,
    togglingAlertId,
    deletingPropertyId,
    handlers,
    updatedApplicants,
  ]);

  useEffect(() => {
    if (!loading && data) {
      setTrackingAction(createAction(ActionTypes.APPLICANT_DATA_LOADED, data));
    }
  }, [loading, data, setTrackingAction]);

  if (loading) {
    return <Loading width="100%" />;
  }

  return (
    <PageGrid isApplicantView>
      <ScrollToTop />
      <PageHeader isApplicantView>
        <PageNav navItems={navItems} />
      </PageHeader>
      <PageBody isApplicantView>
        <Outlet context={outletContext} />
      </PageBody>
    </PageGrid>
  );
}

const postponeRenderT9nKeys = ['Applicant'];
export default withT9nHook(
  ApplicantPage,
  applicantT9nQuery,
  postponeRenderT9nKeys,
);
