import React, { useMemo } from 'react';
import { useModal, Modal, useGoogleMap } from 'savills-ui-kit';
import Agent from 'components/Viewings/Agent';
import Address from './Address/Address';
import { PropertyCardAttributes } from './Attributes/Attributes';
import PropertyTypes from './PropertyTypes/PropertyTypes';
import { PropertyCardSizes } from './Sizes/Sizes';
import Price from './Price/Price';
import PropertyCarousel from './Carousel/Carousel';
import PropertyCardMap from './Map/Map';
import {
  CardWrapper,
  PropertyCardHeadContainer,
  PropertyInfoContainer,
  MainDetails,
  PropertyFeatures,
  PropertyExtrasActionsContainer,
  SizeContainer,
  PropertyExtrasButton,
  ButtonTitle,
  MapIcon,
  FloorplanIcon,
  GalleryIcon,
  PropertyTypeContainer,
  AgentWrapper,
} from './PropertyCard.styled';
import { PropertyCardProps } from './types';
import {
  priceQualifierMap,
  AWAITING_IMAGES,
  HIDE_STATUSES,
  PropertyCardType,
} from './constants';
import PropertyCardGallery from './Gallery/Gallery';
import FloorplanGallery from './Gallery/FloorplanGallery';
import { PropertySummary } from './PropertySummary/PropertySummary';
import MarketingPortalTags from './MarketingPortalTags/MarketingPortalTags';

const PropertyCard: React.FC<PropertyCardProps> = ({
  property,
  t9n,
  agent,
  type = PropertyCardType.DEFAULT,
  marketingPortals,
}) => {
  const { openModal } = useModal();
  const { scriptLoaded } = useGoogleMap({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  });
  const {
    address,
    mainPrice,
    acceptedPrice,
    sellingStatus,
    summary,
    lettingStatus,
    geoLocation,
    rent,
    rentFrequency,
  } = property;

  const priceQualifierMapKey =
    priceQualifierMap[property.qualifier as keyof typeof priceQualifierMap];
  const propertyPriceQualifier =
    t9n.PropertyDetailQualifiers[
      priceQualifierMapKey as keyof typeof t9n.PropertyDetailQualifiers
    ];
  const propertyImages = useMemo(() => {
    if (property.images.length) return property.images;

    return AWAITING_IMAGES;
  }, [property.images]);

  const openGallery = () => {
    openModal(`property-${property.externalPropertyId}-gallery-modal`);
    document.body.classList.add('hide-hj');
  };

  const openMap = () => {
    openModal(`property-${property.externalPropertyId}-map-modal`);
    document.body.classList.add('hide-hj');
  };

  const openFloorPlan = () => {
    openModal(`property-${property.externalPropertyId}-floorplan-modal`);
    document.body.classList.add('hide-hj');
  };

  const propertyStatus = lettingStatus || sellingStatus;
  const hidePrice = propertyStatus
    ? HIDE_STATUSES.includes(propertyStatus)
    : false;

  return (
    <CardWrapper>
      <PropertyCardHeadContainer>
        <PropertyCarousel images={propertyImages} />
        <PropertyExtrasActionsContainer>
          <PropertyExtrasButton type="button" onClick={openGallery}>
            <GalleryIcon />
            <ButtonTitle>{t9n.PropertyCard.GalleryButton}</ButtonTitle>
          </PropertyExtrasButton>
          <PropertyExtrasButton type="button" onClick={openMap}>
            <MapIcon />
            <ButtonTitle>{t9n.PropertyCard.MapButton}</ButtonTitle>
          </PropertyExtrasButton>
          {!!property.floorplans?.length && (
            <PropertyExtrasButton type="button" onClick={openFloorPlan}>
              <FloorplanIcon />
              <ButtonTitle>{t9n.PropertyCard.FloorplanButton}</ButtonTitle>
            </PropertyExtrasButton>
          )}
        </PropertyExtrasActionsContainer>
      </PropertyCardHeadContainer>
      <MainDetails type={type}>
        <PropertyInfoContainer isLetting={!!rent && !hidePrice}>
          {type === PropertyCardType.DEFAULT && (
            <MarketingPortalTags
              property={property}
              marketingPortals={marketingPortals}
              t9n={t9n}
            />
          )}
          <Price
            mainPrice={mainPrice}
            rentalPrice={{
              price: rent,
              frequency: `${rentFrequency} ${t9n.Landlord.PropertyCard.Price}`,
            }}
            acceptedPrice={acceptedPrice}
            propertyStatus={sellingStatus || lettingStatus}
            prefix={propertyPriceQualifier}
          />
          <Address address={address} />
          <PropertySummary summary={summary} />

          {agent && (
            <AgentWrapper>
              <Agent t9n={t9n} agent={agent} />
            </AgentWrapper>
          )}
        </PropertyInfoContainer>
        <PropertyFeatures type={type}>
          <PropertyCardAttributes property={property} />
          <SizeContainer>
            <PropertyTypeContainer>
              <PropertyTypes property={property} />
            </PropertyTypeContainer>
            <PropertyCardSizes property={property} />
          </SizeContainer>
        </PropertyFeatures>
      </MainDetails>
      <Modal
        modalKey={`property-${property.externalPropertyId}-map-modal`}
        showCloseButton
      >
        <div>
          <PropertyCardMap
            id="property-card-map"
            googleLoaded={scriptLoaded}
            propertyPosition={geoLocation}
            loadingText={t9n.Map.Loading}
          />
        </div>
      </Modal>
      <Modal
        modalKey={`property-${property.externalPropertyId}-gallery-modal`}
        showCloseButton
        onModalClose={() => document.body.classList.remove('hide-hj')}
      >
        <div>
          <PropertyCardGallery images={propertyImages} t9n={t9n} />
        </div>
      </Modal>
      {!!property.floorplans?.length && (
        <Modal
          modalKey={`property-${property.externalPropertyId}-floorplan-modal`}
          showCloseButton
        >
          <div>
            <FloorplanGallery images={property.floorplans} t9n={t9n} />
          </div>
        </Modal>
      )}
    </CardWrapper>
  );
};

export default PropertyCard;
