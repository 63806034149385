import { T9nHubResource } from 'providers/T9nProvider/types/t9n';
import { SelectOption } from 'savills-ui-kit/lib/components/Select/types';
import { UploadStatus } from 'views/Documents/types';

export enum DocumentStatus {
  NOT_SENT = '-1',
  SENT_FOR_SIGNING = 'SentForSigning',
  SAVED_TO_UNSENT = 'SavedToAnUnsentPackage',
  SIGNED_NOT_EXECUTED = 'SignedNotExecuted',
  SIGNED_EXECUTED = 'SignedAndExecuted',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
}

export enum SignatoryStatus {
  READY_TO_BE_SIGNED = 'ReadyToBeSigned',
  FULLY_SIGNED_AWAITING_EXECUTION = 'FullySignedAwaitingExecution',
  SIGNED_AND_EXECUTED = 'SignedAndExecuted',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
}

export enum DocumentType {
  EPC = 'EPC',
  CERTIFICATE = 'CRT',
  DEFAULT = 'LET',
  LEGAL = 'LGL',
  FINANCIAL = 'FIN',
  OTHER = 'OR',
}

export const DOCUMENT_TYPE_OPTIONS: SelectOption[] = [
  { label: 'Statement and Invoices', value: 'StatementAndInvoices' },
  {
    label: 'Safety Certificate and Licenses',
    value: 'SafetyCertsAndLicenses',
  },
  { label: 'Agreements', value: 'Agreements' },
  { label: 'Terms', value: 'Terms' },
  { label: 'Tenancy Documents', value: 'TenancyDocuments' },
  { label: 'Other', value: 'Other' },
];

export enum DocumentTypeIcon {
  StatementAndInvoices = 'StatementAndInvoices',
  SafetyCertificateAndLicenses = 'SafetyCertsAndLicenses',
  Agreements = 'Agreements',
  Terms = 'Terms',
  TenancyDocuments = 'TenancyDocuments',
  Other = 'Other',
}

export interface SignatureSense {
  documentStatus: DocumentStatus;
  documentIssued?: string;
  documentExecuted?: string;
  signatoryStatus?: SignatoryStatus;
  documentUri?: string;
}

export interface DocumentListItemProps {
  id: string;
  name: string;
  url?: string;
  onItemToggle?: () => void;
  t9n: T9nHubResource;
  title?: string;
  isSelected?: boolean;
  modifiedOn?: string;
  signatureSense?: SignatureSense;
  uploadStatus?: UploadStatus;
  address?: string;
  type?: DocumentTypeIcon;
  hideAddress?: boolean;
  selectedItems?: string[];
  toggleSelectedItems?: (id: string) => void;
  hideMultiSelect?: boolean;
}
