import React from 'react';
import { MaintenanceNotice } from 'components/Maintenance/MaintenanceComponent';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { FeatureFlags } from 'utils/helpers/detection/types';
import { ROUTES } from 'constants/routes';

export const ReapitMaintenanceNotice = () => {
  return (
    <MaintenanceNotice
      heading="Sorry, some features are unavailable."
      message={[
        'Please note that some MySavills features are currently unavailable due to scheduled maintenance.',
        'We apologise for any inconvenience caused.',
        'Thank you for your patience.',
      ]}
      showPageHeader={false}
      cta="Continue your property search"
      ctaHref={ROUTES.APPLICANT.ROOT}
    />
  );
};

const withReapitMaintenanceMode =
  <T extends Record<string, unknown>>(
    WrappedComponent: React.FC<T>,
  ): React.FC<T> =>
  (props: T) => {
    const isReapitMaintenanceMode = useFeatureIsOn(
      FeatureFlags.REAPIT_MAINTENANCE_MODE as string,
    );
    return isReapitMaintenanceMode ? (
      <ReapitMaintenanceNotice />
    ) : (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <WrappedComponent {...props} />
    );
  };

export default withReapitMaintenanceMode;
