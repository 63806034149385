import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { T9nContext } from 'providers/T9nProvider/t9nContext';
import { useAppData } from 'hooks/useAppData';
import { PortfolioPropertyFormatted } from 'types/property';
import {
  HouseIcon,
  LettingNavList,
  PropertyNavItem,
  PropertyNavTitle,
} from './Sidebar.styled';
import { PropertyNavBlockProps } from './types';
import { getFilteredProperties } from './helpers';
import { PortfolioNav } from './PortfolioNav';

export const LettingNav = ({
  type,
  title,
  selectedPropertyId,
  toggleSidebar,
}: PropertyNavBlockProps) => {
  const urlParams = useLocation();
  const pathName = urlParams.pathname;
  const { t9n } = useContext(T9nContext);

  const { portfolioProperties } = useAppData();

  const filteredProperties = getFilteredProperties(portfolioProperties, t9n);

  if (!filteredProperties || filteredProperties.length === 0) {
    return (
      <PropertyNavItem isActiveRoute={pathName === ROUTES.LETTING.MARKETING}>
        <PropertyNavTitle data-testid={`${type}List`}>
          <HouseIcon />
          <Link to={`${ROUTES.LETTING.MARKETING}`}>{title}</Link>
        </PropertyNavTitle>
      </PropertyNavItem>
    );
  }

  return (
    <PropertyNavItem hideBorder>
      <PropertyNavTitle
        isActiveRoute={pathName === ROUTES.LETTING.PORTFOLIO}
        data-testid={`${type}List`}
        onClick={toggleSidebar}
      >
        <HouseIcon />
        <Link to={`${ROUTES.LETTING.PORTFOLIO}`}>{title}</Link>
      </PropertyNavTitle>
      <LettingNavList>
        {filteredProperties.map((portfolio: PortfolioPropertyFormatted) => (
          <PortfolioNav
            link={portfolio.link}
            key={portfolio?.title}
            title={portfolio.title}
            properties={portfolio.properties}
            selectedPropertyId={selectedPropertyId}
            t9n={t9n}
            type={portfolio.type}
            hasMultipleProperties={filteredProperties.length > 1}
            toggleSidebar={toggleSidebar}
          />
        ))}
      </LettingNavList>
    </PropertyNavItem>
  );
};
