import styled from 'styled-components';

interface LoadingContainerProps {
  backgroundColor?: string;
  width?: string;
}

export const LoadingContainer = styled.div<LoadingContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: ${({ width }) => width || '100vw'};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.hub.colorPalette.misc.nearBlack};
  pointer-events: none;
`;

interface LoadingSpinnerProps {
  size?: number;
  insetColor?: string;
  outerColor?: string;
}

export const LoadingSpinner = styled.div<LoadingSpinnerProps>`
  width: ${({ size }) => (size && `${size}px`) || '50px'};
  height: ${({ size }) => (size && `${size}px`) || '50px'};
  border-radius: 50%;
  border: ${({ size }) => (size && `${size / 10}px`) || '5px'} solid
    ${({ theme, outerColor }) =>
      outerColor || theme.hub.colorPalette.primary.yellow};
  border-top: 5px solid
    ${({ theme, insetColor }) =>
      insetColor || theme.hub.colorPalette.primary.darkBlue};
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
