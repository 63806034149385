/* eslint-disable @typescript-eslint/no-empty-function */
import { T9nContext } from 'providers/T9nProvider/t9nContext';
import { Moment } from 'moment';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SelectOption } from 'savills-ui-kit/lib/components/Select/types';
import { shouldAllowDownload } from 'views/Documents/DocumentsPage/helpers';
import { getUniqueTenancies, mapTenancyOptions } from 'views/Documents/helpers';
import { SlimProperty } from 'types/app';
import { formatStreetAddress } from 'helpers/helpers';
import { ReapitDocument, SortBy } from 'views/Documents/types';
import { useAppData } from 'hooks/useAppData';
import {
  DEFAULT_FILTERS,
  DOCUMENT_TYPE_OPTIONS,
  FilterDateKeys,
  FilterKeys,
} from './constants';
import {
  DocumentFilterContextInterface,
  DocumentFilterProviderProps,
  FilterDate,
  FiltersConfig,
} from './types';
import { getFilterFunctions } from './helpers';

export const DocumentFilterContext =
  createContext<DocumentFilterContextInterface>({
    setFiltersConfig: () => {},
    setAllTenancies: () => {},
    setSelectedDocuments: () => {},
    getTenancyOptions: () => {},
    toggleSelectedDocuments: () => {},
    documentTypeOptions: DOCUMENT_TYPE_OPTIONS,
    filtersConfig: DEFAULT_FILTERS,
    updateFiltersConfig: () => {},
    sortOptions: [],
    filterProperties: [],
    getPropertyOptions: () => {},
    updateDateRange: () => {},
    filteredDocuments: [],
    updateFilteredDocuments: () => {},
    handleSelectAll: () => {},
    resetFilters: () => {},
    setPage: () => {},
    currentPage: 0,
    resetFilteredDocuments: () => {},
  });

export const DocumentFilterProvider: React.FC<DocumentFilterProviderProps> = ({
  children,
}) => {
  const { t9n } = useContext(T9nContext);
  const [filtersConfig, setFiltersConfig] =
    useState<FiltersConfig>(DEFAULT_FILTERS);
  const [allTenancies, setAllTenancies] = useState<SelectOption[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [filterProperties, setFilterProperties] = useState<SelectOption[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<ReapitDocument[]>(
    [],
  );
  const [currentPage, setPage] = React.useState(0);
  const [allDocumentsSelected, setAllDocumentsSelected] =
    useState<boolean>(false);
  const { propertyList } = useAppData();
  const updateFilteredDocuments = useCallback(
    (documents: ReapitDocument[]) => {
      if (!documents) return [];
      const docs = documents;

      if (!docs.length) return docs;

      const filterFunctions = getFilterFunctions(
        filtersConfig,
        propertyList.letting,
      );

      const filteredDocs = filterFunctions.reduce(
        (acc, filterFn) => {
          return acc.filter(filterFn);
        },
        [...docs],
      );
      return setFilteredDocuments(filteredDocs);
    },
    [filtersConfig, propertyList.letting],
  );

  const getTenancyOptions = useCallback(
    (documents: ReapitDocument[]) => {
      const tenancyOptions: SelectOption[] = mapTenancyOptions(documents, t9n);
      const uniqueTenancies = getUniqueTenancies(tenancyOptions);
      setAllTenancies([
        { label: t9n.Documents.TenancyLabel, value: '' },
        ...uniqueTenancies,
      ] as SelectOption[]);
    },
    [t9n],
  );

  const getPropertyOptions = useCallback(
    (allProperties: SlimProperty[]) => {
      const propertyOptions = [
        {
          label: `${t9n.Documents.AllProperties} (${allProperties.length})`,
          value: '',
        },
        ...allProperties.map((property) => ({
          label: formatStreetAddress(property.address),
          value: property.id,
        })),
      ];

      setFilterProperties(propertyOptions);
    },
    [t9n],
  );

  const toggleSelectedDocuments = (documentId: string) => {
    setSelectedDocuments((prevSelectedDocuments) => {
      if (prevSelectedDocuments.includes(documentId)) {
        setAllDocumentsSelected(false);
        return prevSelectedDocuments.filter(
          (selectedId) => selectedId !== documentId,
        );
      }
      return [...prevSelectedDocuments, documentId];
    });
  };

  const updateFiltersConfig = (key: FilterKeys, value: string | FilterDate) => {
    setFiltersConfig((prevFiltersConfig: FiltersConfig) => {
      return { ...prevFiltersConfig, [key]: value };
    });
    resetPagination();
  };

  const updateDateRange = (key: FilterDateKeys, date: Moment | null) => {
    setFiltersConfig((prevFiltersConfig: FiltersConfig) => {
      return {
        ...prevFiltersConfig,
        [FilterKeys.DATE]: {
          ...prevFiltersConfig[FilterKeys.DATE],
          [key]: date,
        },
      };
    });
    resetPagination();
  };

  const resetPagination = () => {
    setPage(0);
  };

  const resetFilteredDocuments = () => {
    setFiltersConfig(DEFAULT_FILTERS);
    setFilteredDocuments([]);
  };

  const handleSelectAll = useCallback(
    (documents: ReapitDocument[]) => {
      if (allDocumentsSelected) {
        setSelectedDocuments([]);
        setAllDocumentsSelected(false);
      } else {
        const filterDownloadedDocuments = documents.filter((doc) =>
          shouldAllowDownload(doc.url, doc.signatureSense),
        );
        setSelectedDocuments(filterDownloadedDocuments.map((doc) => doc.id));
        setAllDocumentsSelected(true);
      }
    },
    [allDocumentsSelected],
  );

  const contextValue = useMemo(() => {
    const sortOptions = [
      { label: t9n.Documents.SortDateAsc, value: SortBy.DATE_ASC },
      { label: t9n.Documents.SortDateDesc, value: SortBy.DATE_DESC },
      { label: t9n.Documents.SortAzAsc, value: SortBy.NAME_ASC },
      { label: t9n.Documents.SortAzDesc, value: SortBy.NAME_DESC },
    ];

    return {
      allTenancies,
      setAllTenancies,
      getTenancyOptions,
      selectedDocuments,
      setSelectedDocuments,
      handleSelectAll,
      setAllDocumentsSelected,
      allDocumentsSelected,
      toggleSelectedDocuments,
      filtersConfig,
      documentTypeOptions: [
        { value: '', label: t9n.Documents.TypeSelector },
        ...DOCUMENT_TYPE_OPTIONS,
      ],
      updateFiltersConfig,
      setFiltersConfig,
      sortOptions,
      filterProperties,
      getPropertyOptions,
      updateDateRange,
      filteredDocuments,
      updateFilteredDocuments,
      setPage,
      currentPage,
      resetFilteredDocuments,
      resetFilters: () => setFiltersConfig(DEFAULT_FILTERS),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t9n.Documents.SortDateAsc,
    t9n.Documents.SortDateDesc,
    t9n.Documents.SortAzAsc,
    t9n.Documents.SortAzDesc,
    t9n.Documents.TypeSelector,
    allTenancies,
    getTenancyOptions,
    selectedDocuments,
    handleSelectAll,
    allDocumentsSelected,
    filtersConfig,
    filterProperties,
    getPropertyOptions,
    filteredDocuments,
    updateFilteredDocuments,
    currentPage,
  ]);

  return (
    <DocumentFilterContext.Provider value={contextValue}>
      {children}
    </DocumentFilterContext.Provider>
  );
};

export const useDocumentFilter = () => useContext(DocumentFilterContext);
