import { GestureElements, Ref } from './constants';

const ExtendedZoom = (_ref: Ref) => {
  const { swiper } = _ref;
  const gesture: GestureElements = {};

  const setZoom = (scale: number) => {
    gesture.slideEl = swiper.slides[swiper.activeIndex] as HTMLDivElement;

    if (!swiper.params || typeof swiper.params.zoom !== 'object') return;

    gesture.imageEl = gesture.slideEl.querySelector(
      `.${swiper.params.zoom.containerClass} img`,
    ) as HTMLImageElement;

    gesture.imageWrapEl = gesture.imageEl.closest(
      `.${swiper.params.zoom.containerClass}`,
    ) as HTMLDivElement;

    if (swiper.params.zoom.zoomedSlideClass) {
      gesture.slideEl.classList.add(swiper.params.zoom.zoomedSlideClass);
    }

    if (swiper.params.zoom.zoomedSlideClass && scale === 1) {
      gesture.slideEl.classList.remove(swiper.params.zoom.zoomedSlideClass);
    }

    swiper.zoom.scale = scale;

    gesture.imageWrapEl.style.transitionDuration = '300ms';
    gesture.imageWrapEl.style.transform = 'translate(0, 0)';

    gesture.imageEl.style.transitionDuration = '300ms';
    gesture.imageEl.style.transform = `translate(0, 0) scale(${swiper.zoom.scale})`;
  };

  swiper.zoom.setZoom = setZoom;
};

export default ExtendedZoom;
