import React, { useContext, useEffect } from 'react';
import { T9nContext, withT9nHook } from 'providers/T9nProvider/t9nContext';
import { Headings } from 'savills-ui-kit';
import { PageTitleContext } from 'providers/PageTitle/PageTitleContext';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { ROUTES } from '../../constants/routes';
import {
  NoticePageBody,
  NoticeLink,
  NoticePageWrapper,
  NoticeCTAs,
  MainContent,
} from '../../components/Notice/Notice.styled';
import { ErrorPageTypes } from '../Error/types';
import errorT9nQuery from '../../queries/t9n/errorT9n.gql';

export function UnavailablePreviewPageComponent({ errorType }: ErrorPageTypes) {
  const { t9n } = useContext(T9nContext);

  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => {
    setPageTitle(t9n.PageTitles.Error);
  }, [setPageTitle, t9n.PageTitles]);

  const content = t9n.ErrorPages.UnavailablePreview;

  return (
    <NoticePageWrapper>
      <ScrollToTop />
      <MainContent>
        <Headings.PageHeading>{content.Title}</Headings.PageHeading>
        <NoticePageBody>{content.Body}</NoticePageBody>
        <NoticeCTAs>
          <NoticeLink to={ROUTES.HOME}>{content.Cta}</NoticeLink>
        </NoticeCTAs>
      </MainContent>
    </NoticePageWrapper>
  );
}

const postponeRenderT9nKeys = ['ErrorPages'];
export default withT9nHook(
  UnavailablePreviewPageComponent,
  errorT9nQuery,
  postponeRenderT9nKeys,
);
