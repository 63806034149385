import { LettingStatus } from 'types/property';

export enum PropertyTypes {
  SELLING = 'selling',
  LETTING = 'letting',
}

export const ACTIVE_TENANCIES = [
  LettingStatus.MarketAppraisal,
  LettingStatus.OnTheMarketFirstTime,
  LettingStatus.OnTheMarketReLet,
  LettingStatus.ArrangingTenancy,
  LettingStatus.ActiveTenancyWithRenewal,
  LettingStatus.ActiveTenancyChangeOfSharer,
];

export const HIDDEN_TENANCIES = [
  LettingStatus.Withdrawn,
  LettingStatus.Removed,
  LettingStatus.Invalid,
  LettingStatus.Empty,
];

export const INACTIVE_TENANCIES = [LettingStatus.Complete];

export const SECONDARY_TENANCIES = [
  LettingStatus.ActiveTenancy,
  LettingStatus.EndOfTenancyConfirmed,
];
