import React, { useState } from 'react';
import Truncate from 'react-truncate';
import { ROUTES } from 'constants/routes';
import { Link } from 'react-router-dom';
import CardTag from 'components/Card/CardTag';
import { getStatusTags } from 'views/Landlord/helpers';
import { ManagedType } from 'views/Landlord/types';
import { formatStreetAddress } from 'helpers/helpers';
import { T9nHubResource } from 'providers/T9nProvider/types/t9n';
import { PortfolioTenancy, SidebarPortfolioProperty } from 'types/property';
import {
  CollapseIcon,
  ExpandIcon,
  PortfolioPropertiesListItem,
  PortfolioPropertyTitle,
  StyledExpandCollapse,
  TenancyList,
  TenancyListItem,
} from './Sidebar.styled';

export const PortfolioPropertyItem = ({
  property,
  selectedPropertyId,
  t9n,
  toggleSidebar,
}: {
  property: SidebarPortfolioProperty;
  selectedPropertyId?: string;
  t9n: T9nHubResource;
  toggleSidebar?: () => void;
}) => {
  const [propertyListOpen, setPropertyListOpen] = useState(true);
  const togglePropertyList = () => {
    setPropertyListOpen(!propertyListOpen);
  };

  if (!property?.propertyId || !property?.address) {
    return null;
  }

  const { propertyId, address, tenancies } = property;

  const streetAddress = address ? formatStreetAddress(address) : '';

  return (
    <PortfolioPropertiesListItem isSelected={selectedPropertyId === propertyId}>
      {property.link ? (
        <Link
          aria-label={`${streetAddress}`}
          onClick={toggleSidebar}
          to={`${property.link}`}
        >
          <Truncate lines={1}>{streetAddress}</Truncate>
        </Link>
      ) : (
        <PortfolioPropertyTitle onClick={togglePropertyList}>
          <Truncate lines={1}>{streetAddress}</Truncate>
          <StyledExpandCollapse>
            {!propertyListOpen ? <CollapseIcon /> : <ExpandIcon />}
          </StyledExpandCollapse>
        </PortfolioPropertyTitle>
      )}

      {tenancies && tenancies.length > 0 && (
        <TenancyList isOpen={propertyListOpen}>
          {tenancies.map((tenancy: PortfolioTenancy) => (
            <TenancyListItem onClick={toggleSidebar} key={tenancy.tenancyId}>
              <Link
                aria-label={`${streetAddress}`}
                to={`${ROUTES.LETTING.ROOT}/${propertyId}/${tenancy.tenancyId}`}
              >
                <CardTag
                  tags={getStatusTags(
                    tenancy.status,
                    ManagedType.NONMANAGED,
                    tenancy.isCurrent,
                    t9n,
                    true,
                  )}
                />
              </Link>
            </TenancyListItem>
          ))}
        </TenancyList>
      )}
    </PortfolioPropertiesListItem>
  );
};
