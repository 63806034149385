import React from 'react';
import { AreaType } from 'types/property';
import { getSizeIcon } from './Sizes.styled';
import {
  PropertyAttributesContainer,
  PropertyAttributeContainer,
  AttributeCount,
} from '../Attributes/Attributes.styled';
import { PropertyCardSizeProps, PropertyCardSizesProps } from './types';

export const PropertyCardSizes: React.FC<PropertyCardSizesProps> = ({
  property,
}) => {
  return (
    <PropertyAttributesContainer overflow>
      {property.area?.size && (
        <PropertyCardSize
          type={
            property.area.type === AreaType.PropertySize
              ? AreaType.PropertySize
              : AreaType.LandSize
          }
          value={property.area.size}
        />
      )}
    </PropertyAttributesContainer>
  );
};

export const PropertyCardSize: React.FC<PropertyCardSizeProps> = ({
  type,
  value,
}) => {
  return (
    <PropertyAttributeContainer>
      {getSizeIcon(type)}
      <AttributeCount>{value}</AttributeCount>
    </PropertyAttributeContainer>
  );
};
