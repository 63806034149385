import React, { useEffect } from 'react';
import { Headings } from 'savills-ui-kit';
import { SAVILLS_UK_HOME } from 'constants/routes';

import {
  NoticePageBody,
  Logo,
  Header,
  MainContent,
  NoticeCTAs,
} from 'components/Notice/Notice.styled';
import { MysLink } from 'components/Link/Link';

export const MaintenanceNotice: React.FC<{
  heading?: string;
  message?: string | Array<string>;
  showPageHeader?: boolean;
  cta?: string;
  ctaHref?: string;
}> = ({ heading, message, showPageHeader = true, cta, ctaHref }) => {
  useEffect(() => {
    document.body.classList.add('hide-hj');
    return () => {
      document.body.classList.remove('hide-hj');
    };
  }, []);

  const hasCta = !!cta && !!ctaHref;
  const pageHeading =
    heading ?? 'Sorry, we are down for maintenance right now.';
  const pageBody = Array.isArray(message) ? (
    message.map((msg) => <NoticePageBody key={msg}>{msg}</NoticePageBody>)
  ) : (
    <NoticePageBody>
      {message ?? 'We hope to be back shortly, thank you for your patience.'}
    </NoticePageBody>
  );
  return (
    <div>
      {showPageHeader && (
        <Header>
          <a href={SAVILLS_UK_HOME} aria-label="Savills home">
            <Logo title="Savills Logo" />
          </a>
        </Header>
      )}
      <MainContent>
        <Headings.PageHeading>{pageHeading}</Headings.PageHeading>
        {pageBody}
        {hasCta && (
          <NoticeCTAs>
            <MysLink as="a" href={ctaHref}>
              {cta}
            </MysLink>
          </NoticeCTAs>
        )}
      </MainContent>
    </div>
  );
};
