import React from 'react';
import { PropertyAttribute } from 'types/property';
import {
  getAttributeIcon,
  PropertyAttributesContainer,
  PropertyAttributeContainer,
  AttributeCount,
} from './Attributes.styled';
import {
  PropertyCardAttributeProps,
  PropertyCardAttributesProps,
} from './types';

export const PropertyCardAttributes: React.FC<PropertyCardAttributesProps> = ({
  property,
}) => {
  const {
    isParent,
    newDevelopmentBedrooms,
    newDevelopmentBathrooms,
    newDevelopmentReceptions,
    officeGrade,
    officePeople,
  } = property;

  const bedrooms =
    isParent && newDevelopmentBedrooms
      ? newDevelopmentBedrooms
      : property.bedrooms;
  const bathrooms =
    isParent && newDevelopmentBathrooms
      ? newDevelopmentBathrooms
      : property.bathrooms;
  const receptions =
    isParent && newDevelopmentReceptions
      ? newDevelopmentReceptions
      : property.receptions;

  return (
    <PropertyAttributesContainer>
      {!!bedrooms && (
        <PropertyCardAttribute
          type={PropertyAttribute.Bedrooms}
          count={bedrooms}
        />
      )}
      {!!bathrooms && (
        <PropertyCardAttribute
          type={PropertyAttribute.Bathrooms}
          count={bathrooms}
        />
      )}
      {!!receptions && (
        <PropertyCardAttribute
          type={PropertyAttribute.Receptions}
          count={receptions}
        />
      )}
      {!!officePeople && (
        <PropertyCardAttribute
          type={PropertyAttribute.OfficePeople}
          count={officePeople}
        />
      )}
      {!!officeGrade && (
        <PropertyCardAttribute
          type={PropertyAttribute.OfficeGrade}
          count={officeGrade}
        />
      )}
    </PropertyAttributesContainer>
  );
};

export const PropertyCardAttribute: React.FC<PropertyCardAttributeProps> = ({
  type,
  count,
}) => {
  return (
    <PropertyAttributeContainer>
      {getAttributeIcon(type)}
      <AttributeCount>{count}</AttributeCount>
    </PropertyAttributeContainer>
  );
};
