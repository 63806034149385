import styled from 'styled-components';

export const FloorplanGalleryContainer = styled.div`
  width: 100vw;
`;

export const ZoomContainer = styled.div`
  min-height: 100vh;
  padding-bottom: 36px;

  > img {
    max-height: 100vh;
  }

  @media screen and (${(props) => `${props.theme.media.widthSml})`} {
    padding-bottom: 0;
  }
`;
