import React, { useContext } from 'react';
import { toast } from 'react-toastify';

import theme from 'styled/theme';

import {
  AgentDetailsList,
  AgentDetailsListItem,
  SmallNegCardAgentName,
  SmallNegCardDetailsWrapper,
} from 'components/NegotiatorCard/NegotiatorCard.styled';
import { ContactCTA } from 'components/Card/Card.styled';
import { AccountStateContext } from 'providers/Account/AccountState';
import { WithFallbackImageAgent } from 'components/ConstrainedImage/ConstrainedImage';

import { AgentProps } from './types';

const Agent: React.FC<AgentProps> = ({ agent, t9n }) => {
  const { state } = useContext(AccountStateContext);
  const agentImg = agent.smallImage || agent.image || agent.profileImageUrl;
  const phoneNumber = agent.telephone || agent.workPhone || agent.phoneNumber;
  const contactHref = agent.email
    ? `mailto:${agent.email}`
    : `tel:${phoneNumber}`;

  const handleContact = () => {
    if (state.isPreview) {
      toast.error(t9n.PreviewMode.Notification);
      return;
    }
    window.location.href = contactHref;
  };

  return (
    <SmallNegCardDetailsWrapper>
      <WithFallbackImageAgent src={agentImg} alt={`${agent.name}`} />
      <div>
        <AgentDetailsList color={theme.hub.colorPalette.font.ink}>
          <AgentDetailsListItem margin="4px">
            <SmallNegCardAgentName>{agent.name}</SmallNegCardAgentName>
          </AgentDetailsListItem>
          {agent.office ||
            (agent.officeId && (
              <AgentDetailsListItem margin="4px">
                {agent.office || agent.officeId}
              </AgentDetailsListItem>
            ))}
          <AgentDetailsListItem isBold margin="0">
            {phoneNumber}
          </AgentDetailsListItem>

          <AgentDetailsListItem margin="0">
            <ContactCTA as="button" onClick={handleContact}>
              {t9n.NegotiatorCard.Btn}
            </ContactCTA>
          </AgentDetailsListItem>
        </AgentDetailsList>
      </div>
    </SmallNegCardDetailsWrapper>
  );
};

export default Agent;
