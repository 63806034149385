import * as Cheerio from 'cheerio';
import { SAVILLS_UK_HOME } from 'constants/routes';
import { getVutureApiUrl } from 'helpers/helpers';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface HeaderFooterContextInterface {
  logoUrl: string;
  headerData?: string;
  footerData?: string;
}

interface HeaderFooterProviderProps {
  languageCode: string;
  countryCode: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const HeaderFooterContext = createContext<HeaderFooterContextInterface>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logoUrl: SAVILLS_UK_HOME,
});

export const HeaderFooterProvider: React.FC<HeaderFooterProviderProps> = ({
  languageCode,
  countryCode,
  children,
}) => {
  const vutureHeaderApi = getVutureApiUrl();

  const [footerData, setFooterData] = useState<string | undefined>(undefined);
  const [headerData, setHeaderData] = useState<string | undefined>(undefined);
  const [logoUrl, setLogoUrl] = useState<string>(SAVILLS_UK_HOME);

  useEffect(() => {
    async function fetchFooter() {
      try {
        const response = await fetch(
          `${vutureHeaderApi}?location=${countryCode}&language=${languageCode}`,
        );
        if (response.status !== 200) {
          setFooterData(undefined);
          return;
        }
        const data = await response.json();
        const $ = Cheerio.load(data['site-header--content']);
        const $modalHTML = $('.sv-cookie-management');
        const logo = $('a.sv-header__home').attr('href');
        setLogoUrl(logo || SAVILLS_UK_HOME);
        setHeaderData($modalHTML.html() || '');
        setFooterData(data['site-footer--content']);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    fetchFooter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = useMemo(
    () => ({ logoUrl, footerData, headerData }),
    [logoUrl, footerData, headerData],
  );

  return (
    <HeaderFooterContext.Provider value={contextValue}>
      {children}
    </HeaderFooterContext.Provider>
  );
};

export const useHeaderFooter = () => useContext(HeaderFooterContext);
