import React, { useEffect } from 'react';

import { useCookieModal } from 'hooks/useCookieModal';
import { FooterWrapper } from './Footer.styled';
import CookieModal from './CookieModal';
import { FooterProps } from './types';

const Footer: React.FC<FooterProps> = ({ headerContent, footerContent }) => {
  const { loadEventHandlers } = useCookieModal();
  useEffect(() => {
    if (headerContent) {
      loadEventHandlers();
    }
  }, [headerContent, loadEventHandlers]);

  if (!footerContent || !headerContent) {
    return null;
  }

  return (
    <>
      <CookieModal modalHtml={headerContent} />
      <FooterWrapper
        dangerouslySetInnerHTML={{
          __html: footerContent,
        }}
      />
    </>
  );
};

export default Footer;
