import { UnavailabilityRule } from 'components/Calendar/types';
import { ContactType } from 'components/ContactInformation/types';
import { Agent } from 'components/NegotiatorCard/types';
import { ProgressIndicatorStep } from 'components/ProgressIndicator/types';
import { LandlordStepCodes } from 'components/PropertyHeader/types';
import { Task } from 'components/Tasks/types';
import { Profile, PropertyList, SlimProperty } from 'types/app';
import { Appointment, LettingStatus, Property } from 'types/property';
import { LandlordEvents } from 'types/savills';
import { ReapitDocument } from 'views/Documents/types';
import { Offer, WithPropertyIdProps } from 'views/Vendor/types';

export enum ManagedType {
  MANAGED = 'Managed',
  NONMANAGED = 'NonManaged',
  DETERMINED = 'DeterminedByTenancies',
}

export type LandlordStatus = {
  lettingType: ManagedType;
  propertyStatus: LettingStatus;
  tenancyStatuses: {
    tenancyId: string;
    status: LettingStatus;
  }[];
};

export type LandlordContactType = {
  imageUrl: string;
  name: string;
  email: string;
  mobilePhone: string;
  workPhone: string;
  homePhone: string;
  role: string;
  internalRole: string;
};

export type LandlordTenancies = {
  tenancyId: string;
  tenancyStatus: string;
  tenancyContacts: LandlordContactType[];
  savillsTeamContacts: LandlordContactType[];
};

export type LandlordContact = {
  propertyStatus: string;
  savillsTeamContacts: LandlordContactType[];
  tenancies: LandlordTenancies[];
};

export type LandlordResponse = {
  property: Property;
  landlordStatus: {
    lettingType: ManagedType;
    propertyStatus: LettingStatus;
    tenancyStatuses: {
      tenancyId: string;
      status: LettingStatus;
      lettingType?: ManagedType;
      isCurrent?: boolean;
    }[];
  };
  landlordContacts: LandlordContact;
  appointmentsByPropertyId: Appointment[];
  unavailability: {
    propertyId: string;
    unavailability: UnavailabilityRule[];
  };
  offersByPropertyId: Offer[];
  landlordEvents?: LandlordEvents;
};

export interface StatusViewProps extends WithPropertyIdProps {
  stepPageTitle: string;
  stepPageCopy: string;
  stepPageNumber: number;
  documents?: ReapitDocument[];
  agent?: Agent;
  steps: ProgressIndicatorStep[];
  activeStep: number;
  hideBar?: boolean;
}

export type LandlordViewConfigProps = {
  [k in LandlordStepCodes]: string[];
};

export interface LandlordProps {
  [key: string]: unknown;
  profile: Profile;
  documents?: ReapitDocument[];
  documentTasks?: Task[];
  propertyList: PropertyList;
}
export interface DocumentsViewProps extends WithPropertyIdProps {
  documents?: ReapitDocument[];
}

export interface PortfolioContactProps {
  propertyId: string;
  savillsTeamContacts: LandlordContactType[];
}

export interface PortfolioResponse {
  portfolioContacts: PortfolioContactProps[];
  portfolioPropertiesDetails: Property[];
}

export interface ContactInformationProps {
  title: string;
  description?: string;
  portfolioContacts?: PortfolioContactProps[];
  contacts?: LandlordContactType[];
  propertyList?: SlimProperty[];
  contactType: ContactType;
  propertyId?: string;
}
export type LandlordSignpostTranslation = {
  Title: string;
};
export interface LandlordSignpostProps {
  backgroundImg: string;
  title: string;
  handleClick: () => void;
}

export interface LandlordStatsProps {
  key: LandlordMarketingStats;
  title: string;
  body: string;
  icon: string;
  disclaimer?: string;
}

export enum LandlordMarketingStats {
  Visits = 'visits',
  Experience = 'experience',
  Coverage = 'coverage',
}

export const LANDLORD_MARKETING_STATS: LandlordStatsProps[] = [
  {
    key: LandlordMarketingStats.Visits,
    title: 'VisitsTitle',
    body: 'VisitsBody',
    disclaimer: 'VisitsDisclaimer',
    icon: 'VisitsIcon',
  },
  {
    key: LandlordMarketingStats.Experience,
    title: 'ExperienceTitle',
    body: 'ExperienceBody',
    icon: 'ExperienceIcon',
  },
  {
    key: LandlordMarketingStats.Coverage,
    title: 'CoverageTitle',
    body: 'CoverageBody',
    icon: 'CoverageIcon',
  },
];
