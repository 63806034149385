import React, { useContext, useMemo } from 'react';
import { Property, PropertyType } from 'types/property';
import { T9nContext } from 'providers/T9nProvider/t9nContext';
import {
  PropertyTypesContainer,
  PropertyTypeContainer,
  Title,
  getPropertyTypeIcon,
} from './PropertyTypes.styled';
import { PropertyTypesProps } from './types';

const getPropertyType = (property: Property) => {
  if (property.type) return property.type;
  if (property?.propertyTypes && property.propertyTypes.length > 0)
    return property?.propertyTypes[0];
  return PropertyType.House;
};

const PropertyTypes: React.FC<PropertyTypesProps> = ({ property }) => {
  const { isFarm, isCommercial } = property;
  const { t9n } = useContext(T9nContext);
  const type = getPropertyType(property) as PropertyType;

  const propertyTypeStringMap = useMemo(
    () => ({
      [PropertyType.House]: t9n.PropertyTypes.House,
      [PropertyType.Bungalow]: t9n.PropertyTypes.Bungalow,
      [PropertyType.FlatApartment]: t9n.PropertyTypes.FlatApartment,
      [PropertyType.Maisonette]: t9n.PropertyTypes.Maisonette,
      [PropertyType.Land]: t9n.PropertyTypes.Land,
      [PropertyType.Farm]: t9n.PropertyTypes.Farm,
      [PropertyType.Cottage]: t9n.PropertyTypes.Cottage,
      [PropertyType.Mews]: t9n.PropertyTypes.Mews,
      [PropertyType.Estate]: t9n.PropertyTypes.Estate,
      [PropertyType.Castle]: t9n.PropertyTypes.Castle,
      [PropertyType.Studio]: t9n.PropertyTypes.Studio,
      [PropertyType.Townhouse]: t9n.PropertyTypes.Townhouse,
      [PropertyType.DevelopmentPlot]: t9n.PropertyTypes.DevelopmentPlot,
      [PropertyType.NewDevelopment]: t9n.PropertyTypes.NewDevelopment,
      [PropertyType.Duplex]: t9n.PropertyTypes.Duplex,
      [PropertyType.Penthouse]: t9n.PropertyTypes.Penthouse,
      [PropertyType.Vineyard]: t9n.PropertyTypes.Vineyard,
      [PropertyType.Retail]: t9n.PropertyTypes.Retail,
      [PropertyType.BuildingPlot]: t9n.PropertyTypes.BuildingPlot,
      [PropertyType.Chalet]: t9n.PropertyTypes.Chalet,
      [PropertyType.Compound]: t9n.PropertyTypes.Compound,
      [PropertyType.Condominium]: t9n.PropertyTypes.Condominium,
      [PropertyType.CountryHouse]: t9n.PropertyTypes.CountryHouse,
      [PropertyType.ExecutiveCondominium]:
        t9n.PropertyTypes.ExecutiveCondominium,
      [PropertyType.FlatOutsideCity]: t9n.PropertyTypes.FlatOutsideCity,
      [PropertyType.HDBApartment]: t9n.PropertyTypes.HDBApartment,
      [PropertyType.HighRiseBuilding]: t9n.PropertyTypes.HighRiseBuilding,
      [PropertyType.MiddleRiseBuilding]: t9n.PropertyTypes.MiddleRiseBuilding,
      [PropertyType.ServicedApartment]: t9n.PropertyTypes.ServicedApartment,
      [PropertyType.TemporaryUseApartments]:
        t9n.PropertyTypes.TemporaryUseApartments,
      [PropertyType.Triplex]: t9n.PropertyTypes.Triplex,
      [PropertyType.Villa]: t9n.PropertyTypes.Villa,
      [PropertyType.Equestrian]: t9n.PropertyTypes.Equestrian,
      [PropertyType.DevelopmentLand]: t9n.PropertyTypes.DevelopmentPlot,
      [PropertyType.Development]: t9n.PropertyTypes.DevelopmentPlot,
      [PropertyType.Industrial]: t9n.PropertyTypes.Industrial,
      [PropertyType.Leisure]: t9n.PropertyTypes.Leisure,
      [PropertyType.Office]: t9n.PropertyTypes.Office,
      [PropertyType.Hotel]: t9n.PropertyTypes.Hotel,
      [PropertyType.Heathcare]: t9n.PropertyTypes.Heathcare,
      [PropertyType.Investment]: t9n.PropertyTypes.Investment,
      [PropertyType.ServicedOffice]: t9n.PropertyTypes.ServicedOffice,
      [PropertyType.Sporting]: t9n.PropertyTypes.Sporting,
      [PropertyType.Woodland]: t9n.PropertyTypes.Woodland,
      [PropertyType.ArableFarm]: t9n.PropertyTypes.ArableFarm,
      [PropertyType.BareLand]: t9n.PropertyTypes.BareLand,
      [PropertyType.DairyFarm]: t9n.PropertyTypes.DairyFarm,
      [PropertyType.LivestockFarm]: t9n.PropertyTypes.LivestockFarm,
      [PropertyType.ResidentialFarmEstate]:
        t9n.PropertyTypes.ResidentialFarmEstate,
      [PropertyType.Laboratory]: t9n.PropertyTypes.Laboratory,
      [PropertyType.Healthcare]: t9n.PropertyTypes.Healthcare,
      [PropertyType.BusinessPark]: t9n.PropertyTypes.BusinessPark,
      [PropertyType.FlexSpace]: t9n.PropertyTypes.FlexSpace,
      [PropertyType.SeniorLiving]: t9n.PropertyTypes.SeniorLiving,
    }),
    [t9n],
  );

  if (!type) return null;

  return (
    <PropertyTypesContainer>
      <PropertyTypeContainer>
        {getPropertyTypeIcon(type, isFarm, isCommercial)}
        <Title>{propertyTypeStringMap[type]}</Title>
      </PropertyTypeContainer>
    </PropertyTypesContainer>
  );
};

export default PropertyTypes;
