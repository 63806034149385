import React, { useState } from 'react';
import Truncate from 'react-truncate';
import { ROUTES } from 'constants/routes';
import { Link, useLocation } from 'react-router-dom';
import { getSuffix } from 'helpers/helpers';
import { PortfolioTypes, SidebarPortfolioProperty } from 'types/property';
import { T9nHubResource } from 'providers/T9nProvider/types/t9n';
import {
  CollapseIcon,
  ExpandIcon,
  LettingNavItem,
  PortfolioIcon,
  PortfolioNavTitle,
  PortfolioPropertiesList,
  StyledExpandCollapse,
} from './Sidebar.styled';

import { PortfolioPropertyItem } from './PortfolioProperty';
import { getPropertiesAndTenancyCount } from './helpers';

export const PortfolioNav = ({
  title,
  properties,
  t9n,
  selectedPropertyId,
  type,
  hasMultipleProperties,
  link,
  toggleSidebar,
}: {
  title: string;
  properties: SidebarPortfolioProperty[];
  t9n: T9nHubResource;
  selectedPropertyId?: string;
  type: PortfolioTypes;
  hasMultipleProperties?: boolean;
  link?: string;
  toggleSidebar?: () => void;
}) => {
  const urlParams = useLocation();
  const pathName = urlParams.pathname;

  const [propertyListOpen, setPropertyListOpen] = useState(
    !hasMultipleProperties,
  );
  const togglePropertyList = () => {
    setPropertyListOpen(!propertyListOpen);
  };

  const propertiesAndTenancyCount = getPropertiesAndTenancyCount(properties);

  const handlePortfolioSelect = () => {
    if (!propertyListOpen) {
      setPropertyListOpen(true);
    }
    if (toggleSidebar) {
      toggleSidebar();
    }
  };

  const portfolioLink =
    type === PortfolioTypes.PERSONAL || !link
      ? `${ROUTES.LETTING.PORTFOLIO}/${type}`
      : link;
  return (
    <LettingNavItem key={title}>
      <PortfolioNavTitle isSelected={pathName === portfolioLink}>
        <PortfolioIcon />
        <Link to={portfolioLink} onClick={handlePortfolioSelect}>
          <Truncate lines={1} width={180}>
            {title}
          </Truncate>{' '}
          {getSuffix(properties)}
        </Link>
        <StyledExpandCollapse>
          {!propertyListOpen ? (
            <CollapseIcon onClick={togglePropertyList} />
          ) : (
            <ExpandIcon onClick={togglePropertyList} />
          )}
        </StyledExpandCollapse>
      </PortfolioNavTitle>
      <PortfolioPropertiesList
        items={propertiesAndTenancyCount}
        isOpen={propertyListOpen}
      >
        {properties.map((portfolioProperty) => (
          <PortfolioPropertyItem
            key={portfolioProperty?.propertyId}
            property={portfolioProperty}
            t9n={t9n}
            selectedPropertyId={selectedPropertyId}
            toggleSidebar={toggleSidebar}
          />
        ))}
      </PortfolioPropertiesList>
    </LettingNavItem>
  );
};
