/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { Keyboard } from 'swiper';

import 'swiper/css';

import { ConstrainedImageStyled } from 'components/ConstrainedImage/ConstrainedImage.styled';
import SlidesPagination from './SlidesPagination';
import {
  GalleryImageContainer,
  GalleryContainer,
} from '../PropertyCard.styled';
import { NavigationContainer } from './Gallery.styled';
import { PropertyCardGalleryProps } from './types';
import CarouselBtn from '../Carousel/CarouselBtn';
import { CarouselDirections } from '../Carousel/constants';

const carouselModules = [Keyboard];

const PropertyCardGallery: React.FC<PropertyCardGalleryProps> = ({
  images,
}) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(
    null,
  );
  const [activeSlide, setActiveSlide] = useState(0);

  const onSlideChange = (props: SwiperClass) => {
    setActiveSlide(props.realIndex);
  };

  return (
    <GalleryContainer>
      <Swiper
        slidesPerView={1}
        modules={carouselModules}
        observer
        loop
        keyboard
        onSlideChange={onSlideChange}
        onSwiper={setSwiperInstance}
      >
        {images.map((image) => (
          <SwiperSlide key={image.href}>
            <GalleryImageContainer>
              <ConstrainedImageStyled
                src={image.href}
                alt={`${image.caption}-gallery-image`}
              />
            </GalleryImageContainer>
          </SwiperSlide>
        ))}
      </Swiper>
      {swiperInstance && (
        <NavigationContainer>
          <CarouselBtn
            direction={CarouselDirections.PREVIOUS}
            isGallery
            swiper={swiperInstance}
          />
          <CarouselBtn
            direction={CarouselDirections.NEXT}
            isGallery
            swiper={swiperInstance}
          />
        </NavigationContainer>
      )}
      <SlidesPagination activeSlideIndex={activeSlide} images={images} />
    </GalleryContainer>
  );
};

export default PropertyCardGallery;
