import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const linkChevronStyles = css`
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  border-style: solid;
  border-width: 6px 0 6px 8px;
  border-color: transparent transparent transparent
    ${(props) => props.theme.colorPalette.secondary.red};
`;

export const MysLink = styled(Link)`
  position: relative;
  height: ${({ theme }) => theme.hub.fontSize.page.default};
  font-size: ${({ theme }) => theme.hub.fontSize.page.default};
  color: ${({ theme }) => theme.colorPalette.secondary.red};
  font-weight: ${({ theme }) => theme.fonts.secondary.fontWeightMed};
  font-family: ${({ theme }) => theme.fonts.secondary.font};
  text-transform: uppercase;
  line-height: 1;
  padding-right: 14px;
  text-decoration: none;

  &::after {
    ${linkChevronStyles};
  }
`;
