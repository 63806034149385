import { ValidationProps } from 'types/global';

export const DEFAULT_LOCALE_PARAMS = {
  COUNTRY: 'gb',
  LANGUAGE: 'en',
};

export const DEFAULT_CURRENCY = 'GBP';
export const DEFAULT_NUM_FORMAT = 'en-GB';

export const DEFAULT_VALIDATION: ValidationProps = {
  isInvalid: true,
  isDirty: false,
  message: '',
  rules: [
    {
      isRequired: true,
      message: 'Please enter a value',
    },
  ],
};

export const REGULAR_EXPRESSIONS = {
  EMAIL: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
  PHONE: /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/,
  UPPERCASE: /[A-Z]/,
  LOWERCASE: /[a-z]/,
  NUMERIC: /(?=.*[\d])/,
  MIN_PASSWORD_CHAR: /^.{8,}$/,
  TIME: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
};

export const DATE_FORMATS = {
  MONTH: 'LLL',
  DAY: 'd',
  LONG_DAY: 'dddd',
  STANDARD_FULL_DATE: 'DD/MM/YYYY',
  TIME_MERIDIEM_SUFFIX: 'h:mm A',
  USER_FACING_DATE: 'DD MMM YYYY',
};

export const formatter = new Intl.NumberFormat(DEFAULT_NUM_FORMAT, {
  style: 'currency',
  currency: DEFAULT_CURRENCY,
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const decimalFormatter = new Intl.NumberFormat(DEFAULT_NUM_FORMAT, {
  style: 'currency',
  currency: DEFAULT_CURRENCY,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const getCurrencyFormatter = (currency: string) =>
  new Intl.NumberFormat(DEFAULT_NUM_FORMAT, {
    style: 'currency',
    currency: currency || DEFAULT_CURRENCY,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

export const KEYBOARD_EVENT_VALUES = {
  TAB: 'Tab',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SPACE: ' ',
  LEFT: 'ArrowLeft',
  UP: 'ArrowUp',
  RIGHT: 'ArrowRight',
  DOWN: 'ArrowDown',
};

export enum PropertyVerticals {
  RESIDENTIAL = 'Residential and New Developments',
  COMMERCIAL = 'Commercial and Development Land',
  FARMS = 'Farms, Estates and Agricultural Land',
}

export enum PropertyTenures {
  TO_BUY = 'to buy',
  TO_LET = 'to let',
}
