import { PROPERTY_CARD_DIMENSIONS } from 'components/PropertyCard/constants';
import styled from 'styled-components';

export const ConstrainedImageStyled = styled.img<{
  isAgent?: boolean;
  isCollapsed?: boolean;
}>`
  width: 100%;
  height: 100%;
  max-height: ${PROPERTY_CARD_DIMENSIONS.maxHeightMob};
  object-fit: cover;
  display: block;

  @media screen and (${(props) => props.theme.media.widthLtSml}) {
    height: ${(props) =>
      props.isAgent || props.isCollapsed
        ? '100%'
        : PROPERTY_CARD_DIMENSIONS.maxHeight};
  }

  @media screen and (${(props) => props.theme.media.widthMed}) {
    max-height: ${PROPERTY_CARD_DIMENSIONS.maxHeight};
  }
`;

export const AgentImage = styled.img`
  width: 70px;
  height: 70px;
  object-fit: cover;
`;
