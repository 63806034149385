import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { ROUTES } from 'constants/routes';

import {
  NoResultsContainer,
  SharedHeader,
  SharedHeading,
} from 'components/PropertyPageShared/PropertySharedComponents.styled';

import ViewingTimePeriod from 'components/Viewings/ViewingTimePeriod';
import TabPanel from 'components/TabPanel/TabPanel';
import { T9nContext } from 'providers/T9nProvider/t9nContext';
import { T9nHubResource } from 'providers/T9nProvider/types/t9n';
import { AppointmentCodes } from 'types/property';
import { FeatureFlags } from 'utils/helpers/detection/types';
import withMaintenanceMode from 'components/Maintenance/ReapitMaintenanceMode';
import { useApplicantContext } from '../Applicant';
import { Viewing } from '../types';
import { Heading } from '../Applicant.styled';
import {
  getPastViewings,
  getTodayViewings,
  getUpcomingViewings,
} from '../helpers';

const getTabContent = (
  t9n: T9nHubResource,
  todaysViewings?: Viewing[],
  upcomingViewings?: Viewing[],
  completedViewings?: Viewing[],
  isPast = false,
) => {
  return (
    <>
      {!!todaysViewings?.length && (
        <>
          <Heading>
            {t9n.Applicant.Viewings.TodayTitle} ({todaysViewings.length})
          </Heading>
          <ViewingTimePeriod viewings={todaysViewings} t9n={t9n} />
        </>
      )}
      {!!upcomingViewings?.length && (
        <>
          <Heading>
            {t9n.Applicant.Viewings.UpcomingTitle} ({upcomingViewings.length})
          </Heading>
          <ViewingTimePeriod viewings={upcomingViewings} t9n={t9n} />
        </>
      )}
      {!!completedViewings?.length && (
        <>
          <Heading>
            {t9n.Applicant.Viewings.CompletedTitle} ({completedViewings.length})
          </Heading>
          <ViewingTimePeriod viewings={completedViewings} t9n={t9n} />
        </>
      )}
      {!todaysViewings?.length &&
        !upcomingViewings?.length &&
        !completedViewings?.length && (
          <NoResultsContainer>
            {isPast
              ? t9n.Applicant.Viewings.EmptyCompleted
              : t9n.Applicant.Viewings.EmptyScheduled}
          </NoResultsContainer>
        )}
    </>
  );
};

const ViewingsView = () => {
  const { data } = useApplicantContext();
  const { t9n } = useContext(T9nContext);
  const { viewings } = data.applicantRecord;
  const filteredViewings = viewings.filter(
    (viewing) => viewing.typeId === AppointmentCodes.VW,
  );
  const showViewings = useFeatureIsOn(FeatureFlags.VIEWINGS as string);
  const navigate = useNavigate();

  if (viewings.length === 0 || !showViewings) {
    navigate(ROUTES.APPLICANT.ROOT);
  }

  const todaysViewings = getTodayViewings(filteredViewings);
  const scheduledViewings = getUpcomingViewings(filteredViewings);
  const completedViewings = getPastViewings(filteredViewings);
  const viewingTabs = [
    {
      id: t9n.Applicant.Viewings.ScheduledTitle,
      title: `${t9n.Applicant.Viewings.ScheduledTitle} (${
        [...scheduledViewings, ...todaysViewings].length
      })`,
      body: getTabContent(t9n, todaysViewings, scheduledViewings),
    },
    {
      id: t9n.Applicant.Viewings.CompletedTitle,
      title: `${t9n.Applicant.Viewings.CompletedTitle} (${completedViewings.length})`,
      body: getTabContent(t9n, undefined, undefined, completedViewings, true),
    },
  ];

  return (
    <div>
      <SharedHeader>
        <SharedHeading>{t9n.Applicant.Viewings.Title} </SharedHeading>
      </SharedHeader>

      <TabPanel tabs={viewingTabs} />
    </div>
  );
};

export default withMaintenanceMode(ViewingsView);
