import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { setContext } from '@apollo/client/link/context';
import browserCookies from 'browser-cookies';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
  from,
  ApolloLink,
} from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { ASTNode, stripIgnoredCharacters } from 'graphql';
import { sha256 } from 'crypto-hash';
import { DEFAULT_LOCALE_PARAMS } from 'constants/global';
import { AuthContextProps } from 'react-oidc-context';
import { GPS_BEARER } from 'constants/cookies';
import { PREVIEW_MODE_ERROR } from 'constants/auth';
import { toast } from 'react-toastify';
import { ROUTES } from 'constants/routes';
import { getBaseUrl } from 'utils/helpers/misc/misc';

const isDev = process.env.NODE_ENV === 'development';

export interface LocalizationProps {
  countryCode: string;
  languageCode: string;
  isPreview?: boolean;
}

export const T9N_CLIENT_NAME = 't9n';

export type FatalErrorCallback = (
  error: NetworkError,
  correlationId?: string,
) => void;

let unAuthenticatedClient: ApolloClient<NormalizedCacheObject> | void;

const isTest = process.env.REACT_APP_NODE_ENV === 'test';

function removeLeadingSlash(route: string) {
  return route.replace(/^\//, '');
}

function getT9Link({ languageCode, countryCode }: LocalizationProps) {
  const options = {
    uri: window.appConfig.t9nServerUri,
    headers: {
      gpslanguagecode: languageCode || DEFAULT_LOCALE_PARAMS.LANGUAGE,
      gpscountrycode: countryCode || DEFAULT_LOCALE_PARAMS.COUNTRY,
    },
  };
  // cypress docker runner appears to not like apollo get requests, so we toggele them off for that environment
  return isTest
    ? new HttpLink(options)
    : createPersistedQueryLink({
        sha256,
        useGETForHashedQueries: true,
      }).concat(new HttpLink(options));
}

function getErrorLink(
  fatalErrorCallback?: FatalErrorCallback,
  isPreview?: boolean,
) {
  return onError(({ networkError, operation }) => {
    if (networkError?.message === PREVIEW_MODE_ERROR) {
      toast.error(PREVIEW_MODE_ERROR);
      return;
    }
    if (networkError) {
      const error = networkError as ServerError;
      const context = operation.getContext();
      const { response } = context;
      const correlationId = response?.headers?.get('x-Correlation-ID');

      const forbiddenError =
        error.statusCode === 403 || error.statusCode === 401;

      if (forbiddenError && isPreview) {
        window.parent.postMessage('REFRESH_TOKEN', '*');
      }

      if (forbiddenError) {
        sessionStorage.clear();
        localStorage.clear();
        browserCookies.erase(GPS_BEARER);
        window.location.href = `${getBaseUrl()}${removeLeadingSlash(
          ROUTES.FORBIDDEN,
        )}`;
        return;
      }

      // eslint-disable-next-line no-console
      console.error(operation.operationName, error);

      if (fatalErrorCallback) fatalErrorCallback(networkError, correlationId);
    }
  });
}

function getTokenFromCookie() {
  const cookies = document.cookie.split(';');
  const tokenCookie = cookies.find((cookie) => cookie.includes(GPS_BEARER));
  return tokenCookie?.split('=')[1];
}

const blockedQueriesForPreview = ['getSignedUrlForUpload'];
function getAuthLink(isPreview: boolean) {
  const impersonationToken = sessionStorage.getItem(
    `oidc.user:${window.appConfig.authServerUri}:savills-web`,
  );
  return setContext((request, { headers }) => {
    if (
      isPreview &&
      (blockedQueriesForPreview.includes(request.operationName as string) ||
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        request.query.definitions.some((def: any) => {
          return def.operation === 'mutation';
        }))
    ) {
      throw new Error(PREVIEW_MODE_ERROR);
    }
    const parsedToken = JSON.parse(impersonationToken ?? '{}');
    const Authorization =
      isPreview && impersonationToken
        ? `Bearer ${parsedToken.access_token}`
        : getTokenFromCookie();

    return {
      headers: {
        ...headers,
        Authorization,
      },
    };
  });
}

export function getApolloClient(
  { countryCode, languageCode }: LocalizationProps,
  fatalErrorCallback?: FatalErrorCallback,
): ApolloClient<NormalizedCacheObject> | null {
  if (unAuthenticatedClient) {
    return unAuthenticatedClient;
  }

  try {
    const t9nLink = getT9Link({ languageCode, countryCode });
    const errorLink = getErrorLink(fatalErrorCallback);

    unAuthenticatedClient = new ApolloClient<NormalizedCacheObject>({
      link: from([errorLink, t9nLink]),
      cache: new InMemoryCache(),
    });

    return unAuthenticatedClient;
  } catch (e: unknown) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
}

let client: ApolloClient<NormalizedCacheObject> | void;

export function getSecureApolloClient(
  { user }: AuthContextProps,
  { countryCode, languageCode }: LocalizationProps,
  fatalErrorCallback?: FatalErrorCallback,
  isPreview = false,
): ApolloClient<NormalizedCacheObject> | null {
  if (client) {
    return client;
  }

  const profile = user?.profile;
  const accessToken = user?.access_token;
  try {
    if (!accessToken) {
      return null;
    }

    const headers: {
      [key: string]: string;
    } =
      isDev && window.appConfig.apolloServerUri.includes('localhost')
        ? {
            Authorization: `Bearer ${accessToken}`,
            isauthenticatednegotiator: String(
              !!(profile?.userName && profile?.clientId),
            ),
            reapitcontactcode: (profile?.reapit_contact_code as string) || '',
            email: profile?.email || profile?.name || '',
            gpslanguagecode: languageCode || DEFAULT_LOCALE_PARAMS.LANGUAGE,
            gpscountrycode: countryCode || DEFAULT_LOCALE_PARAMS.COUNTRY,
          }
        : {
            Authorization: `Bearer ${accessToken}`,
            gpslanguagecode: languageCode || DEFAULT_LOCALE_PARAMS.LANGUAGE,
            gpscountrycode: countryCode || DEFAULT_LOCALE_PARAMS.COUNTRY,
          };

    const hubLink = new HttpLink({
      uri: window.appConfig.apolloServerUri,
      headers,
      print(ast: ASTNode, originalPrint: (ast: ASTNode) => string) {
        return stripIgnoredCharacters(originalPrint(ast));
      },
    });

    const authLink = getAuthLink(isPreview);
    const t9nLink = getT9Link({ languageCode, countryCode });
    const errorLink = getErrorLink(fatalErrorCallback, isPreview);

    client = new ApolloClient<NormalizedCacheObject>({
      link: from([
        errorLink,
        ApolloLink.split(
          (operation) => operation.getContext().clientName === T9N_CLIENT_NAME,
          t9nLink,
          from([authLink, hubLink]),
        ),
      ]),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              hubResource: {
                merge(existing, incoming) {
                  // Define your custom merge logic here
                  return { ...existing, ...incoming };
                },
              },
            },
          },
          Appointment: {
            keyFields: ['reapitId'],
          },
          Unavailability: {
            keyFields: ['propertyId'],
          },
          User: {
            keyFields: ['email'],
          },
          PropertyList: {
            keyFields: ['selling', [['id']], 'letting', [['id']]],
          },
          DocumentList: {
            keyFields: ['id'],
          },
          Tasks: {
            keyFields: ['taskId'],
          },
          Profile: {
            keyFields: ['userId'],
          },
        },
      }),
    });

    return client;
  } catch (e: unknown) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
}
