import { downloadZip } from 'client-zip';
import { PropertyList } from 'types/app';
import { DocumentStatus } from 'components/DocumentListItem/types';
import { ReapitDocument } from '../types';
import { ZIP_NAME } from './constants';

export const getMergedProperties = (propertyList: PropertyList) => {
  if (propertyList?.letting && propertyList?.selling) {
    return [...propertyList.selling, ...propertyList.letting];
  }

  if (propertyList?.selling) {
    return [...propertyList.selling];
  }

  return [];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const shouldAllowDownload = (url?: string, signatureSense?: any) => {
  const fullySigned =
    signatureSense?.documentStatus === DocumentStatus.SIGNED_EXECUTED;

  const hasSignatureSense = !!signatureSense;
  const hasDownloadLink = !!url;

  return hasDownloadLink && (!hasSignatureSense || fullySigned);
};

export const downloadHandler = async (
  selectedDocuments: string[],
  documents: ReapitDocument[],
) => {
  const documentsToFilter =
    selectedDocuments && selectedDocuments.length > 0
      ? documents.filter((doc) => selectedDocuments.includes(doc.id))
      : documents;

  const downloads = documentsToFilter
    .filter((doc) => !!doc.url)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((doc) => fetch(doc.url!));

  const responses = await Promise.all(downloads);
  const files = responses.map((response, index) => ({
    name:
      documentsToFilter[index].name ||
      documentsToFilter[index].title ||
      'document',
    lastModified: new Date(documentsToFilter[index].modifiedOn).getTime(),
    input: response,
  }));
  const blob = await downloadZip(files).blob();
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = ZIP_NAME;
  link.click();
  link.remove();
};
