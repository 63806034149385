import React from 'react';
import moment from 'moment';

import CardTag from 'components/Card/CardTag';
import { ViewingDateType } from './types';
import {
  ViewingDateContainer,
  ViewingDateDay,
  ViewingDateMonth,
  ViewingDateSidebar,
  ViewingFullDate,
  ViewingTag,
} from './Viewings.styled';

const ViewingDate: React.FC<ViewingDateType> = ({
  start,
  end,
  viewingFlags,
  includeTime = true,
}) => {
  const day = moment(start).format('D');
  const month = moment(start).format('MMM');
  const endDate = end ? ` - ${moment(end).format('HH:mm')}` : '';
  const fullDate = includeTime
    ? `${moment(start).format('dddd, Do MMMM YYYY, HH:mm')}${endDate}`
    : `${moment(start).format('dddd, Do MMMM YYYY')}${endDate}`;
  return (
    <ViewingDateContainer>
      <ViewingDateSidebar>
        <ViewingDateMonth>{month}</ViewingDateMonth>
        <ViewingDateDay>{day}</ViewingDateDay>
      </ViewingDateSidebar>
      <ViewingFullDate>{fullDate}</ViewingFullDate>
      <ViewingTag>
        <CardTag tags={viewingFlags} />
      </ViewingTag>
    </ViewingDateContainer>
  );
};

export default ViewingDate;
