import moment from 'moment';
import { SelectOption } from 'savills-ui-kit/lib/components/Select/types';

import { T9nHubResource } from 'providers/T9nProvider/types/t9n';

import { ReapitDocument, ReapitTenancyDetails } from './types';

export const getTenancyLabel = (
  t9n: T9nHubResource,
  tenancy?: ReapitTenancyDetails,
) => {
  if (!tenancy) return '';
  const { name, status, startDate, endDate } = tenancy;

  if (!tenancy.startDate && !tenancy.endDate) {
    return name;
  }

  const startYear = !startDate
    ? moment().format('YYYY')
    : moment(startDate).format('YYYY');
  const endYear =
    status === 'current' || !endDate
      ? t9n.Documents.TenancyCurrent
      : moment(endDate).format('YYYY');
  return `${name} (${startYear} - ${endYear})`;
};

export const getUniqueTenancies = (tenancyOptions: SelectOption[]) => {
  return tenancyOptions.filter(
    (option, index, self) =>
      index ===
      self.findIndex(
        (t) => t.value === option.value && t.label === option.label,
      ),
  );
};

export const sortTenancies = (filteredDocuments: ReapitDocument[]) => {
  return [...filteredDocuments].sort((a, b) => {
    const aStartDate = a.tenancyDetails?.startDate || '';
    const bStartDate = b.tenancyDetails?.startDate || '';
    const aEndDate = a.tenancyDetails?.endDate || '';
    const bEndDate = b.tenancyDetails?.endDate || '';

    if (bStartDate === aStartDate) {
      if (bEndDate === aEndDate) {
        return (
          a.tenancyDetails?.name.localeCompare(
            b.tenancyDetails?.name as string,
          ) || 0
        );
      }
      return bEndDate.localeCompare(aEndDate) || 0;
    }
    return bStartDate.localeCompare(aStartDate) || 0;
  });
};

export const mapTenancyOptions = (
  documents: ReapitDocument[],
  t9n: T9nHubResource,
) => {
  const filteredDocuments = documents.filter(
    (document) => document.tenancyDetails,
  );

  const sortedDocuments = sortTenancies(filteredDocuments);

  return sortedDocuments.map((document) => ({
    label: getTenancyLabel(t9n, document.tenancyDetails),
    value: document.tenancyDetails?.id || '',
  }));
};
