import React from 'react';

import NavItem from './NavItem';
import { StyledPageNav, StyledNav } from './StyledPageNav';
import { PageNavProps } from './types';

function PageNav({ navItems }: PageNavProps) {
  return (
    <StyledPageNav data-test-id="pageNav">
      <StyledNav>
        {navItems.map((navItem) => (
          <NavItem
            isactive={navItem.isactive}
            item={navItem}
            key={navItem.key}
          />
        ))}
      </StyledNav>
    </StyledPageNav>
  );
}

export default PageNav;
