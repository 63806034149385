import styled from 'styled-components';

export const SharedHeading = styled.h3`
  font-family: ${(props) => props.theme.fonts.primary.font};
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0;

  span {
    margin: 16px 0;
    vertical-align: middle;
  }

  @media screen and (${({ theme }) => theme.media.widthSml}) {
    flex-direction: row;
  }

  @media screen and (${(props) => props.theme.media.widthLtSml}) {
    font-size: ${(props) => props.theme.typography.large};
  }
`;

export const SubHeading = styled(SharedHeading)`
  font-size: 18px;
`;

export const SharedSection = styled.section`
  margin-bottom: 48px;

  p {
    font-family: ${(props) => props.theme.fonts.secondary.font};
    font-weight: 300;
  }
`;

export const SharedHeader = styled.div<{ isRow?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.isRow ? 'row' : 'column')};
  margin-bottom: 10px;

  @media screen and (${({ theme }) => theme.media.widthSml}) {
    flex-direction: row;
  }

  @media screen and (${({ theme }) => theme.media.widthLtSml}) {
    ${(props) =>
      props.isRow &&
      `

      align-items: center;
    grid-gap: 10px;

    h3 {
      margin: 0;
    }
      `}
  }
`;

export const SharedListItem = styled.li`
  margin-bottom: 40px;
`;

export const NoResultsContainer = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) => props.theme.typography.xsmall};
  padding: 0;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 10px;

  p {
    margin: 0;
  }
`;

export const SharedCopy = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: 300;
  font-size: ${(props) => props.theme.typography.small};
  margin: 24px 0 36px;
`;

export const PortfolioPropertiesWrapper = styled.div`
  display: flex;
  grid-gap: 16px;
  width: 'fit-content';
  overflow-x: auto;
  grid-template-columns: repeat(300px);
`;

export const PortfolioPropertyCardContainer = styled.div`
  width: 100%;
`;
