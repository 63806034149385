import React, { useContext, useEffect } from 'react';
import { Button, TertiaryButton } from 'savills-ui-kit';
import {
  Logo,
  Header,
  LoggedOutContent,
  ImgContainer,
  LoggedOutHeader,
  LoggedOutSubHeader,
  LoggedOutCTAs,
  LoggedOutWrapper,
} from 'components/Notice/Notice.styled';
import { T9nContext, withT9nHook } from 'providers/T9nProvider/t9nContext';
import { broadcastRemoveSessionMessage } from 'services/logout';
import loggedOutT9n from 'queries/t9n/loggedOutT9n.gql';
import Footer from 'components/Footer/Footer';
import { HeaderFooterContext } from 'providers/HeaderFooter/HeaderFooterContext';
import imgSrc from 'assets/loggedOut.png';
import mobileImgSrc from 'assets/mobileLoggedOut.png';
import { ROUTES } from 'constants/routes';

const LoggedOutComponent = () => {
  const { t9n } = useContext(T9nContext);

  const {
    footerData,
    headerData,
    logoUrl: homeUrl,
  } = useContext(HeaderFooterContext);

  useEffect(() => {
    const logout = async () => {
      await broadcastRemoveSessionMessage();
    };
    logout();

    document.body.classList.add('hide-hj');
    return () => {
      document.body.classList.remove('hide-hj');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = () => {
    window.location.href = ROUTES.HOME;
  };

  const handleSearch = () => {
    window.location.href = homeUrl;
  };

  return (
    <>
      <Header>
        <a href={homeUrl} aria-label="Savills UK Home">
          <Logo title="Savills Logo" />
        </a>
      </Header>
      <LoggedOutWrapper>
        <LoggedOutContent>
          <div>
            <LoggedOutHeader>{t9n.LoggedOut.Title}</LoggedOutHeader>
            <LoggedOutSubHeader>{t9n.LoggedOut.Message}</LoggedOutSubHeader>
            <LoggedOutCTAs>
              <Button onClick={handleLogin}>
                {t9n.Account.Buttons.LogBackIn}
              </Button>
              <TertiaryButton onClick={handleSearch}>
                {t9n.Account.Buttons.StartNewSearch}
              </TertiaryButton>
            </LoggedOutCTAs>
          </div>
          <ImgContainer>
            <picture>
              <source media="(max-width: 799px)" srcSet={mobileImgSrc} />
              <source media="(min-width: 800px)" srcSet={imgSrc} />
              <img src={imgSrc} alt="placeholder " />
            </picture>
          </ImgContainer>
        </LoggedOutContent>
      </LoggedOutWrapper>

      <Footer
        headerContent={headerData || undefined}
        footerContent={footerData || undefined}
      />
    </>
  );
};

export const LoggedOut = withT9nHook(LoggedOutComponent, loggedOutT9n, [
  'LoggedOut',
]);
