import { decimalFormatter } from 'constants/global';
import React from 'react';

import { SellingStatus } from 'types/property';
import {
  PriceContainer,
  PriceText,
  Prefix,
  AcceptedPriceText,
  RentalPrice,
  RentalPriceFrequency,
} from './Price.styled';
import { HIDE_STATUSES } from '../constants';
import { PriceProps } from './types';

const Price: React.FC<PriceProps> = ({
  propertyStatus,
  mainPrice,
  acceptedPrice,
  prefix,
  suffix,
  darkMode = false,
  rentalPrice,
}) => {
  const hidePrice = propertyStatus
    ? HIDE_STATUSES.includes(propertyStatus)
    : false;
  if (hidePrice) return null;

  const hasQualifyingStatusAcceptedPrice = [
    SellingStatus.Exchanged,
    SellingStatus.Completed,
  ].includes(propertyStatus as SellingStatus);

  const formattedRentalPrice =
    rentalPrice?.price && rentalPrice.price > 0
      ? decimalFormatter.format(rentalPrice?.price)
      : null;

  return (
    <PriceContainer>
      {prefix && (!acceptedPrice || !hasQualifyingStatusAcceptedPrice) && (
        <Prefix darkMode={darkMode}>{prefix}</Prefix>
      )}
      {hasQualifyingStatusAcceptedPrice && acceptedPrice && (
        <AcceptedPriceText darkMode={darkMode}>
          {acceptedPrice}
        </AcceptedPriceText>
      )}
      {(!acceptedPrice || !hasQualifyingStatusAcceptedPrice) && (
        <PriceText darkMode={darkMode}>{mainPrice}</PriceText>
      )}
      {suffix && <Prefix darkMode={darkMode}>{suffix}</Prefix>}
      {formattedRentalPrice && (
        <RentalPrice darkMode={darkMode}>
          {formattedRentalPrice}
          <RentalPriceFrequency darkMode={darkMode}>
            {rentalPrice?.frequency}
          </RentalPriceFrequency>
        </RentalPrice>
      )}
    </PriceContainer>
  );
};

export default Price;
