/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import {
  AWAITING_IMAGES,
  AWAITING_IMAGES_AGENT,
} from 'components/PropertyCard/constants';
import React, { ImgHTMLAttributes } from 'react';
import { AgentImage, ConstrainedImageStyled } from './ConstrainedImage.styled';

export const WithFallbackImage = (
  props: ImgHTMLAttributes<HTMLImageElement>,
) => {
  const onImageLoadError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    event.currentTarget.src = AWAITING_IMAGES[0].href;
  };

  return <ConstrainedImageStyled {...props} onError={onImageLoadError} />;
};

export const WithFallbackImageAgent = (
  props: ImgHTMLAttributes<HTMLImageElement>,
) => {
  const onImageLoadError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    event.currentTarget.src = AWAITING_IMAGES_AGENT.href;
  };

  return <AgentImage {...props} onError={onImageLoadError} />;
};
