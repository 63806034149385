import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

interface AddressLineProps {
  darkMode?: boolean;
  isMiniCard?: boolean;
}

export const Line1 = styled.h3<AddressLineProps>`
  font-family: ${(props) => props.theme.fonts.primary.font};
  font-weight: ${(props) => props.theme.fonts.primary.fontWeight};
  font-size: ${(props) =>
    props.isMiniCard
      ? props.theme.hub.fontSize.propertyCards.default
      : props.theme.hub.fontSize.propertyCards.large};
  margin: 0 0 4px;
  color: ${(props) =>
    props.darkMode
      ? props.theme.colorPalette.branding.white
      : props.theme.colorPalette.branding.ink};

  @media screen and (${(props) => props.theme.media.widthMed}) {
    color: ${(props) => props.theme.colorPalette.branding.ink};
  }
`;

const truncationFont = css`
  font-family: ${(props) =>
    props.theme.fonts.secondary.font.split(', ').join(', "Courier New", ')};
`;

export const Line2 = styled.p<AddressLineProps>`
  ${truncationFont}
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) =>
    props.isMiniCard
      ? props.theme.typography.xxsmall
      : props.theme.typography.xsmall};
  margin: 0;
  color: ${(props) =>
    props.darkMode
      ? transparentize(0.2, props.theme.colorPalette.branding.white)
      : props.theme.colorPalette.branding.ink};

  @media screen and (${(props) => props.theme.media.widthMed}) {
    color: ${(props) => props.theme.colorPalette.branding.ink};
    font-size: ${(props) =>
      props.isMiniCard
        ? props.theme.typography.xsmall
        : props.theme.typography.small};
  }

  @media screen and (${(props) => props.theme.media.widthLtMed}) {
    margin: 5px 0;
  }
`;
