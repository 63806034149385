import styled, { DefaultTheme } from 'styled-components';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as BellSvg } from 'assets/icons/bell.svg';
import { NotificationStyleEnum } from './types';

interface NotificationBannerProps {
  notificationType: NotificationStyleEnum;
  hidden: boolean;
}

interface DismissNotificationProps {
  theme: DefaultTheme;
  notificationType: NotificationStyleEnum;
}

export const BellIcon = styled(BellSvg)`
  height: 20px;
  width: 20px;
  fill: ${(props) => props.theme.colorPalette.branding.ink};
`;

export const CloseIcon = styled(Close)`
  height: 16px;
  width: 16px;
`;

export const NotificationBanner = styled.div<NotificationBannerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 10px;
  z-index: 1000;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-size: ${(props) => props.theme.typography.small};
  line-height: 18px;
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  transition: all 0.25s;
  background: ${(props) => {
    switch (props.notificationType) {
      case NotificationStyleEnum.Alert:
        return props.theme.colorPalette.secondary.red;
      case NotificationStyleEnum.Info:
        return props.theme.colorPalette.branding.greyBlue;
      case NotificationStyleEnum.Caution:
      default:
        return props.theme.colorPalette.branding.yellow;
    }
  }};
  color: ${(props) => {
    switch (props.notificationType) {
      case NotificationStyleEnum.Alert:
        return props.theme.colorPalette.branding.white;
      case NotificationStyleEnum.Info:
        return props.theme.colorPalette.branding.white;
      case NotificationStyleEnum.Caution:
      default:
        return props.theme.colorPalette.branding.ink;
    }
  }};
  ${(props) =>
    props.hidden
      ? `
    padding: 0;
    height: 0;
    opacity: 0;
  `
      : ''};
  @media (${(props) => props.theme.media.widthMed}) {
    ${(props) => (props.hidden ? 'padding: 0;' : 'padding: 20px;')}
  }
`;

export const NotificationContent = styled.div`
  flex: 1;
  margin: 0 10px;
  @media (${(props) => props.theme.media.widthMed}) {
    margin: 0 20px;
  }
`;

export const DismissButton = styled.button`
  align-self: flex-start;
  border: 0;
  color: ${(props) => props.theme.colorPalette.branding.ink};
  font-size: ${(props) => props.theme.typography.default};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  cursor: pointer;
  background: none;
`;

export const DismissIcon = styled(CloseIcon)<DismissNotificationProps>`
  border: 0;
  color: ${(props) => props.theme.colorPalette.branding.ink};
  font-size: ${(props) => props.theme.typography.default};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  cursor: pointer;
  background: none;
  transition: color 0.25s;
  path {
    fill: ${(props) => {
      switch (props.notificationType) {
        case NotificationStyleEnum.Alert:
          return props.theme.colorPalette.branding.white;
        case NotificationStyleEnum.Info:
          return props.theme.colorPalette.branding.white;
        case NotificationStyleEnum.Caution:
        default:
          return props.theme.colorPalette.branding.ink;
      }
    }};
  }
`;
