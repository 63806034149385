import React from 'react';
import { Button } from 'savills-ui-kit';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import PropertyCard from 'components/PropertyCard/PropertyCard';
import { PropertyCardType } from 'components/PropertyCard/constants';
import { getCurrencyFormatter } from 'constants/global';
import { FeatureFlags } from 'utils/helpers/detection/types';
import { OfferStatus } from 'views/Vendor/types';

import {
  ApplicantOffer,
  OfferDate,
  OfferList,
  OfferPrice,
  OfferStatusTag,
  OfferListHeader,
  AcceptedOfferCard,
  AcceptedTitle,
  AcceptedParagraph,
  HouseIcon,
} from './Offers.styled';

import { ApplicantOfferCardProps, OfferLineProps } from './types';
import { OFFER_DATE_FORMAT } from './constants';

const OfferLineItem: React.FC<OfferLineProps> = ({ offer }) => {
  const isCurrentOffer =
    offer.status === OfferStatus.Pending ||
    offer.status === OfferStatus.Accepted;

  return (
    <>
      <p>
        <OfferPrice status={offer.status} isActive={isCurrentOffer}>
          {getCurrencyFormatter(offer.currency).format(offer.price)}
        </OfferPrice>
      </p>
      <p>
        <OfferDate>
          {moment(offer.date.dueDate).format(OFFER_DATE_FORMAT)}
        </OfferDate>
      </p>
      <p>
        <OfferStatusTag status={offer.status}>
          {offer.tag?.title}
        </OfferStatusTag>
      </p>
    </>
  );
};

const ApplicantOfferCard: React.FC<ApplicantOfferCardProps> = ({
  offer,
  t9n,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    return navigate(ROUTES.DOCUMENTS.UPLOAD);
  };

  const showUploadCTA = useFeatureIsOn(FeatureFlags.UPLOAD as string);

  return (
    <>
      {offer.isAccepted && (
        <AcceptedOfferCard>
          <HouseIcon />
          <AcceptedTitle>{t9n.Applicant.Offers.AcceptedTitle}</AcceptedTitle>
          <AcceptedParagraph
            showLink={showUploadCTA}
            dangerouslySetInnerHTML={{
              __html: t9n.Applicant.Offers.AcceptedParagraph,
            }}
          />
          {showUploadCTA && (
            <Button onClick={handleClick}>
              {t9n.Applicant.Offers.AcceptedCTA}
            </Button>
          )}
        </AcceptedOfferCard>
      )}
      <ApplicantOffer>
        <PropertyCard
          property={offer.property}
          t9n={t9n}
          agent={offer.offers[0].negotiator}
          type={PropertyCardType.VIEWING}
        />
        <OfferListHeader>{t9n.Applicant.Offers.ListTitle}</OfferListHeader>
        <OfferList>
          {offer.offers.map((item) => (
            <OfferLineItem key={item.id} offer={item} />
          ))}
        </OfferList>
      </ApplicantOffer>
    </>
  );
};

export default ApplicantOfferCard;
