import { Image } from 'types/app';
import { LettingStatus, SellingStatus } from 'types/property';

export const priceQualifierMap = {
  askingPrice: 'AskingPrice',
  guidePrice: 'GuidePrice',
  priceOnApplication: 'PriceOnApplication',
  offersInRegion: 'RegionOf',
  offersOver: 'From',
  offersInExcess: 'OffersInvited',
  fixedPrice: 'FixedPrice',
  priceReducedTo: 'ReducedTo',
  toLetByTender: 'ToLetByTender',
  perDesk: 'PerDesk',
  rentOnApplication: 'RentOnApplication',
};

export const PROPERTY_CARD_IMAGE_HEIGHT = {
  smallViewport: '200px',
  medViewport: '390px',
  largeViewport: '400px',
  medAppViewport: '380px',
  largeAppViewport: '385px',
  xLargeViewport: '400px',
  miniCardHeight: '185px',
};

export const PROPERTY_CARD_DIMENSIONS = {
  maxHeight: '401px',
  maxHeightMob: '300px',
  miniCardMaxWidth: '320px',
  miniCardHeight: '425px',
};

export const EXPANDABLE_CARD_HEIGHT = {
  expanded: {
    smallViewport: 'auto',
    medViewport: '390px',
    largeViewport: '400px',
    xLargeViewport: '400px',
  },
  collapsed: {
    smallViewport: 'auto',
    medViewport: '72px',
    largeViewport: '72px',
    xLargeViewport: '72px',
  },
};

export const GALLERY_PROPERTY_IMAGE_HEIGHT = {
  smallViewport: '320px',
  medViewport: '420px',
  largeViewport: '690px',
};

export const AWAITING_IMAGES: Image[] = [
  {
    href: 'https://assets.savills.com/awaiting/ENAwaitingImage_Gallery_L.jpg',
    caption: 'Awaiting Property Images',
  },
];

export const AWAITING_IMAGES_AGENT: Image = {
  href: 'https://assets.savills.com/awaiting/AwaitingImage_Agent_S.jpg',
  caption: 'Awaiting Agent Images',
};

export const CAROUSEL_BREAKPOINTS = {
  320: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  580: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  960: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  1136: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  1660: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
};

export type PROPERTY_STATUSES = LettingStatus | SellingStatus;

export const HIDE_STATUSES: PROPERTY_STATUSES[] = [
  SellingStatus.Valuation,
  SellingStatus.PaidValuation,
  SellingStatus.PreAppraisal,
  SellingStatus.Withdrawn,
  SellingStatus.SoldExternally,
  LettingStatus.Removed,
  LettingStatus.Empty,
  LettingStatus.Withdrawn,
];

export const MARKETING_OFFLINE_STATUSES: SellingStatus[] = [
  SellingStatus.ForSale,
  SellingStatus.UnderOffer,
];

export enum PropertyCardType {
  SAVED_PROPERTY = 'SAVED_PROPERTY',
  VIEWING = 'VIEWING',
  DEFAULT = 'DEFAULT',
}
