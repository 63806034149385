/* eslint-disable no-console */
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { decodeJwt } from 'utils/helpers/jwt/jwt';
import { getLocalizationParametersFromCookieOrUrl } from 'utils/helpers/localization/localization';

export enum ActionTypes {
  START_SESSION = 'START_SESSION',
  APPLICANT_DATA_LOADED = 'APPLICANT_DATA_LOADED',
  VIEW_SAVED_ALERT = 'PROFILE_VIEW_SAVED_SEARCH',
  REMOVE_SAVED_ALERT = 'PROFILE_REMOVE_SEARCH_API_SUCCESS',
  TOGGLE_SAVED_ALERT = 'PROFILE_TOGGLE_ALERT_API_SUCCESS',
  VIEW_SAVED_PROPERTY = 'PROFILE_VIEW_SAVED_PROPERTY',
  REMOVE_SAVED_PROPERTY = 'PROFILE_REMOVE_PROPERTY_API_SUCCESS',
}

export type TrackingAction = {
  type: ActionTypes | string;
  payload: unknown;
};

export const TRACKING_WAIT_TIME = 250;

type ProfileData = {
  MemberId: string;
  GpsProfileId: string;
  reapit_contact_code: string;
};

export function createAction(
  type: ActionTypes | string,
  payload: unknown = {},
) {
  return {
    type,
    payload,
  };
}

export function useTracking() {
  const [action, setTrackingAction] = useState<TrackingAction | null>(null);
  const auth = useAuth();
  const profileData = useMemo(
    () => decodeJwt<ProfileData>(auth.user?.access_token),
    [auth.user?.access_token],
  );
  const { countryCode, languageCode } =
    getLocalizationParametersFromCookieOrUrl();
  useEffect(() => {
    if (window.msTrackingApi && action && profileData) {
      const trackingData = {
        action,
        headers: {
          origin: window.location.origin,
          MemberId: profileData.MemberId,
          GpsProfileId: profileData.GpsProfileId,
          cntCode: profileData.reapit_contact_code,
          GpsLanguageCode: languageCode,
          GpsCountryCode: countryCode,
          'Content-Type': 'application/json',
        },
        state: {},
      };
      try {
        window.msTrackingApi.send(trackingData);
      } catch (e) {
        console.error(e);
      }
    }
  }, [action, auth?.user?.profile, countryCode, languageCode, profileData]);
  return {
    setTrackingAction,
  };
}
