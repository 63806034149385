import React, { useMemo, useState } from 'react';
import { AuthContextProps } from 'react-oidc-context';
import { T9nHubResource } from 'providers/T9nProvider/types/t9n';
// TODO move to shared folder somewhere
import { InfoIcon } from 'components/Card/Card.styled';
import {
  BellIcon,
  DismissButton,
  DismissIcon,
  NotificationBanner,
  NotificationContent,
} from './NotificationBanner.styled';
import { replaceValues } from './helpers';
import {
  MaintenanceBannerEnum,
  NotificationFeature,
  NotificationStyleEnum,
  UserTypeEnum,
} from './types';

function Icon({
  notificationType,
}: {
  readonly notificationType: NotificationStyleEnum;
}) {
  if (notificationType === NotificationStyleEnum.Alert) {
    return <BellIcon />;
  }
  return <InfoIcon />;
}

export default function NotificationBannerComponent({
  t9n,
  auth,
  notification,
}: {
  readonly t9n: T9nHubResource;
  readonly auth: AuthContextProps | null;
  readonly notification: NotificationFeature | null;
}) {
  const [isHidden, setIsHidden] = useState(false);
  const notificationToDisplay = useMemo(() => {
    const profile = auth?.user?.profile;
    const isUserConnected =
      profile?.my_savills_landlord_enabled === 'true' ||
      profile?.my_savills_vendor_enabled === 'true';
    const hasEnabledUserNotification = !!notification?.enabledUsers?.value;
    return isUserConnected && hasEnabledUserNotification
      ? UserTypeEnum.EnabledUser
      : UserTypeEnum.AllUsers;
  }, [auth?.user?.profile, notification]);

  if (!notification) {
    return null;
  }
  if (
    notificationToDisplay === UserTypeEnum.EnabledUser &&
    notification.enabledUsers
  ) {
    return (
      <NotificationBanner
        notificationType={notification.enabledUsers.type}
        hidden={isHidden}
      >
        <Icon notificationType={notification.enabledUsers.type} />
        <NotificationContent>
          {notification.enabledUsers?.value}
        </NotificationContent>
        {notification.dismissible && (
          <DismissButton
            type="button"
            onClick={() => setIsHidden(true)}
            aria-label={t9n.SiteNotifications.Dismiss}
          >
            <DismissIcon notificationType={notification.enabledUsers.type} />
          </DismissButton>
        )}
      </NotificationBanner>
    );
  }

  if (!notification.allUsers?.replacements.length) {
    return null;
  }

  const notificationType =
    notification.allUsers?.type === MaintenanceBannerEnum.ScheduledMaintenance
      ? NotificationStyleEnum.Caution
      : NotificationStyleEnum.Alert;

  const notificationMessage =
    notification.allUsers?.type === MaintenanceBannerEnum.ScheduledMaintenance
      ? t9n.SiteNotifications.ScheduledMaintenance
      : t9n.SiteNotifications.GenericMaintenance;

  return (
    <NotificationBanner notificationType={notificationType} hidden={isHidden}>
      <Icon notificationType={notificationType} />
      <NotificationContent>
        {replaceValues(
          notificationMessage,
          notification.allUsers?.replacements,
        )}
      </NotificationContent>
      {notification.dismissible && (
        <DismissButton
          type="button"
          onClick={() => setIsHidden(true)}
          aria-label={t9n.SiteNotifications.Dismiss}
        >
          <DismissIcon notificationType={notificationType} />
        </DismissButton>
      )}
    </NotificationBanner>
  );
}
