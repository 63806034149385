import styled from 'styled-components';

export const ViewingDateContainer = styled.div`
  display: flex;
  background: ${(props) => props.theme.colorPalette.branding.white};
  border: 1px solid ${(props) => props.theme.colorPalette.forms.inputBorder};
  font-size: ${(props) => props.theme.typography.default};
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeightMed};
  align-items: center;
  margin: 0 0 8px;
`;

export const ViewingCardWrapper = styled.div`
  background: ${(props) => props.theme.colorPalette.branding.white};
  border: 1px solid ${(props) => props.theme.colorPalette.forms.inputBorder};
  margin: 0 0 20px;
`;

export const ViewingFullDate = styled.span`
  padding: 12px;
`;
export const ViewingDateMonth = styled.span`
  font-size: ${(props) => props.theme.typography.xsmall};
  text-transform: uppercase;
`;
export const ViewingDateDay = styled.span`
  font-size: ${(props) => props.theme.typography.xlarge};

  &:before {
    background: ${(props) => props.theme.colorPalette.secondary.steel};
    content: '';
    border-radius: 2px;
    display: block;
    height: 14px;
    width: 6px;
    position: absolute;
    top: -8px;
    left: 12px;
  }

  &:after {
    background: ${(props) => props.theme.colorPalette.secondary.steel};
    content: '';
    border-radius: 2px;
    display: block;
    height: 14px;
    width: 6px;
    position: absolute;
    top: -8px;
    right: 12px;
    z-index: 3;
  }
`;

export const ViewingDateSidebar = styled.div`
  background: ${(props) => props.theme.colorPalette.calendar.unavailable};
  padding: 12px;
  width: 54px;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;

  &:before {
    background: ${(props) => props.theme.colorPalette.branding.white};
    content: '';
    border-radius: 2px;
    display: block;
    height: 7px;
    width: 10px;
    position: absolute;
    top: 1px;
    left: 10px;
  }

  &:after {
    background: ${(props) => props.theme.colorPalette.branding.white};
    content: '';
    border-radius: 2px;
    display: block;
    height: 7px;
    width: 10px;
    position: absolute;
    top: 1px;
    right: 10px;
  }
`;

export const ViewingTag = styled.span`
  margin: 0 20px 0 auto;
`;
