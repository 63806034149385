import React from 'react';
import styled from 'styled-components';
import { ReactComponent as House } from 'assets/icons/house.svg';
import { ReactComponent as Farm } from 'assets/icons/farm.svg';
import { ReactComponent as Vineyard } from 'assets/icons/vineyard.svg';
import { ReactComponent as Commercial } from 'assets/icons/commercial.svg';
import { PropertyType } from 'types/property';
import { attributeIconStyles } from '../Attributes/Attributes.styled';

export const HouseIcon = styled(House)`
  ${attributeIconStyles};
`;

export const FarmIcon = styled(Farm)`
  ${attributeIconStyles};
`;

export const VineyardIcon = styled(Vineyard)`
  ${attributeIconStyles};
`;

export const CommercialIcon = styled(Commercial)`
  ${attributeIconStyles};
`;

export const PropertyTypesContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PropertyTypeContainer = styled.div`
  position: relative;
  padding-left: 24px;
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.span`
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) => props.theme.typography.xsmall};
  color: ${(props) => props.theme.hub.colorPalette.primary.grey};
`;

export const getPropertyTypeIcon = (
  propertyType: PropertyType,
  isFarm?: boolean,
  isCommercial?: boolean,
) => {
  switch (true) {
    case isFarm && propertyType === PropertyType.Vineyard:
      return <VineyardIcon />;
    case isFarm:
      return <FarmIcon />;
    case isCommercial:
      return <CommercialIcon />;
    default:
      return <HouseIcon />;
  }
};
