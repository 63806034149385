import React from 'react';
import { useSwiper } from 'swiper/react';

import {
  ZoomControlsWrapper,
  ZoomInIcon,
  ZoomOutIcon,
  ResetZoomIcon,
  ZoomButton,
} from './Gallery.styled';
import { ExtendedSwiper, GalleryZoomProps } from './types';

const GalleryZoom: React.FC<GalleryZoomProps> = ({
  maxZoom,
  activeZoomScale,
  t9n,
}) => {
  const swiper = useSwiper() as unknown as ExtendedSwiper;

  const zoomIn = () => {
    if (activeZoomScale < maxZoom) {
      if (activeZoomScale === 1) {
        swiper.zoom.in();
      }

      swiper.zoom.setZoom(activeZoomScale + 1);
    }
  };

  const zoomOut = () => {
    if (activeZoomScale > 1) {
      swiper.zoom.setZoom(activeZoomScale - 1);
    }
  };

  const resetZoom = () => {
    swiper.zoom.out();
  };

  return (
    <ZoomControlsWrapper>
      <ZoomButton type="button" onClick={zoomIn}>
        <ZoomInIcon />
        <span>{t9n.PropertyCarousel.ZoomIn}</span>
      </ZoomButton>
      <ZoomButton type="button" onClick={resetZoom}>
        <ResetZoomIcon />
        <span>{t9n.PropertyCarousel.ResetZoom}</span>
      </ZoomButton>
      <ZoomButton type="button" onClick={zoomOut}>
        <ZoomOutIcon />
        <span>{t9n.PropertyCarousel.ZoomOut}</span>
      </ZoomButton>
    </ZoomControlsWrapper>
  );
};

export default GalleryZoom;
