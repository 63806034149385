import { SelectOption } from 'savills-ui-kit/lib/components/Select/types';
import { SortBy } from 'views/Documents/types';

export const DOCUMENT_TYPE_OPTIONS: SelectOption[] = [
  { label: 'Statement and Invoices', value: 'StatementAndInvoices' },
  {
    label: 'Safety Certificate and Licenses',
    value: 'SafetyCertsAndLicenses',
  },
  { label: 'Agreements', value: 'Agreements' },
  { label: 'Terms', value: 'Terms' },
  { label: 'Tenancy Documents', value: 'TenancyDocuments' },
  { label: 'Other', value: 'Other' },
];

export enum FilterKeys {
  TYPE = 'type',
  PROPERTY = 'property',
  TENANCY = 'tenancy',
  DATE = 'date',
  SORT_BY = 'sortBy',
}

export enum FilterDateKeys {
  FROM = 'from',
  TO = 'to',
}

export const DEFAULT_FILTERS = {
  [FilterKeys.TYPE]: '',
  [FilterKeys.PROPERTY]: '',
  [FilterKeys.TENANCY]: '',
  [FilterKeys.DATE]: {
    [FilterDateKeys.FROM]: null,
    [FilterDateKeys.TO]: null,
  },
  [FilterKeys.SORT_BY]: SortBy.DATE_DESC,
};
