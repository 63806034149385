import styled from 'styled-components';
import { PropertyCardType } from '../constants';

export const PropertySummaryList = styled.ul<{
  type: PropertyCardType;
  isNewDevelopment?: boolean;
}>`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 10px;
  margin-top: 20px;

  @media screen and (${(props) => props.theme.media.widthMed}) {
    max-height: 150px;
    overflow: hidden;
    grid-template-columns: 50% 50%;
  }

  ${(props) =>
    props.type === PropertyCardType.SAVED_PROPERTY &&
    !props.isNewDevelopment &&
    `
  padding: 0 30px;
  margin: 0 0 25px;
`}

  ${(props) =>
    props.type === PropertyCardType.SAVED_PROPERTY &&
    props.isNewDevelopment &&
    `
    margin: 0px 30px 30px;

  @media screen and (${props.theme.media.widthMed}) {
    margin: 0px 30px
  }
`}
`;

export const PropertySummaryItem = styled.li<{ color?: string }>`
  position: relative;
  padding: 0 15px;
  font-family: ${(props) => props.theme.fonts.secondary.font};
  font-weight: ${(props) => props.theme.fonts.secondary.fontWeight};
  font-size: ${(props) => props.theme.typography.xsmall};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(props) => props.theme.colorPalette.branding.ink};

  &:before {
    position: absolute;
    top: 5px;
    left: 0;
    background: ${(props) =>
      props.color ? props.color : props.theme.colorPalette.secondary.red};
    width: 5px;
    height: 5px;
    border-radius: 5px;
    content: ' ';
  }
`;
