import React from 'react';
import { LoadingContainer, LoadingSpinner } from './Loading.styled';
import { LoadingProps } from './types';

export default function Loading({
  backgroundColor,
  insetColor,
  width,
}: LoadingProps) {
  return (
    <LoadingContainer backgroundColor={backgroundColor} width={width}>
      <LoadingSpinner data-testid="loadingSpinner" insetColor={insetColor} />
    </LoadingContainer>
  );
}
